import { states } from "../../../pages/Users/constants";
import makeRequest from "./makeRequest";
import { getUserByEmail } from "./profile";

export function getSharing() {
    return new Promise(function (resolve, reject) {
        try {
            makeRequest(`/users-sharings`, "GET").then(data => {
                if (data.error) throw data.error.message
                if (!data) {
                    reject({})
                    return
                }

                const sharing = data.data.attributes;

                const getRoleData = (role) => {
                    return sharing[role]?.data.map(user => { return { id: user.id, ...user.attributes } });
                }

                const admins = getRoleData("admins");
                const colaborators = getRoleData("colaborators");
                const inactive_admins = getRoleData("inactive_admins");
                const inactive_colaborators = getRoleData("inactive_colaborators");

                const allUsers = {
                    admins,
                    colaborators,
                    inactive_admins,
                    inactive_colaborators
                }

                resolve(allUsers);
            })
        }
        catch (error) {
            console.error(error)
        }
    })
}


export function updateSharing(user) {
    return new Promise(async function (resolve, reject) {
        let body, userData;
        try {
            [body, userData] = await Promise.all([getSharing(), getUserByEmail(user.email)]);

            var isInactive = body.inactive_admins.some(admin => admin.id === userData.id) || body.inactive_colaborators.some(colaborator => colaborator.id === userData.id)

            isInactive = isInactive ? "inactive_" : "";

            body.admins = body.admins.filter(admin => admin.id !== userData.id)
            body.inactive_admins = body.inactive_admins.filter(inactive_admin => inactive_admin.id !== userData.id)
            body.colaborators = body.colaborators.filter(colaborator => colaborator.id !== userData.id)
            body.inactive_colaborators = body.inactive_colaborators.filter(inactive_colaborator => inactive_colaborator.id !== userData.id)

            switch (user.role.toUpperCase()) {
                case "ADMIN":
                    body[`${isInactive}admins`].push(userData.id)
                    break;

                case "COLLABORATOR":
                    body[`${isInactive}colaborators`].push(userData.id)
                    break;
                default: break;
            }

            makeRequest(`/users-sharings`, "PUT", body).then(data => {
                try {
                    if (data.error) throw data.error.message
                    if (!data) {
                        reject({})
                        return
                    }

                    var sharing = { id: data.data.id, ...data.data.attributes }

                    resolve(sharing)
                }
                catch (error) {
                    switch (error) {
                        case "No active subscription found":
                            reject("NoActiveSubscription");
                            break;
                        case "Subscription expired":
                            reject("SubscriptionExpired");
                            break;
                        case "You have reached the maximum number of users allowed for your plan":
                            reject("ReachedUserLimitAtCurrentPlan");
                            break;
                        default:
                            reject("GeneralError");
                            break;
                    }
                    console.error(error)
                }
            })
        } catch (error) {
            reject(error)
            console.error(error)
        }
    })
}

export function switchSharingState(user) {
    return new Promise(async function (resolve, reject) {
        try {
            var body = {};
            var userData;
            await getSharing().then(data => {
                body = data;
            })

            await getUserByEmail(user.email).then(data => {
                userData = data;
            })

            var isInactive = user.state === states.INACTIVE ? "inactive_" : "";

            body.admins = body.admins.filter(admin => admin.id !== userData.id)
            body.inactive_admins = body.inactive_admins.filter(inactive_admin => inactive_admin.id !== userData.id)
            body.colaborators = body.colaborators.filter(colaborator => colaborator.id !== userData.id)
            body.inactive_colaborators = body.inactive_colaborators.filter(inactive_colaborator => inactive_colaborator.id !== userData.id)


            switch (user.role.toUpperCase()) {
                case "ADMIN":
                    body[`${isInactive}admins`].push(userData.id)
                    break;

                case "COLLABORATOR":
                    body[`${isInactive}colaborators`].push(userData.id)
                    break;

                default: break;
            }

            makeRequest(`/users-sharings`, "PUT", body).then(data => {
                try {
                    if (data.error) throw data.error.message
                    if (!data) {
                        reject({})
                        return
                    }

                    var sharing = { id: data.data.id, ...data.data.attributes }

                    resolve(sharing)
                }
                catch (error) {
                    reject(error)
                    console.error(error)
                }
            })
        } catch (error) {
            reject(error)
            console.error(error)
        }
    })
}

export function removeFromSharing(id, user) {
    return new Promise(async function (resolve, reject) {
        try {
            var body = {};
            var userData;
            await getSharing(id).then(data => {
                body = data;
            })

            await getUserByEmail(user.email).then(data => {
                userData = data;
            })

            body.admins = body.admins.filter(admin => admin.id !== userData.id)
            body.inactive_admins = body.inactive_admins.filter(inactive_admin => inactive_admin.id !== userData.id)
            body.colaborators = body.colaborators.filter(colaborator => colaborator.id !== userData.id)
            body.inactive_colaborators = body.inactive_colaborators.filter(inactive_colaborator => inactive_colaborator.id !== userData.id)

            makeRequest(`/users-sharings`, "PUT", body).then(data => {
                try {
                    if (data.error) throw data.error.message
                    if (!data) {
                        reject({})
                        return
                    }

                    var sharing = { id: data.data.id, ...data.data.attributes }

                    resolve(sharing)
                }
                catch (error) {
                    reject(error)
                    console.error(error)
                }
            })
        } catch (error) {
            console.error(error)
        }
    })
}