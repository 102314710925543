export function TextArea({ className = "", ...props }) {
  const validationClass = props.error ? "is-invalid" : "";

  return (
    <>
      <textarea
        className={`form-control ${className} ${validationClass}`}
        {...props}
      />
      {props.error && <div className="invalid-feedback">{props.error} </div>}
    </>
  );
}
