import React, { useEffect, useMemo, useRef, useState } from "react";
import QRCodeStyling from "qr-code-styling";
import { toast } from 'react-toastify';

import { API, LOCAL } from "../../constant";


const QRPreview = ({ pageData, qrCode, width, height, className, shouldCopy, setShouldCopy, shouldDownload, setShouldDownload, shouldPrint, setShouldPrint }) => {

    const qrCodeData = `${API}/redirect/${qrCode?.identifier}`;

    const options = useMemo(() => ({
        width: width,
        height: height,
        type: 'canvas',
        data: qrCodeData,
        image: "",
        margin: 0,
        qrOptions: {
            typeNumber: 0,
            mode: 'Byte',
            errorCorrectionLevel: 'Q'
        },
        imageOptions: {
            hideBackgroundDots: true,
            margin: 8,
            crossOrigin: 'anonymous',
        },
        dotsOptions: {
            color: qrCode.dotColor,
            type: qrCode.dotFormat
        },
        backgroundOptions: {
            color: qrCode.backgroundColor,
        },
        cornersSquareOptions: {
            color: qrCode.cornerSquareColor,
            type: qrCode.cornerSquareFormat,
        },
        cornersDotOptions: {
            color: qrCode.cornerDotColor,
            type: qrCode.cornerDotFormat
        }
    }), [width, height, qrCodeData, qrCode.dotColor, qrCode.dotFormat, qrCode.backgroundColor, qrCode.cornerSquareColor, qrCode.cornerSquareFormat, qrCode.cornerDotColor, qrCode.cornerDotFormat]);

    const [QRCodeImage, setQRCodeImage] = useState(new QRCodeStyling(options));
    const ref = new useRef(null);

    useEffect(() => {
        setQRCodeImage(new QRCodeStyling(options));
    }, [width, height]);

    useEffect(() => {
        if (!shouldCopy) return;

        const canvas = QRCodeImage._canvas;

        canvas.toBlob((blob) => {
            if (!window.ClipboardItem) {
                toast(pageData.browserError);
                return;
            }

            const item = new ClipboardItem({ [blob.type]: blob });
            navigator.clipboard.write([item])
                .then(() => {
                    toast(pageData.qrCodeCopied);
                }).catch((error) => {
                    toast(pageData.errorQRCodeCopied);
                })
                .finally(() => {
                    setShouldCopy(false);
                });
        });
    }, [shouldCopy, setShouldCopy, QRCodeImage])

    useEffect(() => {
        if (!shouldDownload) return;

        QRCodeImage.download({
            extension: "svg",
            name: qrCode.name,
        });

        setShouldDownload(false);

    }, [shouldDownload, setShouldDownload, QRCodeImage, qrCode.name])

    useEffect(() => {
        if (!shouldPrint) return;

        const size = 1000;

        const qrCodeOptions = QRCodeImage._options;

        const printStyle = new QRCodeStyling({ ...qrCodeOptions, width: size, height: size });
        const printWindow = window.open('', '_blank');

        printStyle._canvasDrawingPromise.then(() => {
            const canvas = printStyle._canvas;
            const dataURL = canvas.toDataURL('image/png');

            printWindow.document.write('<html><body><img src="' + dataURL + '"/></body></html>');
            printWindow.document.close();

            printWindow.onload = () => {
                printWindow.print();
                printWindow.close();
                setShouldPrint(false);
            };
        }).catch(() => { });

    }, [shouldPrint, setShouldPrint, QRCodeImage, options])

    useEffect(() => {
        QRCodeImage.append(ref.current);
    }, [QRCodeImage]);

    //Dot Color
    useEffect(() => {
        QRCodeImage.update({
            dotsOptions: {
                color: qrCode.dotColor,
            },
        });
    }, [qrCode.dotColor, QRCodeImage]);

    //Dot Format
    useEffect(() => {
        QRCodeImage.update({
            dotsOptions: {
                type: qrCode.dotFormat,
            },
        });
    }, [qrCode.dotFormat, QRCodeImage]);

    //Background Color
    useEffect(() => {
        QRCodeImage.update({
            backgroundOptions: {
                color: qrCode.backgroundColor,
            },
        });
    }, [qrCode.backgroundColor, QRCodeImage]);

    useEffect(() => {
        QRCodeImage.update({
            cornersSquareOptions: {
                type: qrCode.cornerSquareFormat,
            },
        });
    }, [qrCode.cornerSquareFormat, QRCodeImage]);

    useEffect(() => {
        QRCodeImage.update({
            cornersDotOptions: {
                type: qrCode.cornerDotFormat,
            },
        });
    }, [qrCode.cornerDotFormat, QRCodeImage]);

    //Corner Dot Color
    useEffect(() => {
        QRCodeImage.update({
            cornersDotOptions: {
                color: qrCode.cornerDotColor,
            },
        });
    }, [qrCode.cornerDotColor, QRCodeImage]);

    //Corner Square Color
    useEffect(() => {
        QRCodeImage.update({
            cornersSquareOptions: {
                color: qrCode.cornerSquareColor,
            },
        });
    }, [qrCode.cornerSquareColor, QRCodeImage]);

    //Image
    useEffect(() => {
        if (qrCode.image?.type) {
            QRCodeImage.update({
                image: qrCode.image ? URL.createObjectURL(qrCode.image) : ""
            });
        }
        else if (qrCode.image?.data?.attributes) {

            QRCodeImage.update({
                image: LOCAL + qrCode.image.data.attributes.url
            });
        }
        else if (qrCode.image?.url != null) {
            QRCodeImage.update({
                image: LOCAL + qrCode.image.url
            });
        }

        else {
            QRCodeImage.update({
                image: ""
            });
        }
    }, [qrCode.image, QRCodeImage]);

    return (
        <div
            id={qrCode.id}
            key={qrCode.id}
            className={className}
            ref={ref}
        />
    );
};

export default QRPreview;