import { Link } from "react-router-dom";

export function CTABanner({ text, button, url }) {
  return (
    <section className="bg-primary w-100 py-5">
      <div className="container row g-4 mx-auto">
        <h1 className="col-md-9 text-light">{text}</h1>
        <Link
          to={url}
          className="col-md-3 d-flex justify-content-center align-items-center"
        >
          <button className="btn btn-outline-light btn-lg right-r">
            {button}
            <i className="mdi mdi-arrow-right"></i>
          </button>
        </Link>
      </div>
    </section>
  );
}
