import { Link, NavLink } from "react-router-dom";
import { LOCAL } from "../../constant";

const PublicNavigation = ({ menuData, children }) => {
  const authButtonContent = (
    <>
      <i className="mdi mdi-account-circle me-2"></i> {menuData.Login}
    </>
  );
  
  const authButtonContentRegister = (
    <>
      <i className="mdi mdi-account me-2"></i> {menuData.btnRegister}
    </>
  );
  const authButtonContentMobile = menuData.Login;
  const authButtonContentMobileRegister = menuData.btnRegister;

  const logoLight = LOCAL + menuData.LogoLight.data.attributes.url;

  const links = menuData.menu_items.data.map((link) => {
    const linkData = link.attributes;
    return (
      <li className="nav-item mx-lg-1" key={`link-${linkData.Text}`}>
        <NavLink className="nav-link" to={linkData.URL}>
          {linkData.Text}
        </NavLink>
      </li>
    );
  });

  return (
    <>
      <nav className="navbar navbar-expand-lg py-lg-3 navbar-dark">
        <div className="container">
          <Link to="/" className="navbar-brand me-lg-5">
            <img src={logoLight} alt="logo" className="logo-dark" height="18" />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="mdi mdi-menu"></i>
          </button>

          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav me-auto align-items-center">{links}</ul>

            <ul className="navbar-nav ms-auto align-items-center">
              <li className="nav-item me-0">
                <Link to="/Statistics" className="nav-link d-lg-none">
                  {authButtonContentMobile}
                </Link>
                <Link
                  to="/Statistics"
                  className="btn btn-md btn-outline-light d-none d-lg-inline-flex"
                >
                  {authButtonContent}
                </Link>
              </li>
              <li className="nav-item ms-1">
                <Link to="/signUp" className="nav-link d-lg-none">
                  {authButtonContentMobileRegister}
                </Link>
                <Link
                  to="/signUp"
                  className="btn btn-md btn-light d-none d-lg-inline-flex"
                >
                  {authButtonContentRegister}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {children}
    </>
  );
};

export default PublicNavigation;
