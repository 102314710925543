import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

// Components Imports
import { Group, Input, Label } from "../../components/Form";
import Loading from "../../components/Loading";
import Modal from "../../components/Modal";
import { Table, TableHead } from "../../components/Table";

// Hooks Imports
import { useAuthContext } from "../../context/AuthContext";
import usePayment from "../../hooks/usePayment";
import usePagesData from "../../hooks/pageData/usePagesData";

// Utils Imports
import { PageTypes } from "../../hooks/pageData/PageTypes";

const cancelationModalId = "cancelationModal";

const Payments = () => {
  const { user, isLoading: userIsLoading } = useAuthContext();
  const { doGetInvoice, doGetCurrentPlan, doCancelSubscription, invoices, currentPlan } = usePayment();

  const closeCancelationModalRef = useRef(null);

  useEffect(() => {
    doGetInvoice();
    doGetCurrentPlan();
  }, []);

  const { pageData, isPending, isError } = usePagesData({ pageType: PageTypes.PAYMENTS });

  if (userIsLoading || isPending || isError) return <Loading />;

  const handlePlanCancelation = () => {
    doCancelSubscription();
    if (closeCancelationModalRef.current) closeCancelationModalRef.current.click();
  };

  const invoiceItems = invoices.map((invoice) => {
    const key = `invoice-${invoice.id}`;

    const paid = invoice.state === "paid";
    const state = paid ? pageData.Paid : pageData.Pendent;
    const stateColor = paid ? "text-success" : "text-warning";

    return (
      <tr key={key}>
        <td>
          <span className={`${stateColor} fw-semibold`}>{state}</span>
        </td>
        <td>
          <span className="text-body fw-semibold">{invoice.id}</span>
        </td>
        <td>
          <span className="text-body fw-semibold">{invoice.plan}</span>
        </td>
        <td>
          <span className="text-body fw-semibold">{invoice.date}</span>
        </td>
        <td>
          <span className="text-body fw-semibold">
            {invoice.total} {invoice.currency}
          </span>
        </td>
        <td className="text-center">
          <a href={invoice.url} target="_blank" rel="noreferrer">
            <button
              type="button"
              className="button-reset action-icon text-center"
            >
              <i className="mdi mdi-eye"></i>
            </button>
          </a>
        </td>
      </tr>
    );
  });

  const popUp = pageData.CancelationPopUp;

  const cancelationModal = (
    <Modal
      ref={closeCancelationModalRef}
      id={cancelationModalId}
      title={popUp.Title}
      position="center"
      footer={{
        close: popUp.Cancel,
        confirm: {
          text: popUp.Confirm,
          onClick: handlePlanCancelation,
          color: "btn-danger",
        },
      }}
    >
      <p>{popUp.Description}</p>
      {currentPlan.isContract && (
        <p>{pageData.ContractPlanCancelationExtraParagraph}</p>
      )}
    </Modal>
  );

  const currentPlanName = currentPlan.isTrial
    ? pageData.CurrentPlanIsTrial
    : currentPlan.name || pageData.NoPlanSubscribed;

  const groupClassNames =
    invoices.length > 0 ? "col-md-6 col-lg-4" : "col-md-6";

  return (
    <>
      {cancelationModal}
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <h4 className="page-title">{pageData.Title}</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="header-title">{pageData.PlanDetails}</h4>
                <div className="row">
                  <Group className={groupClassNames}>
                    <Label htmlFor="clientId">{pageData.ClientID.Label}</Label>
                    <Input
                      id="clientId"
                      value={user.customerId || user.id}
                      onChange={() => {}}
                      disabled
                    />
                  </Group>
                  <Group className={groupClassNames}>
                    <Label htmlFor="currentPlan">
                      {pageData.CurrentPlan.Label}
                    </Label>
                    <Input
                      id="currentPlan"
                      value={currentPlanName}
                      onChange={() => {}}
                      disabled
                    />
                  </Group>
                  {invoices.length > 0 && (
                    <Group className="col-lg-4 d-flex align-items-end justify-content-start justify-content-lg-end">
                      <Link
                        to="/payments/selectPlan"
                        className="btn btn-primary me-2"
                      >
                        {pageData.ChangePlan}
                      </Link>
                      <button
                        className="btn btn-outline-secondary"
                        data-bs-toggle="modal"
                        data-bs-target={`#${cancelationModalId}`}
                      >
                        {pageData.CancelPlan}
                      </button>
                    </Group>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                {invoices.length > 0 ? (
                  <>
                    <h4 className="header-title">{pageData.PaymentHistory}</h4>
                    <p className="text-muted font-14">
                      {pageData.TableText}
                      
                    </p>
                    <Table className="table-striped">
                      <TableHead actionLabel={pageData.DetailsTitle}> 
                        <th>{pageData.State}</th>
                        <th>{pageData.InvoiceNumber}</th>
                        <th>{pageData.Plan}</th>
                        <th>{pageData.Date}</th>
                        <th>{pageData.Total}</th>
                      </TableHead>
                      <tbody>{invoiceItems}</tbody>
                    </Table>
                  </>
                ) : (
                  <>
                    <div className="text-center">
                      <h4 className="mb-3">{pageData.NoInvoicesFound}</h4>
                      <Link
                        to="/payments/selectPlan"
                        className="btn btn-primary me-2"
                      >
                        {pageData.ButtonSubscribeText}
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payments;
