import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

export function CountryBarChart({ data }) {
  const rootStyle = getComputedStyle(document.documentElement);
  const primaryColor = rootStyle.getPropertyValue("--ct-primary");
  const secondaryColor = rootStyle.getPropertyValue("--ct-secondary");

  const evenColor = (x) => x % 2 === 0 ? primaryColor : secondaryColor;

  return (
    <ResponsiveContainer width="95%" height={300}>
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        layout="vertical"
      >
        <CartesianGrid horizontal={false} vertical={false} />
        <YAxis type="category" dataKey="name" />
        <XAxis type="number" />
        <Tooltip />
        <Bar dataKey="scans" fill={primaryColor}>
          {
            data.map((entry, index) => (
              <Cell key={`country-${index}`} fill={evenColor(index)} />
            ))
          }
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
} 