import makeRequest from "../../dataFetching"
import { API_CHECKOUT_PAGE } from "../../../utils/constants";
import { findCheckoutPageMapping } from "../../dataMapping/checkoutPage";
/**
 * FIND
 * Fetches all checkoutPage entries.
 * @returns {Promise<Object>} A promise that resolves to the response data from the GET request.
 */
export function findCheckoutPage () {
  return makeRequest(API_CHECKOUT_PAGE)
  .then(findCheckoutPageMapping);
}

/**
 * CREATE LOCALIZATION
 * Creates a new checkoutPage localization entry.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function createLocalizationCheckoutPage ({}) {}


/**
 * PUT
 * Updates an existing checkoutPage entry.
 * @param {integer} id - The ID of the checkoutPage entry to update.
 * @returns {Promise<Object>} A promise that resolves to the response data from the PUT request.
 */
export function updateCheckoutPage (id, {}) {}


/**
 * DELETE
 * Deletes a specific checkoutPage entry by ID.
 * @param {integer} id - The ID of the checkoutPage entry to delete.
 * @returns {Promise<Object>} A promise that resolves to the response data from the DELETE request.
 */
export function deleteCheckoutPage ({ id }) {}
