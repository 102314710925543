import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import "../../styles/pages/QrLinkTreeRedirect.css";

// Components imports
import Loading from "../../components/Loading";

// Hooks Imports
import usePagesData from "../../hooks/pageData/usePagesData";
import { PageTypes } from "../../hooks/pageData/PageTypes";
import useQrCodes from "../../hooks/qrCode/useQrCodes";

// Utils Imposts
import { LOCAL } from "../../constant";

const QrLinkTreeRedirect = () => {
  const QrCodeIdentifier = useParams().identifier;

  const { doGetQrCodeByIdentifier, qrCodeIdentifier } = useQrCodes();

  useEffect(() => {
    doGetQrCodeByIdentifier({ qrCodeIdentifierParam: QrCodeIdentifier });
  }, [QrCodeIdentifier]);

  const { pageData, isPending, isError } = usePagesData({ pageType: PageTypes.QR_LINK_TREE_REDIRECT });

  if (isPending || !qrCodeIdentifier || isError) return <Loading />;

  const userAvatar = qrCodeIdentifier.users_permissions_user.avatar?.url;

  return (
    <>
      <div className="LinkTree--wrapper">
        <div className="LinkTree--container">
          <div className="profile_picture">
            <img
              src={
                LOCAL +
                (userAvatar
                  ? userAvatar
                  : pageData.DefaultAvatar.data.attributes.url)
              }
              alt="Avatar"
              className="avatar"
            />
            <p>{qrCodeIdentifier.users_permissions_user.username}</p>
          </div>
          <div className="LinkTree--links">
            {qrCodeIdentifier.qr_code_linktrees.map((linkTree, index) => (
              <div
                key={index}
                className="customCard"
                onClick={() => window.open(linkTree.Link, "_blank")}
              >
                <div className="LinkTree--link">
                  <div className="createQR--form--Linktree--image--circle">
                    <img
                      src={LOCAL + linkTree.image.url}
                      alt="Preview"
                      className="createQR--form--Linktree--imagePreview"
                    />
                  </div>
                  <span>{linkTree.Name}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default QrLinkTreeRedirect;
