import makeRequest from "./makeRequest"
import { API } from "../../../constant";
import { getToken } from "../../../helpers";

export function getUser() {
    return new Promise(function (resolve, reject) {
        makeRequest("/users/me?populate=*", "GET")
            .then(data => {
                if (data.error) throw data.error.message
                if (!data) {
                    reject({})
                    return
                }

                resolve(data)
            })
            .catch(error => {
                console.error(error)
                reject("CouldNotGetUser")
            });
    })

}

export function getUsers() {
    return new Promise(function (resolve, reject) {
        makeRequest("/users", "GET").then(data => {
            try {
                if (data.error) throw data.error.message
                if (!data) {
                    reject({})
                    return
                }

                resolve(data)
            }
            catch (error) {
                console.error(error)
                //toast.error("Erro na obtenção dos Utilizadores!")
            }
        })
    })

}

export function getUserByEmail(email) {
    return new Promise(function (resolve, reject) {
        makeRequest(`users-permissions/email/${email}`)
            .then(data => {
                try {
                    if (data.error) throw data.error.message
                    if (!data) {
                        reject({})
                        return
                    }

                    resolve(data)
                }
                catch (error) {
                    console.error(error)
                    reject("UserNotFound");
                }
            })
            .catch(() => {
                reject("UserNotFound");
            })
    })
}

export function loginUser(body) {
    return new Promise(function (resolve, reject) {
        makeRequest("/auth/local", "POST", body, "USER").then(data => {
            try {
                if (data.error) reject(data.error.message)
                if (!data) {
                    reject({})
                    return
                }

                resolve(data)
            }
            catch (error) {
                console.error(error)
            }
        })
    })
}

export async function uploadImage(file) {
    var formData = new FormData()
    formData.append("files", file)

    const imageResponse = await fetch(`${API}/upload`,
        {
            method: "POST",
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
            body: formData
        })

    const data = await imageResponse.json();
    if (data && data.length > 0) {
        return data[0]; // Return the first uploaded file data
    } else {
        throw new Error('Failed to upload image');
    }
}

export async function updateMe(data) {
    try {
        let updatedData = { ...data };
        // Check if there is an avatar file to upload
        if (data.avatarFile) {
            const uploadedImage = await uploadImage(data.avatarFile);
            if (uploadedImage && uploadedImage.id) {
                updatedData.avatar = uploadedImage.id;
            }
        }else{
            updatedData.avatar= data.avatar?.id;

        }

        const response = await makeRequest(`/users-permissions/me`, 'PUT', updatedData, 'USER');
        if (response.error) {
            throw new Error(response.error.message);
        }

        return response;
    } catch (error) {
        console.error("Error updating user:", error);
        throw new Error("ErrorUpdatingUser");
    }
}

export function registerUser(body) {
    return new Promise(function (resolve, reject) {
        makeRequest(`/auth/local/register`, "POST", body, "USER").then(data => {
            try {
                if (data.error) throw data.error.message
                if (!data) {
                    reject({})
                    return
                }

                resolve(data)
            }
            catch (error) {
                console.error(error)
                if (error === "email must be a valid email")
                    reject("InvalidEmail")

                if (error === "Email or Username are already taken")
                    return reject("UsernameOrEmailAlreadyTaken")

                reject("ProblemSigningUp")
            }
        })

    })
}

export function updatePassword(body) {
    return new Promise(function (resolve, reject) {
        try {
            makeRequest(`/auth/reset-password`, "POST", body, "USER").then(data => {
                if (data.error) throw data.error.message
                if (!data) {
                    reject({})
                    return
                }
                var user = { id: data.data.id, ...data.data.attributes }
                resolve(user)
            })
        }
        catch (error) {
            console.error(error)
            //toast.error("Erro na Atualização da Palavra-passe")
        }
    })
}

export async function deleteUser() {
    const res = await makeRequest(`users-permissions/me`, "DELETE", {}, "USER");
    if (res.error) {
        throw new Error(res.error.message);
    }

    return true
}