import { LOCAL } from "../../constant";

import UploadFileForm from "../UploadFileForm";
import WifiForm from "../WifiForm";
import LinkTreeForm from "../LinkTreeForm";

export default function QrCodeInputSwitch({
  qrCode,
  setFiles,
  urlPreference,
  wifi,
  setWifi,
  linkList,
  setLinkList,
  editing = false,
  pageData,
  typeTitle,
  errors,
  setErrors,
}) {
  if (pageData === undefined)
    throw new Error("pageData is required in QrCodeInputSwitch component");

  if (typeTitle === undefined) {
    typeTitle = pageData.qr_code_type_cards.data.find(
      (t) => t.attributes.Type === qrCode.type
    ).attributes.Title;
  }

  let startingFile = editing
    ? qrCode.mediaField?.data?.at(0)?.attributes
    : null;

  let acceptedTypes = [];

  switch (qrCode.type) {
    case "Imagens":
      acceptedTypes = ".jpeg, .png, .gif, .svg, .tiff, .ico, .dvu";
      break;

    case "MP3":
      acceptedTypes = ".mp3";
      break;

    case "PDF":
      acceptedTypes = ".pdf";
      break;

    case "Vídeo":
      if (!urlPreference) {
        acceptedTypes = ".mpeg, .mp4, .quicktime, .wmv, .avi, .flv";
      }
      break;

    case "Menu":
    case "Cupon":
      startingFile = qrCode.url === "" && startingFile;

      if (!urlPreference) {
        acceptedTypes = ".pdf, .png, .jpg";
      }
      break;
    case "Wifi":
      return (
        <WifiForm
          wifi={wifi}
          setWifi={setWifi}
          pageData={pageData}
          errors={errors}
          setErrors={setErrors}
        />
      );
    case "Lista de Links":
      const formattedLinks = qrCode.qr_code_linktrees?.data?.map((link) => {
        return {
          ...link.attributes,
          id: link.id,
          image: {
            url: LOCAL + link.attributes.image?.data?.attributes?.url,
            name: link.attributes.image?.data?.attributes?.name,
          },
        };
      });

      const startingLinks = editing ? formattedLinks : null;
      
      return (
        <LinkTreeForm
          qrCodeId={qrCode.id}
          linkList={linkList}
          setLinkList={setLinkList}
          startingLinks={startingLinks}
          pageData={pageData}
          error={errors?.linkList}
        />
      );
    default:
      break;
  }
  
  if (acceptedTypes.length === 0) return <></>;

  return (
    <UploadFileForm
      acceptedtypes={acceptedTypes}
      multipleFiles={false}
      filesState={setFiles}
      startingFile={startingFile}
      pageData={pageData}
      error={errors?.files}
    />
  );
}
