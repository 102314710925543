import { Link } from "react-router-dom";

import { LOCAL } from "../../constant";
import Loading from "../Loading";
import usePagesData from "../../hooks/pageData/usePagesData";
import { PageTypes } from "../../hooks/pageData/PageTypes";

const InfoCard = ({ infoCardData }) => {
  if (!infoCardData)
    throw new Error(
      "Info card data is required, make sure to send the strapi data through props."
    );

  const infoCardItems = getInfoCardItems(infoCardData);
  const logo = LOCAL + infoCardData.Logo.data.attributes.url;
  
  const { pageData, isPending, isError } = usePagesData({ pageType: PageTypes.FOOTER });

  if (isPending || isError) return <Loading />;
  return (
    <footer className="bg-dark py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <img src={logo} alt="" className="logo-dark" height="25" />
            <p className="text-muted mt-4">{pageData.Description}</p>
          </div>

          {infoCardItems}
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="mt-5">
              <p className="text-muted mt-4 text-center mb-0">
                {pageData.CopyRights}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

function getInfoCardItems(infoCardData) {
  return infoCardData.info_menu_categories.data.map((category, index) => {
    const categoryContent = category.attributes;
    return (
      <div key={`category-${index}`} className="col-lg-2 col-md-4 mt-3 mt-lg-0">
        <h5 className="text-light">
          <Link to={categoryContent.URL}>{categoryContent.Title}</Link>
        </h5>
        <ul className="list-unstyled ps-0 mb-0 mt-3">
          {categoryContent.info_menu_items.data.map((item, index) => {
            const itemContent = item.attributes;
            return (
              <li className="mt-2" key={`item-${index}`}>
                <Link className="text-muted" to={itemContent.URL}>
                  {itemContent.Text}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  });
}

export default InfoCard;
