import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Components Imports
import { Group, Input, Label, SelectComponent } from "../../components/Form";
import Loading from "../../components/Loading";
import QRDetailsCard from "../../components/QRDetailsCard";

// Utils Imports
import { sortTypes, stateTypes } from "./constants";

// Hooks Imports
import { useAuthContext } from "../../context/AuthContext";
import useQrCodes from "../../hooks/qrCode/useQrCodes";
import usePagesData from "../../hooks/pageData/usePagesData";
import { PageTypes } from "../../hooks/pageData/PageTypes";

const itemsPerPage = 5;

const MyQrCodes = () => {
  const { user } = useAuthContext();
  const [filter, setFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [stateFilter, setStateFilter] = useState("");
  const [selectedSort, setSelectedSort] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const { doGetAllQRCodesFromUser, QRCodes } = useQrCodes();

  let pageData = undefined;

  useEffect(() => {
    doGetAllQRCodesFromUser();
  }, [pageData]);

  const resetToFirstPage = () => {
    if (currentPage === 1) return;
    setCurrentPage(1);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    resetToFirstPage();
  };

  const handleSortChange = (e) => {
    setSelectedSort(e);
    resetToFirstPage();
  };

  const handleSortByTypeChange = (e) => {
    setTypeFilter(e);
    resetToFirstPage();
  };

  const handleSortByStateChange = (e) => {
    setStateFilter(e);
    resetToFirstPage();
  };

  const correctStateVariable = (state) => {
    switch (state) {
      case stateTypes.APPROVED:
        return "Approved";
      case stateTypes.PENDING:
        return "Pending";
      case stateTypes.NOT_APPROVED:
        return "Not Approved";
      default:
        return "Not Approved";
    }
  };

  const applyFilters = () => {
    const filteredItems = QRCodes?.filter(
      (QrCode) =>
        QrCode.name.toLowerCase().includes(filter.toLowerCase()) &&
        (!typeFilter || QrCode.type === typeFilter) &&
        (!stateFilter || QrCode.approval === correctStateVariable(stateFilter))
    );
    let sortedItems = filteredItems;

    if (selectedSort === sortTypes.NAME) {
      sortedItems.sort((a, b) => a.name.localeCompare(b.name));
    } else if (selectedSort === sortTypes.MOST_RECENT) {
      sortedItems.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    } else if (selectedSort === sortTypes.MOST_SCANNED) {
      sortedItems.sort((a, b) => b.scans.length - a.scans.length);
    }

    return sortedItems;
  };

  const filteredAndSortedItems = applyFilters();

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    filteredAndSortedItems?.slice(indexOfFirstItem, indexOfLastItem) || [];

  const totalPages = Math.ceil(filteredAndSortedItems?.length / itemsPerPage);

  const handlePageChange = (incrementation) => {
    setCurrentPage((prevPage) => prevPage + incrementation);
  };

  const { pageData: page, isPending, isError } = usePagesData({ pageType: PageTypes.MY_QR_CODES });
  pageData = page;
  if (!currentItems || isPending || isError) return <Loading />;

  const stateOptions = pageData.SelectState.Options.map((o) => {
    return { label: o.Text, value: o.Value };
  });

  const typeOptions = pageData.qr_code_type_cards.data.map((o) => {
    const optionData = o.attributes;
    return { label: optionData.Title, value: optionData.Type };
  });

  const orderOptions = pageData.OrderBy.Options.map((o) => {
    return { label: o.Text, value: o.Value };
  });

  const hasQrCodes = QRCodes.length > 0;

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                {hasQrCodes && (
                  <Link to="/create" className="btn btn-primary">
                    {pageData.btnCreateQrCode}
                  </Link>
                )}
              </div>
              <h4 className="page-title">
                {pageData.Title}{" "}
                <span className="text-primary">{pageData.Title2}</span>
              </h4>
            </div>
          </div>
        </div>

        {hasQrCodes && (
          <div className="row">
            <div className="card">
              <div className="card-body">
                <h4 className="mt-2 mb-3">{pageData.FilterLabel}</h4>
                <div className="row">
                  <Group columns={4}>
                    <Label>{pageData.FilterArea.SearchTitle}</Label>
                    <Input
                      type="text"
                      value={filter}
                      onChange={handleFilterChange}
                      placeholder={pageData.Search}
                    />
                  </Group>
                  <Group columns={4}>
                    <Label>{pageData.FilterArea.StateTitle}</Label>
                    <SelectComponent
                      placeholder={pageData.SelectState.Title}
                      onChange={(o) => handleSortByStateChange(o?.value)}
                      options={stateOptions}
                      isClearable={true}
                    />
                  </Group>
                  <Group columns={4}>
                    <Label>{pageData.FilterArea.TypeTitle}</Label>
                    <SelectComponent
                      placeholder={pageData.SelectTypeTitle}
                      onChange={(o) => handleSortByTypeChange(o?.value)}
                      options={typeOptions}
                      isClearable={true}
                    />
                  </Group>
                  <Group columns={4}>
                    <Label>{pageData.FilterArea.OrderByTitle}</Label>
                    <SelectComponent
                      placeholder={pageData.OrderBy.Title}
                      onChange={(o) => handleSortChange(o?.value)}
                      options={orderOptions}
                      isClearable={true}
                    />
                  </Group>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row">
          {hasQrCodes ? (
            currentItems.map((qrCode) => (
              <QRDetailsCard
                key={qrCode.id}
                userId={user.id}
                qrCode={qrCode}
                pageData={pageData}
              />
            ))
          ) : (
            <div className="card">
              <div className="card-body d-flex flex-column align-items-center">
                <h4 className="mt-2 mb-3 text-center">
                  {pageData.CouldNotFindAnyQRCodes}
                </h4>
                {!hasQrCodes && (
                  <Link to="/create" className="btn btn-primary">
                    {pageData.btnCreateQrCode}
                  </Link>
                )}
              </div>
            </div>
          )}
        </div>

        {totalPages > 1 && (
          <div className="row text-center mb-2">
            <div className="button-group">
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(-1)}
              >
                <i className="fs-4 mdi mdi-chevron-left"></i>
              </button>
              <span className="mx-2">
                {currentPage} {pageData.Of} {totalPages}
              </span>
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(1)}
              >
                <i className="fs-4 mdi mdi-chevron-right"></i>
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MyQrCodes;
