import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

// Components Imports
import { Group, Input, Label } from "../../components/Form";
import { Brand } from "../../components/Authentication";
import Loading from "../../components/Loading";

// Utils Imports
import { LOCAL } from "../../constant";
import { toast } from "../../utils";

// Hooks Imports
import usePagesData from "../../hooks/pageData/usePagesData";
import { PageTypes } from "../../hooks/pageData/PageTypes";
import useAuth from "../../hooks/auth/useAuth";

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const token = useParams().token;

  const { pageData, isPending, isError } = usePagesData({ pageType: PageTypes.FORGOT_PASSWORD });
  const { doUpdatePasswordToken } = useAuth();

  if (isPending || isError) return <Loading />;

  const logo = LOCAL + pageData.Logo.data.attributes.url;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isLoading) return;
    setIsLoading(true);

    const formData = new FormData(event.target);
    const payload = Object.fromEntries(formData.entries());
    const { password, passwordConfirmation } = payload;

    if (password !== passwordConfirmation) {
      return toast(pageData.PasswordsDoNotMatch);
    }


    doUpdatePasswordToken({ password, passwordConfirmation, token });
  };

  const backgroundStyle = {
    backgroundImage: `url(${LOCAL + pageData.Image.data?.attributes.url})`,
    backgroundSize: "cover",
  };

  return (
    <>
      <div className="auth-fluid" style={backgroundStyle}>
        <div className="auth-fluid-form-box">
          <div className="align-items-center d-flex h-100">
            <div className="card-body">
              <Brand logo={logo} />

              <h4 className="mt-0">{pageData.Title}</h4>
              <p className="text-muted mb-4">{pageData.Description}</p>

              <form onSubmit={handleSubmit}>
                <Group>
                  <Label htmlFor="password">{pageData.Password.Label}</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder={pageData.Password.Placeholder}
                  />
                </Group>
                <Group>
                  <Label htmlFor="passwordConfirmation">
                    {pageData.ConfirmPassword.Label}
                  </Label>
                  <Input
                    type="password"
                    name="passwordConfirmation"
                    id="passwordConfirmation"
                    placeholder={pageData.ConfirmPassword.Placeholder}
                  />
                </Group>
                <div className="mb-0 text-center d-grid">
                  <button className="btn btn-primary" type="submit">
                    <i className="mdi mdi-lock-reset"></i>{" "}
                    {pageData.RecoverPassword}
                  </button>
                </div>
              </form>

              <footer className="footer footer-alt">
                <p className="text-muted">
                  {pageData.RememberPassword}{" "}
                  <Link to="/signin" className="text-muted ms-1">
                    <b>{pageData.Login}</b>
                  </Link>
                </p>
              </footer>
            </div>
          </div>
        </div>
        <div className="auth-fluid-right text-center">
          <div className="auth-user-testimonial">
            <h2 className="mb-3">{pageData.TitleImage}</h2>
            <p>{pageData.TextImage}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
