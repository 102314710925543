import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "../../styles/pages/CreateQR.css";

// Components Imports
import Card from "../../components/Card";
import Loading from "../../components/Loading";
import QRPreview from "../../components/QRPreview";
import QRStyleEditor from "../../components/QRStyleEditor";
import QrCodeInputSwitch from "../../components/QrCodeInputSwitch";
import { Group, Input, Label, RangeDate, Switch, TextArea } from "../../components/Form";

// Utils Imports
import { LOCAL } from "../../constant";
import { handleFieldValidation, stringifyWifiUrl, toast } from "../../utils";

// Hooks Imports
import { useAuthContext } from "../../context/AuthContext";
import usePagesData from "../../hooks/pageData/usePagesData";
import { PageTypes } from "../../hooks/pageData/PageTypes";
import useQrCodes from "../../hooks/qrCode/useQrCodes";

const QR_CODE_INITIAL = {
  name: "",
  description: "",
  dotColor: "",
  dotFormat: "square",
  backgroundColor: "#FFFFFF",
  cornerDotFormat: "square",
  cornerDotColor: "#000000",
  cornerSquareFormat: "square",
  cornerSquareColor: "#000000",
  url: "",
  text: "",
  identifier: "",
  static: false,
  type: "",
  startDate: "",
  endDate: "",
};

const WIFI_INITIAL = {
  wifiName: "",
  wifiPassword: "",
  wifiIncription: "",
  isHidden: false,
};

let qrCodeDimensions = { width: 250, height: 250 };

const CreateQR = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { doAddQrCode } = useQrCodes();
  const [qrCode, setQRCode] = useState(QR_CODE_INITIAL);
  const [wifi, setWifi] = useState(WIFI_INITIAL);
  const [linkList, setLinkList] = useState([]);

  const [files, setFiles] = useState("");
  const [showQR, setShowQR] = useState(false);
  const [generateCustomUrl, setGenerateCustomUrl] = useState(false);
  const [selectedPage, setSelectedPage] = useState(1);
  const [urlPreference, setUrlPreference] = useState(true);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const page = new URLSearchParams(location.search).get("page");
    const pageNumber = parseInt(page, 10);
    
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= 4) {

      setSelectedPage(pageNumber);
    }
  }, [location]);

  useEffect(() => {
    if (user)
      setQRCode((prev) => ({
        ...prev,
        approval: user?.needsApproval ? "Pending" : "Approved",
        users_permissions_user: user?.id,
      }));
  }, [user]);

  useEffect(() => {
    if (qrCode.type === "Wifi")
      setQRCode((prev) => ({
        ...prev,
        url: stringifyWifiUrl(wifi),
      }));
  }, [wifi]);

  useEffect(() => {
    if (
      qrCode.type === "MP3" ||
      qrCode.type === "PDF" ||
      qrCode.type === "Imagens" ||
      (qrCode.type === "Vídeo" && urlPreference === false) ||
      (qrCode.type === "Menu" && urlPreference === false) ||
      (qrCode.type === "Cupon" && urlPreference === false)
    ) {
      setQRCode({ ...qrCode, url: "", mediaField: files });
    }
  }, [files]);

  useEffect(() => {
    if (qrCode.type === "Lista de Links") {
      setQRCode({ ...qrCode, qr_code_linktrees: linkList });
    }
  }, [linkList]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("page") && !urlParams.has("qrcodeType")) {
      window.location.href = window.location.origin + window.location.pathname;
    }
  }, []);

  const { pageData, isPending } = usePagesData({ pageType: PageTypes.CREATE_QR });

  if (isPending) return <Loading />;

  const handleValueChange = (event) => {
    const { name, value } = event.target;
    setQRCode((old) => ({
      ...old,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleStaticChange = (type, isStatic) => {
    setQRCode((old) => {
      return {
        ...old,
        type: type,
        static: isStatic,
      };
    });
  };
  const handleStartDateChange = (e) => {
    setQRCode({ ...qrCode, startDate: e.target.value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      startDate: "",
    }));
  };

  const handleEndDateChange = (e) => {
    setQRCode({ ...qrCode, endDate: e.target.value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      endDate: "",
    }));
  };

  const handlePagination = (type) => {
    let nextPage = selectedPage;

    if (selectedPage === 1) {
      if (qrCode.type === "" && type === null) {
        return toast(pageData.SelectQRType);
      }
      nextPage = selectedPage + 1;
    } else if (selectedPage === 2) {
      const validationErrors = handleFieldValidation(
        qrCode,
        wifi,
        urlPreference,
        generateCustomUrl,
        files,
        linkList,
        pageData.fieldValidationErrors
      );
      
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      } else {
        nextPage = selectedPage + 1;
      }
    } else if (selectedPage === 3) {
      setShowQR(true);
      nextPage = selectedPage + 1;
    }
    navigate(`?page=${nextPage}`);
  };

  const clearQRCode = () => {
    setQRCode({
      name: "",
      description: "",
      dotColor: "",
      dotFormat: "square",
      backgroundColor: "#FFFFFF",
      cornerDotFormat: "square",
      cornerDotColor: "#000000",
      cornerSquareFormat: "square",
      cornerSquareColor: "#000000",
      url: "",
      text: "",
      identifier: "",
      static: false,
      type: "",
      startDate: "",
      endDate: "",
    });
    setGenerateCustomUrl(false);
    setUrlPreference(true);
    setFiles("");
    setErrors({});
  };
  
  const postQRCode = () => {
    var valid =
      qrCode.name ||
      qrCode.dotColor ||
      qrCode.dotFormat ||
      qrCode.backgroundColor ||
      qrCode.cornerDotFormat ||
      qrCode.cornerDotColor ||
      qrCode.cornerSquareFormat ||
      qrCode.cornerSquareColor ||
      qrCode.url ||
      qrCode.type;

    if (valid) {
      doAddQrCode({ qrCode });
    } else {
      toast(pageData.MissingData);
    }
  };

  const currentTitle = (selectedPage, pageData) => {
    switch (selectedPage) {
      case 1:
        return [pageData.FirstTitle, pageData.FirstTitle2];
      case 2:
        return [pageData.SecondTitle, pageData.SecondTitle2];
      case 3:
        return [pageData.ThirdTitle, pageData.ThirdTitle2];
      default:
        return null;
    }
  };

  const getCurrentTypeContent = (type, dynamicTypes) => {
    const currentType = dynamicTypes.find((t) => t.attributes.Type === type);

    const content = {
      typeTitle: currentType?.attributes.Title || "",
      typeDescription: currentType?.attributes.Description || "",
      typePhoneImage:
        currentType?.attributes.PhoneImage.data.attributes.url || "",
    };

    return content;
  };
  
  const dynamicTypes = pageData.dynamic_qr_code_types.data;
  const staticTypes = pageData.static_qr_code_types.data;

  const dynamicTypeElements = dynamicTypes.map((type) => {
    const typeData = type.attributes;

    return (
      <div
        key={`dynamic-type-${typeData.Type}`}
        className="col-lg-4 col-md-6 col-sm-6"
      >
        <div className="grid-container">
          <div
            className="card hoverCard"
            key={"dynamic-" + typeData.Type}
            onMouseOver={(e) => handleStaticChange(typeData.Type, false)}
            onMouseOut={(e) => handleStaticChange("", false)}
            onClick={() => {
              handlePagination(typeData.Type);
            }}
          >
            <div className="row align-items-center">
              <div className="col-3">
                <div className="card-body">
                  <img
                    className="icon"
                    src={LOCAL + typeData.Image.data.attributes.url}
                    alt={typeData.Type}
                  />
                </div>
              </div>
              <div className="col">
                <div className="card-body">
                  <h5 className="card-title">{typeData.Title}</h5>
                  <p className="card-text">{typeData.ShortDescription}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  const staticTypeElements = staticTypes.map((type) => {
    const typeData = type.attributes;
    return (
      <div
        key={`static-type-${typeData.Type}`}
        className="col-lg-4 col-md-6 col-sm-6"
      >
        <div className="grid-container">
          <div
            className="card hoverCard"
            key={"dynamic-" + typeData.Type}
            onMouseOver={(e) => handleStaticChange(typeData.Type, true)}
            onMouseOut={(e) => handleStaticChange("", true)}
            onClick={handlePagination}
          >
            <div className="row align-items-center">
              <div className="col-3">
                <div className="card-body">
                  <img
                    className="icon"
                    src={LOCAL + typeData.Image.data.attributes.url}
                    alt={typeData.Type}
                  />
                </div>
              </div>
              <div className="col">
                <div className="card-body">
                  <h5 className="card-title">{typeData.Title}</h5>
                  <p className="card-text">{typeData.ShortDescription}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  const PageButton = ({
    page,
    label,
    iconClass,
    isActive,
    isDisabled,
    onClick,
  }) => (
    <button
      type="button"
      className={`btn ${isActive ? "btn-primary" : "btn-light"} ${
        isDisabled ? "disabled" : ""
      }`}
      onClick={onClick}
      disabled={isDisabled}
    >
      <i className={`${iconClass} me-2 font-16`}></i>
      {label}
    </button>
  );

  const pageButtons = [
    {
      page: 1,
      label: pageData.ButtonText1,
      iconClass:
        selectedPage > 1
          ? "mdi mdi-checkbox-marked-circle-outline text-success"
          : "mdi mdi-checkbox-blank-circle-outline",
      isActive: selectedPage === 1,
      isDisabled: false,
      onClick: () => {
        clearQRCode();
        setSelectedPage(1);
      },
    },
    {
      page: 2,
      label: pageData.ButtonText2,
      iconClass:
        selectedPage > 2
          ? "mdi mdi-checkbox-marked-circle-outline text-success"
          : "mdi mdi-checkbox-blank-circle-outline",
      isActive: selectedPage === 2,
      isDisabled: selectedPage < 2,
      onClick: () => setSelectedPage(2),
    },
    {
      page: 3,
      label: pageData.ButtonText3,
      iconClass: "mdi mdi-checkbox-blank-circle-outline",
      isActive: selectedPage === 3,
      isDisabled: selectedPage < 3,
      onClick: () => setSelectedPage(3),
    },
  ];

  const typeContent = getCurrentTypeContent(qrCode.type, dynamicTypes);
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <div className="page-title-right">
              {selectedPage === 3 && (
                <button
                  className="btn btn-primary float-end ms-2"
                  onClick={() => postQRCode()}
                >
                  <i className="mdi mdi-content-save-check me-2"></i>
                  {pageData.Submit}
                </button>
              )}
              {selectedPage >= 2 && (
                <>
                  {selectedPage !== 3 && (
                    <button
                      className="btn btn-primary float-end ms-2"
                      onClick={handlePagination}
                    >
                      {pageData.Next}
                      <i className="mdi mdi-arrow-right ms-2"></i>
                    </button>
                  )}

                  <button
                    className="btn btn-outline-secondary float-end"
                    onClick={
                      selectedPage > 2
                        ? () => {
                            setSelectedPage((prev) => prev - 1);
                          }
                        : () => {
                            clearQRCode();
                            setSelectedPage((prev) => prev - 1);
                          }
                    }
                  >
                    <i className="mdi mdi-arrow-left me-2"></i>
                    {pageData.Back}
                  </button>
                </>
              )}
            </div>
            <h4 className="page-title">
              {currentTitle(selectedPage, pageData)[0] +
                " " +
                currentTitle(selectedPage, pageData)[1]}
            </h4>
          </div>
        </div>
        <div className="row row-content">
          <div className="col-lg-9">
            <div className="btn-group mb-2 w-100">
              {pageButtons.map((btn, index) => (
                <PageButton
                  key={`page-button-${index}`}
                  page={btn.page}
                  label={btn.label}
                  iconClass={btn.iconClass}
                  isActive={btn.isActive}
                  isDisabled={btn.isDisabled}
                  onClick={btn.onClick}
                />
              ))}
            </div>

            {selectedPage === 1 && (
              <>
                <Card title={pageData.DynamicTitle}>
                  <div className="row">
                    <p>{pageData.DynamicDescription}</p>
                    <div className="row">{dynamicTypeElements}</div>
                  </div>
                </Card>
                <Card title={pageData.StaticTitle}>
                  <p>{pageData.StaticDescription}</p>
                  <div className="row">
                    <div className="row">{staticTypeElements}</div>
                  </div>
                </Card>
              </>
            )}
            {selectedPage === 2 && (
              <>
                <Card title={pageData.QrCodeInfo}>
                  <Group>
                    <Label htmlFor="name">{pageData.Name.Label}</Label>
                    <Input
                      disabled={false}
                      onChange={handleValueChange}
                      value={qrCode.name}
                      name="name"
                      placeholder={pageData.Name.Placeholder}
                      error={errors.name}
                    />
                  </Group>
                  <Group>
                    <Label htmlFor="description">
                      {pageData.Description.Label}
                    </Label>
                    <TextArea
                      disabled={false}
                      onChange={handleValueChange}
                      value={qrCode.description}
                      name="description"
                      placeholder={pageData.Description.Placeholder}
                      error={errors.description}
                    />
                  </Group>

                  {!qrCode.static && (
                    <>
                      <Group>
                        <Label htmlFor="identifier">
                          {pageData.URLConfiguration.Label}
                        </Label>
                        <Input
                          disabled={!generateCustomUrl}
                          onChange={handleValueChange}
                          value={qrCode.identifier}
                          name="identifier"
                          type="url"
                          placeholder={pageData.URLConfiguration.Placeholder}
                          error={errors.urlConfig}
                        />
                      </Group>
                      <Group>
                        <Input
                          id="generateCustomUrl"
                          type="checkbox"
                          className="me-2"
                          name="urlConfig"
                          url="url"
                          checked={!generateCustomUrl}
                          onChange={() => setGenerateCustomUrl((prev) => !prev)}
                        />
                        <Label type="checkbox" htmlFor="generateCustomUrl">
                          {pageData.GenerateAutomatically}
                        </Label>
                      </Group>
                    </>
                  )}
                </Card>
                <Card title={typeContent.typeTitle}>
                  {(qrCode.type === "Vídeo" ||
                    qrCode.type === "Menu" ||
                    qrCode.type === "Cupon") && (
                    <div className="row">
                      <div className="createQR--checkbox-container">
                        <Group>
                          <div className="row">
                            <Label>{pageData.UseURL}</Label>
                          </div>
                          <Switch
                            id={"useUrl"}
                            name="useUrl"
                            onLabel="Sim"
                            offLabel="Não"
                            checked={urlPreference}
                            onChange={() => setUrlPreference(!urlPreference)}
                          />
                        </Group>
                      </div>
                    </div>
                  )}
                  {qrCode.type !== "Wifi" &&
                    qrCode.type !== "MP3" &&
                    qrCode.type !== "PDF" &&
                    qrCode.type !== "Texto" &&
                    qrCode.type !== "Comentários" &&
                    urlPreference &&
                    qrCode.type !== "Imagens" &&
                    qrCode.type !== "Lista de Links" && (
                      <Group>
                        <Label htmlFor="url">{pageData.DestinyURL.Label}</Label>
                        <Input
                          disabled={false}
                          onChange={handleValueChange}
                          value={qrCode.url}
                          name="url"
                          placeholder={pageData.DestinyURL.Placeholder}
                          error={errors.url}
                        />
                      </Group>
                    )}
                  {(qrCode.type === "Texto" ||
                    qrCode.type === "Comentários") && (
                    <Group>
                      <TextArea
                        disabled={false}
                        onChange={handleValueChange}
                        value={qrCode.text}
                        name="text"
                        placeholder={
                          qrCode.type === "Texto" ||
                          qrCode.type === "Comentários"
                            ? pageData.TextPlaceholder
                            : pageData.CommentPlaceholder
                        }
                        error={errors.text}
                      />
                    </Group>
                  )}

                  <QrCodeInputSwitch
                    qrCode={qrCode}
                    setFiles={setFiles}
                    wifi={wifi}
                    setWifi={setWifi}
                    urlPreference={urlPreference}
                    linkList={linkList}
                    setLinkList={setLinkList}
                    pageData={pageData}
                    typeTitle={typeContent.typeTitle}
                    errors={errors}
                    setErrors={setErrors}
                  />
                </Card>
                <Card title={pageData.ScheduleCampaign}>
                  <div className="createQR--form">
                    <RangeDate
                      isDisabled={false}
                      min={true}
                      onChange={handleValueChange}
                      startName="startDate"
                      endName="endDate"
                      startDate={qrCode.startDate}
                      endDate={qrCode.endDate}
                      handleStartDateChange={handleStartDateChange}
                      handleEndDateChange={handleEndDateChange}
                      startDateLabel={pageData.Start}
                      endDateLabel={pageData.End}
                      pageData={pageData}
                      errorDate1={errors.startDate}
                      errorDate2={errors.endDate}
                    />
                  </div>
                </Card>
              </>
            )}
            {selectedPage === 3 && (
              <Card>
                <QRStyleEditor
                  qrCode={qrCode}
                  setQRCode={setQRCode}
                  handleValueChange={handleValueChange}
                  pageData={pageData}
                />
              </Card>
            )}
          </div>
          <div className="col-lg-3 d-flex justify-content-center previewQr">
            <div className="grid-container  h-100">
              <div className="createQR--preview overflow-auto h-100">
                <h3 className="text-center">
                  {" "}
                  {typeContent.typeTitle || pageData.QRCodeExample}{" "}
                </h3>

                {selectedPage === 3 ? (
                  <>
                    <div className="createQR--qrPreview-container">
                      <QRPreview
                        pageData={pageData}
                        edit
                        className="createQR--qrPreview"
                        qrCode={qrCode}
                        width={qrCodeDimensions.width} 
                        height={qrCodeDimensions.height}
                      />
                    </div>
                  </>
                ) : (
                  <img
                    src={
                      LOCAL +
                      (qrCode.type
                        ? typeContent.typePhoneImage
                        : pageData.DefaultPhoneImage.data.attributes.url)
                    }
                    alt="iphone"
                    style={{ display: showQR ? "none" : "block" }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateQR;
