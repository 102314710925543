import 'react-toastify/dist/ReactToastify.css';
import { toast as t } from 'react-toastify';

export function toast({ Type, Message }) {
  switch (Type) {
    case 'success':
      t.success(Message);
      break;
    case 'error':
      t.error(Message);
      break;
    case 'warn':
      t.warn(Message);
      break;
    case 'warning':
      t.warning(Message);
      break;
    case 'loading':
      t.loading(Message);
      break;
    default:
      t.info(Message);
      break;
  }
}