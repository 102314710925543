import React, { useEffect, useState } from "react";

// Components Imports
import Card from "../../components/Card";
import Loading from "../../components/Loading";
import { Group, Input, Label } from "../../components/Form";

// Hooks Imports
import { useAuthContext } from "../../context/AuthContext";
import useUser from "../../hooks/user/useUser";
import usePagesData from "../../hooks/pageData/usePagesData";

// Utils Imports
import { LOCAL } from "../../constant";
import { getUser, updateMe } from "../../services/apiCalls/db";
import { toast } from "../../utils";
import { PageTypes } from "../../hooks/pageData/PageTypes";

const formId = "profile";

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useAuthContext();
  const { doGetCurrentUser, isLoadingUser } = useUser();
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [errorNif, setErrorNif] = useState("");
  const [data, setData] = useState({
    username: "",
    description: "",
    contact: "",
    nif: "",
    email: "",
    avatar: null,
    avatarFile: null,
  });

  useEffect(() => {
    if (user) {
      setData({
        username: user.username ?? "",
        description: user.description ?? "",
        contact: user.contact ?? "",
        nif: user.nif ?? "",
        email: user.email ?? "",
        avatar: user.avatar,
        avatarFile: null,
      });
    }
  }, [user]);
  useEffect(() => {
    if (!isLoadingUser) {
      doGetCurrentUser();
    }
  }, [isLoadingUser]);

  const handleTextChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const isValidNIF = (nif) => {
    const patternEmpresa = /^5\d{8}$/;
    const patternParticular = /^2\d{8}$/;

    return patternEmpresa.test(nif) || patternParticular.test(nif);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setData((prevData) => ({
          ...prevData,
          avatar: reader.result,
          avatarFile: file,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageClick = () => {
    document.getElementById("avatarInput").click();
  };

  const toggleEditProfile = () => setIsEditingProfile((prev) => !prev);

  const handleProfileUpdate = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (data.nif && !isValidNIF(data.nif)) {
      setErrorNif(pageData.InvalidNIF.Message);
      setLoading(false);
      return;
    } else {
      setErrorNif("");
    }

    try {
      await updateMe(data);
      const updatedUser = await getUser();
      setUser(updatedUser);
      toast(pageData.UserUpdatedSuccessfully);
    } catch {
      toast(pageData.ErrorUpdatingUser);
    } finally {
      setLoading(false);
      setIsEditingProfile(false);
    }
  };

  const { pageData: pageContent, isPending: pageIsLoading } = usePagesData({ pageType: PageTypes.PROFILE_PAGE });
  const pageData = pageContent?.pageData;

  if (!user || !data || pageIsLoading || loading) return <Loading />;

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <div className="page-title-right">
              {isEditingProfile ? (
                <button
                  form={formId}
                  type="submit"
                  className="btn btn-primary float-end"
                >
                  <i className="mdi mdi-content-save-check me-2"></i>
                  {pageData.SaveChanges}
                </button>
              ) : (
                <></>
              )}
              {!isEditingProfile ? (
                <button
                  form={formId}
                  type="button"
                  className="btn btn-primary float-end"
                  onClick={toggleEditProfile}
                >
                  <i className="mdi mdi-account-edit me-2"></i>
                  {pageData.EditProfile}
                </button>
              ) : (
                <></>
              )}
            </div>
            <h4 className="page-title">{pageData.Title}</h4>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <Card>
            <form onSubmit={handleProfileUpdate} id={formId}>
              <div className="grid-structure">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <img
                      src={
                        data.avatar
                          ? data.avatar.url
                            ? LOCAL + data.avatar.url
                            : data.avatar
                          : LOCAL + pageData.DefaultAvatar.data.attributes.url
                      }
                      alt="Avatar"
                      className="rounded-circle avatar-lg img-thumbnail"
                      onClick={isEditingProfile ? handleImageClick : null}
                      style={{
                        cursor: isEditingProfile ? "pointer" : "default",
                      }}
                    />
                    {isEditingProfile && (
                      <input
                        type="file"
                        id="avatarInput"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                      />
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <Group>
                      <Label htmlFor="username">{pageData.Name.Label}</Label>
                      <Input
                        type="text"
                        id="username"
                        name="username"
                        onChange={handleTextChange}
                        placeholder={pageData.Name.Placeholder}
                        value={data.username}
                        disabled={!isEditingProfile}
                      />
                    </Group>
                    <Group>
                      <Label htmlFor="description">
                        {pageData.Description.Label}
                      </Label>
                      <Input
                        type="text"
                        id="description"
                        name="description"
                        value={data.description}
                        onChange={handleTextChange}
                        disabled={!isEditingProfile}
                      />
                    </Group>
                  </div>
                  <div className="col-lg-4 col-sm-12">
                    <Group>
                      <Label htmlFor="nif">{pageData.NIF.Label}</Label>
                      <Input
                        type="number"
                        id="nif"
                        name="nif"
                        value={data.nif}
                        onChange={handleTextChange}
                        disabled={!isEditingProfile}
                        error={errorNif}
                      />
                    </Group>
                  </div>
                  <div className="col-lg-4 col-sm-12">
                    <Group>
                      <Label htmlFor="contact">{pageData.Contact.Label}</Label>
                      <Input
                        type="text"
                        id="contact"
                        name="contact"
                        value={data.contact}
                        onChange={handleTextChange}
                        disabled={!isEditingProfile}
                      />
                    </Group>
                  </div>
                  <div className="col-lg-4 col-sm-12">
                    <Group>
                      <Label htmlFor="email">{pageData.Email.Label}</Label>
                      <Input
                        type="email"
                        id="email"
                        name="email"
                        value={data.email}
                        onChange={handleTextChange}
                        disabled={!isEditingProfile}
                      />
                    </Group>
                  </div>
                </div>
              </div>
            </form>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Profile;
