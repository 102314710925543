import { ConnectionError, FetchingError, UploadError, ValidationError } from "../services/errors";
import { toast } from "../services/toast";
import { ToastType } from "../services/toast/ToastType";

export function internalErrors (err) {
  if (err instanceof ConnectionError) {
    toast({ 
      actionName: ToastType.INTERNAL_ERRORS.ACTION_NAME, 
      actionMessage: ToastType.INTERNAL_ERRORS.MESSAGES.CONNECTION_ERROR
    });
    return true;
  } else if (err instanceof FetchingError) {
    toast({ 
      actionName: ToastType.INTERNAL_ERRORS.ACTION_NAME, 
      actionMessage: ToastType.INTERNAL_ERRORS.MESSAGES.FETCHING_ERROR
    });
    return true;
  } else if (err instanceof UploadError) {
    toast({ 
      actionName: ToastType.INTERNAL_ERRORS.ACTION_NAME, 
      actionMessage: ToastType.INTERNAL_ERRORS.MESSAGES.UPLOAD_ERROR
    });
    return true;
  } else if (err instanceof ValidationError) {
    toast({ 
      actionName: ToastType.INTERNAL_ERRORS.ACTION_NAME, 
      actionMessage: ToastType.INTERNAL_ERRORS.MESSAGES.VALIDATION_ERROR
    });
    return true;
  }
  return false;
}