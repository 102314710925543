import makeRequest from "../../dataFetching"
import { API_QR_CODES_SCANS } from "../../../utils/constants";
import { qrCodeFindScansMapping, findQrCodeScansFilesMapping, deleteScanMapping } from "../../dataMapping/qrCode/scans";

export function findQrCodeScans () {
  return makeRequest(API_QR_CODES_SCANS)
    .then(qrCodeFindScansMapping);
}

export function findQrCodeScansFiles({ qrCodeId }) {
  return makeRequest(`/qr-codes/${qrCodeId}?populate[0]=qr_code_linktrees&populate[1]=qr_code_linktrees.image&populate[2]=image&populate[3]=mediaField&populate[4]=scans`)
    .then(findQrCodeScansFilesMapping);
}


export function findOneScans ({ id }) {}


export function createScans ({}) {}


export function updateScans ({ id }, {}) {}

export function deleteScan ({ id }) {
  return makeRequest(`/scans/${id}`, {
    method: "DELETE"
  })
  .then(deleteScanMapping);
}

export async function deleteScans ({ ids }) {
  const deleteScansPromises = ids.map(id => deleteScan({ id }));
  return await Promise.all(deleteScansPromises);
}
