import makeRequest from "../../dataFetching"
import { API_PLANS_AND_PRICING, INFO_MENU } from "../../../utils/constants";
import { findPlansAndPricingMapping } from "../../dataMapping/plansAndPricing";
import { infoMenuMapping } from "../../dataMapping/infoMenu";
/**
 * FIND
 * Fetches all PlansAndPricing entries.
 * @returns {Promise<Object>} A promise that resolves to the response data from the GET request.
 */
export async function findPlansAndPricingPage () {
  const responsePlansAndPricing = await makeRequest(API_PLANS_AND_PRICING);
  const responseInfoMenu = await makeRequest(INFO_MENU);
  
  const mappedPlansAndPricing = findPlansAndPricingMapping(responsePlansAndPricing);
  const mappedInfoMenu = infoMenuMapping(responseInfoMenu);

  return { 
    pageData: mappedPlansAndPricing,
    infoCardData: mappedInfoMenu
  }
}

/**
 * CREATE LOCALIZATION
 * Creates a new PlansAndPricing localization entry.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function createLocalizationPlansAndPricing ({}) {}


/**
 * PUT
 * Updates an existing PlansAndPricing entry.
 * @param {integer} id - The ID of the PlansAndPricing entry to update.
 * @returns {Promise<Object>} A promise that resolves to the response data from the PUT request.
 */
export function updatePlansAndPricing (id, {}) {}


/**
 * DELETE
 * Deletes a specific PlansAndPricing entry by ID.
 * @param {integer} id - The ID of the PlansAndPricing entry to delete.
 * @returns {Promise<Object>} A promise that resolves to the response data from the DELETE request.
 */
export function deletePlansAndPricing ({ id }) {}
