import { Link } from "react-router-dom";

import "../../styles/components/PricePlan.css";

export function PricePlan({
  isPreview,
  recommended = "",
  name,
  icon,
  price,
  currency,
  interval,
  frequency,
  features,
  slug,
  button = { text: "", onClick: null },
}) {
  const colClassName = !isPreview ? "col-md-4" : "col-md-6";

  const planNameClassName = !isPreview ? "card-pricing-plan-name" : "";

  const featureList = features.map((feature, index) => {
    return <li key={`plan-${index}-feature-${feature}`}>{feature}</li>;
  });

  return (
    <div className={colClassName}>
      <div
        className={`card card-pricing h-100 ${
          recommended ? "card-pricing-recommended" : ""
        }`}
      >
        <div className="card-body text-center d-flex flex-column align-items-center">
          {recommended && (
            <div className="card-pricing-plan-tag align-self-stretch">
              {recommended}
            </div>
          )}
          <p className={`fw-bold text-uppercase ${planNameClassName}`}>
            {name}
          </p>
          {!isPreview && (
            <i className={`card-pricing-icon ${icon} text-primary`}></i>
          )}
          <h2 className="card-pricing-price p-0">
            {price}
            {currency}
            <span> / {interval}</span>
          </h2>
          <span className="text-gray frequency-text-size">{frequency}</span>
          {!isPreview && (
            <ul className="card-pricing-features">{featureList}</ul>
          )}
          {button.onClick === null ? (
            <Link to={`/checkout?plan=${slug}`} className="mt-auto">
              <button className="btn btn-primary mt-2">{button.text}</button>
            </Link>
          ) : (
            <button onClick={button.onClick} className="btn btn-primary mt-2">
              {button.text}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
