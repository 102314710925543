import { Group, Label, SelectComponent } from "../Form";
import { useThemeSelect } from "./ThemeSelector.hooks";

const dummyThemes = [
  { value: "light", label: "Light Mode" },
  { value: "dark", label: "Dark Mode" },
];

export default function ThemeSelector({ pageData }) {
  const [themes, setThemes] = useThemeSelect();

  const pageDefaultValue = dummyThemes.find((t) => t.value === themes.page);
  const sidebarDefaultValue = dummyThemes.find(
    (t) => t.value === themes.sidebar
  );

  return (
    <>
      <Group>
        <Label htmlFor="page-color">{pageData.pageColor.Label}</Label>
        <SelectComponent
          id="page-color"
          placeholder={pageData.pageColor.Placeholder}
          options={dummyThemes}
          isSearchable={false}
          defaultValue={pageDefaultValue}
          onChange={(selected) => {
            setThemes({
              ...themes,
              page: selected.value,
            });
          }}
        />
      </Group>
      <Group>
        <Label htmlFor="sidebar-color">{pageData.sidebarColor.Label}</Label>
        <SelectComponent
          id="sidebar-color"
          placeholder={pageData.sidebarColor.Placeholder}
          options={dummyThemes}
          isSearchable={false}
          defaultValue={sidebarDefaultValue}
          onChange={(selected) => {
            setThemes({
              ...themes,
              sidebar: selected.value,
            });
          }}
        />
      </Group>
    </>
  );
}
