import { useState, useEffect } from "react";
import { fetchLottie } from "../../services/apiCalls/lottie";
import { BASE } from "../../utils/constants";

export default function useLottie (lottieType) {
  const [data, setData] = useState(null);
  const [animationData, setAnimationData] = useState(null);
  const [animationIsLoading, setAnimationIsLoading] = useState(true);

  useEffect(() => {
    if (!lottieType) return;
    fetchLottie(lottieType)
      .then(res => {
        if (res.error) throw new Error(res.error.Message);
        setData(res);
        setAnimationIsLoading(false);
      })
      .catch(err => {
        setAnimationIsLoading(false);
      })
  }, []);

  useEffect(() => {
    if (!data) return;
    fetch(`${BASE}${data.data[0].attributes.file.data.attributes.url}`)
      .then(res => res.json())
      .then(data => {
        setAnimationData(data)
      })
      .catch(err => {
        setAnimationData(false);
      })

  }, [data])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return { defaultOptions, animationIsLoading }
}