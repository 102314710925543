import { Link } from "react-router-dom"

export function Brand({ logo = "", logoDark = logo, logoLight = "logo" }) {
  return (
    <div className="auth-brand text-center text-lg-start">
      <Link to="/" className="logo-dark">
        <span><img src={logoDark} alt="" height="20" /></span>
      </Link>
      <Link to="/" className="logo-light">
        <span><img src={logoLight} alt="" height="20" /></span>
      </Link>
    </div>
  )
}