import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastType } from "../../services/toast/ToastType";
import { toast } from "../../services/toast";
import { getAllQRCodesFromUser, getQRCode, getQRCodeByIdentifier, updateQRCode, deleteQRCode, addQRCode } from "../../services/apiCalls/db";
import { internalErrors } from "../../utils/error";
import { parseWifiUrl } from "../../utils";

export default function useQrCodes () {
  const navigate = useNavigate();
  const [QRCodes, setQRCodes] = useState([]);
  const [qrCode, setQrCode] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [isError, setIsError] = useState(false);
  const [qrCodeIdentifier, setQrCodeIdentifier] = useState(null);
  const [urlPreference, setUrlPreference] = useState(true);
  const [isEditingQrCode, setIsEditingQrCode] = useState(false);
  const [qrCodeDataUpdated, setQrCodeDataUpdated] = useState({
    id: "",
    name: "",
    url: "",
    description: "",
    state: "",
    createdAt: ""
  });
  const [wifi, setWifi] = useState({
    wifiName: "",
    wifiPassword: "",
    wifiIncription: "",
    isHidden: false
  });

  const doAddQrCode = ({ qrCode }) => {
    setIsPending(true);
    setIsError(false);

    addQRCode({ ...qrCode })
      .then(() => {
        toast({
          actionName: ToastType.ADD_QR_CODE.ACTION_NAME,
          actionMessage: ToastType.ADD_QR_CODE.MESSAGES.CREATE_QR_MESSAGE
        });
        setTimeout(() => {
          navigate("/MyQrCodes");
        }, 3000);
      })
      .catch((err) => {
        setIsError(true);
        if(!internalErrors(err)) qrCodeCreationToast(err);
      })
      .finally(() => setIsPending(false));
  }

  const qrCodeCreationToast = (errorMessage) => {
    if (errorMessage === "No active subscription found") {
      toast({
        actionName: ToastType.ADD_QR_CODE.ACTION_NAME,
        actionMessage: ToastType.ADD_QR_CODE.MESSAGES.NO_ACTIVE_SUBSCRIPTION
      });
    } else if (errorMessage === "Subscription expired") {
      toast({
        actionName: ToastType.ADD_QR_CODE.ACTION_NAME,
        actionMessage: ToastType.ADD_QR_CODE.MESSAGES.SUBSCRIPTION_EXPIRED
      });
    } else if (errorMessage === "You have reached the maximum number of static QR Codes allowed for your plan") {
      toast({
        actionName: ToastType.ADD_QR_CODE.ACTION_NAME,
        actionMessage: ToastType.ADD_QR_CODE.MESSAGES.DYNAMIC_QR_CODES_EXCEEDED
      });
    } else if (errorMessage === "You have reached the maximum number of dynamic QR Codes allowed for your plan") {
      toast({
        actionName: ToastType.ADD_QR_CODE.ACTION_NAME,
        actionMessage: ToastType.ADD_QR_CODE.MESSAGES.STATIC_QR_CODES_LIMIT_EXCEEDED
      });
    } else if (errorMessage.includes("identifier")) {
      toast({
        actionName: ToastType.ADD_QR_CODE.ACTION_NAME,
        actionMessage: ToastType.ADD_QR_CODE.MESSAGES.URL_CONFIGURATION_ERROR
      });
    } else if (errorMessage.includes("unique")) {
      toast({
        actionName: ToastType.ADD_QR_CODE.ACTION_NAME,
        actionMessage: ToastType.ADD_QR_CODE.MESSAGES.CUSTOM_QRL_TAKEN
      });
    } else if (errorMessage.includes("UnsavedChangesError")) {
      toast({
        actionName: ToastType.ADD_QR_CODE.ACTION_NAME,
        actionMessage: ToastType.ADD_QR_CODE.MESSAGES.UNSAVED_CHANGES_ERROR
      });
    } else {
      toast({
        actionName: ToastType.ADD_QR_CODE.ACTION_NAME,
        actionMessage: ToastType.ADD_QR_CODE.MESSAGES.ERROR_OCURRED
      });
    }
  };

  const doGetAllQRCodesFromUser = () => {
    setIsPending(true);
    setIsError(false);

    getAllQRCodesFromUser()
      .then((data) => {
        setQRCodes(data);
      })
      .catch((err) => {
        setIsError(true);
        internalErrors(err);
      })
      .finally(() => setIsPending(false));
  }

  const doGetQrCodeByIdentifier = ({ qrCodeIdentifierParam }) => {
    setIsPending(true);
    setIsError(false);

    getQRCodeByIdentifier(qrCodeIdentifierParam)
      .then((data) => {
        setQrCodeIdentifier(data);
      })
      .catch(err => {
        setIsError(true);
        if (!internalErrors(err)) toast({
          actionName: ToastType.GET_QR_CODE_IDENTIFIER.ACTION_NAME,
          actionMessage: ToastType.GET_QR_CODE_IDENTIFIER.MESSAGES.SOMETHING_WENT_WRONG
        })
      })
      .finally(() => setIsPending(false));
  }

  const doGetQrCodes = ({ qrCodeId }) => {
    getQRCode(qrCodeId)
      .then(data => {
        setQrCode(data)
        setQrCodeDataUpdated(data)

        setUrlPreference(data.url !== "" && data.type !== "Wifi")

        if (data.type !== "Wifi") return;

        const parsedUrl = parseWifiUrl(data.url);
        if (parsedUrl) {
          setWifi(parsedUrl);
        }
      })
      .catch(() => {
        navigate("/404")
      })
  }

  const doUpdateQrCode = ({ qrCodeId }) => {
    updateQRCode(qrCodeId, cleanUrlOrFile(qrCodeDataUpdated))
      .then(() => {
        getQRCode(qrCodeId).then(data => {
          setQrCode(cleanUrlOrFile(data));
        })

        toast({
          actionName: ToastType.UPDATE_QR_CODE.ACTION_NAME,
          actionMessage: ToastType.UPDATE_QR_CODE.MESSAGES.QR_CODE_UPDATED_SUCCESSFULLY
        });
        setIsEditingQrCode(false);
      })
      .catch((err) => {
        if (!internalErrors(err)) {
          if (err === "You are not allowed to update this QR Code") {
            toast({
              actionName: ToastType.UPDATE_QR_CODE.ACTION_NAME,
              actionMessage: ToastType.UPDATE_QR_CODE.MESSAGES.NOT_ALLOWED_TO_EDIT_QR_CODE
            });
          } else {
            toast({
              actionName: ToastType.UPDATE_QR_CODE.ACTION_NAME,
              actionMessage: ToastType.UPDATE_QR_CODE.MESSAGES.ERROR_UPDATING_QR_CODE
            });
          }
        }
      });
  }

  const doDeleteQrCode = ({ currentQrCode }) => {
    deleteQRCode(currentQrCode.id)
      .then(() => {
        toast({
          actionName: ToastType.DELETE_QR_CODE.ACTION_NAME,
          actionMessage: ToastType.DELETE_QR_CODE.MESSAGES.DELETE_QR_CODES_SUCCESSFULLY
        });
        setTimeout(() => {
          navigate(-1);
        }, 3000);
      })
      .catch((err) => {
        if (!internalErrors(err)) {
          if (err === "You are not allowed to delete this QR Code") {
            toast({
              actionName: ToastType.DELETE_QR_CODE.ACTION_NAME,
              actionMessage: ToastType.DELETE_QR_CODE.MESSAGES.NOT_ALLOWED_TO_DELETE_QR_CODE
            });
          } else {
            toast({
              actionName: ToastType.DELETE_QR_CODE.ACTION_NAME,
              actionMessage: ToastType.DELETE_QR_CODE.MESSAGES.ERROR_DELETING_QR_CODE
            });
          }
        }
      })
  }

  function cleanUrlOrFile(dirtyQrCode) {
    if (!urlPreference)
      return { ...dirtyQrCode, url: "" };

    return { ...dirtyQrCode, mediaField: { data: [] } };
  }

  useEffect(() => {
    const sortedItems = QRCodes.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    setQRCodes(sortedItems);
  }, [QRCodes]);

  return { doAddQrCode, doGetAllQRCodesFromUser, doGetQrCodeByIdentifier, doGetQrCodes, doUpdateQrCode, doDeleteQrCode, setQrCode, setQrCodeDataUpdated, setUrlPreference, setWifi, setIsEditingQrCode, isEditingQrCode, qrCode, urlPreference, qrCodeDataUpdated, wifi, QRCodes, qrCodeIdentifier, isPending, isError };
}