import React from "react";
import { Link } from "react-router-dom";

// Components Imports
import { Group, Input, Label } from "../../components/Form";
import { Brand } from "../../components/Authentication";
import Loading from "../../components/Loading";
import Modal from "../../components/Modal";

// Hooks Imports
import useAuth from "../../hooks/auth/useAuth";

// Utils Imports
import { LOCAL } from "../../constant";
import { toast } from "../../utils";
import usePagesData from "../../hooks/pageData/usePagesData";
import { PageTypes } from "../../hooks/pageData/PageTypes";

const termsId = "terms", privacyId = "privacy";

const SignUp = () => {
  const { pageData, isPending: pageIsLoading, isError } = usePagesData({ pageType: PageTypes.SIGN_UP });
  
  const { doRegister } = useAuth();

  if (pageIsLoading || isError) return <Loading />;

  const logo = LOCAL + pageData.Logo.data.attributes.url;

  const privacyPopUp = pageData.PrivacyPopUp;
  const termsPopUp = pageData.TermsPopUp;

  const backgroundStyle = {
    backgroundImage: `url(${LOCAL + pageData.Image.data?.attributes.url})`,
    backgroundSize: "cover",
  };

  const checkFormValues = (formValues) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formValues.username) {
      toast(pageData.usernameMissing);
      return false;
    }

    if (!emailRegex.test(formValues.email)) {
      toast(pageData.emailNotValid);
      return false;
    }

    if (!formValues.password) {
      toast(pageData.passwordMissing);
      return false;
    }

    if (!formValues.confirmPassword) {
      toast(pageData.confirmPasswordMissing);
      return false;
    }

    if (formValues.confirmPassword !== formValues.password) {
      toast(pageData.passwordsDoNotMatch);
      return false;
    }

    if (!formValues.acceptTermsAndConditions) {
      toast(pageData.acceptTermsAndConditions);
      return false;
    }
  
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const payload = Object.fromEntries(new FormData(event.target).entries());

    if (checkFormValues(payload)) {

      const { username, email, password } = payload;

      doRegister({ username, email, password });
    }
  };

  return (
    <>
      <Modal
        id={termsId}
        title={termsPopUp.Title}
        size="scrollable"
        position="center"
      >
        <p>{termsPopUp.Description}</p>
      </Modal>
      <Modal
        id={privacyId}
        title={privacyPopUp.Title}
        size="scrollable"
        position="center"
      >
        <p>{privacyPopUp.Description}</p>
      </Modal>
      <div className="auth-fluid" style={backgroundStyle}>
        <div className="auth-fluid-form-box">
          <div className="align-items-center d-flex h-100">
            <div className="card-body">
              <Brand logo={logo} />

              <h4 className="mt-0">{pageData.Title}</h4>
              <p className="text-muted mb-4">{pageData.Description}</p>

              <form onSubmit={handleSubmit}>
                <Group>
                  <Label htmlFor="username">{pageData.Name.Label}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    id="username"
                    name="username"
                    placeholder={pageData.Name.Placeholder}
                    required
                  ></Input>
                </Group>
                <Group>
                  <Label htmlFor="email">{pageData.Email.Label}</Label>
                  <Input
                    type="email"
                    id="email"
                    name="email"
                    required
                    placeholder={pageData.Email.Placeholder}
                  />
                </Group>
                <Group>
                  <Label htmlFor="password">{pageData.Password.Label}</Label>
                  <Input
                    type="password"
                    id="password"
                    name="password"
                    required
                    placeholder={pageData.Password.Placeholder}
                  />
                </Group>
                <Group>
                  <Label htmlFor="confirmPassword">
                    {pageData.ConfirmPassword.Label}
                  </Label>
                  <Input
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    required
                    placeholder={pageData.ConfirmPassword.Placeholder}
                  />
                </Group>
                <div className="mb-3">
                  <div className="form-check">
                    <Input
                      type="checkbox"
                      id="checkbox"
                      name="acceptTermsAndConditions"
                    />
                    <Label type="checkbox" htmlFor="acceptTermsAndConditions">
                      {pageData.Agree}{" "}
                      <span className="text-muted">
                        <a href={`#${termsId}`} data-bs-toggle="modal">
                          {pageData.TermsOfUse}
                        </a>{" "}
                        {pageData.And}{" "}
                        <a href={`#${privacyId}`} data-bs-toggle="modal">
                          {pageData.PrivacyPolitics}
                        </a>
                      </span>
                    </Label>
                  </div>
                </div>
                <div className="mb-0 d-grid text-center">
                  <button className="btn btn-primary" type="submit">
                    <i className="mdi mdi-account-circle"></i> {pageData.SignUp}
                  </button>
                </div>
              </form>

              <footer className="footer footer-alt">
                <p className="text-muted">
                  {pageData.CreateAccountQuestion}
                  <Link to="/signin" className="text-muted ms-1">
                    <b>{pageData.Signin}</b>
                  </Link>
                </p>
              </footer>
            </div>
          </div>
        </div>
        <div className="auth-fluid-right text-center">
          <div className="auth-user-testimonial">
            <h2 className="mb-3">{pageData.TitleImage}</h2>
            <p>{pageData.TextImage}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
