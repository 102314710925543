import React from "react";

// Components Imports
import { Section, Hero, Card, CTABanner, Accordion, HeroButton } from "../../components/Landing";
import ExternalLottie from "../../components/ExternalLottie";
import InfoCard from "../../components/InfoCard";
import Loading from "../../components/Loading";

// Hooks Imports
import usePagesData from "../../hooks/pageData/usePagesData";

// Utils Imports
import { LOCAL } from "../../constant";
import Pricing from "../../components/Pricing";

// Services
import { LottieTypes } from "../../services/apiCalls/lottie/LottieTypes";
import { PageTypes } from "../../hooks/pageData/PageTypes";

const PlansAndPrices = () => {
  const plansId = "plans";

  const { pageData: pageContent, isPending, isError } = usePagesData({ pageType: PageTypes.PLANS_AND_PRICING });

  if (isPending || isError) return <Loading />;

  const pageData = pageContent.pageData;
  const infoCardData = pageContent.infoCardData;

  const header = pageData.Header;
  const headerSection_1 = pageData.HeaderSection_1;
  const headerSection_2 = pageData.HeaderSection_2;
  const headerSection_3 = pageData.HeaderSection_3;

  const paymentPlans = pageData.payment_plans.data;
  const planFeatures = pageData.ContentSection_2;
  const bannerCTA = pageData.BannerCTA;

  const headerImage = LOCAL + header.Image.data.attributes.url;

  const planFeatureCards = planFeatures.Cards.map((card) => {
    const image = LOCAL + card.Image.data.attributes.url;
    const title = card.Title;
    const description = card.Description;

    return (
      <div className="col-md-6 col-lg-4 h-100" key={`feature-card-${title}`}>
        <Card title={title} description={description} image={image} />
      </div>
    );
  });

  const faqCards = pageData.faq_cards.data.map((f) => {
    const faq = f.attributes;

    return { title: faq.question, description: faq.answer };
  });

  return (
    <>
      <Hero title={header.Title} subtitle={header.Subtitle} image={headerImage}>
        <HeroButton
          id={plansId}
          text={header.ButtonText}
          icon={header.ButtonIconClass}
        />
      </Hero>
      <Section
        isFirstSection={true}
        descriptions={[headerSection_1.Subtitle]}
        icon={headerSection_1.Icon}
        navigationId={plansId}
      >
        <Pricing paymentPlans={paymentPlans} recommendedText={pageData.RecommendedText} />
      </Section>
      <Section
        titles={headerSection_2.Title.map((t) => t.Text)}
        descriptions={[headerSection_2.Subtitle]}
        icon={headerSection_2.Icon}
      >
        <div className="row mt-2 py-5 align-items-stretch">
          {planFeatureCards}
        </div>
      </Section>
      <CTABanner
        text={bannerCTA.Title}
        button={bannerCTA.Button}
        url={bannerCTA.URL}
      />
      <Section
          titles={headerSection_3.Title.map((t) => t.Text)}
          descriptions={[headerSection_3.Subtitle]}
          icon={headerSection_3.Icon}
      >
        <div className="row g-4 align-items-center">
          <div className="col-lg-6">
            <Accordion items={faqCards} />
          </div>
          <div className="col-lg-6">
            <ExternalLottie lottieType={LottieTypes.PLANS_AND_PRICING} />
          </div>
        </div>
      </Section>
      <InfoCard infoCardData={infoCardData} />
    </>
  );
};

export default PlansAndPrices;
