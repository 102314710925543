import makeRequest from "../../dataFetching"
import { API_PAYMENTS_PLAN_PAGE } from "../../../utils/constants";
import { findPaymentPlansPageMapping } from "../../dataMapping/paymentPlansPage";
/**
 * FIND
 * Fetches all PaymentPlansPage entries.
 * @returns {Promise<Object>} A promise that resolves to the response data from the GET request.
 */
export function findPaymentPlansPage () {
  return makeRequest(API_PAYMENTS_PLAN_PAGE)
  .then(findPaymentPlansPageMapping);
}

/**
 * CREATE LOCALIZATION
 * Creates a new PaymentPlansPage localization entry.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function createLocalizationPaymentPlansPage ({}) {}


/**
 * PUT
 * Updates an existing PaymentPlansPage entry.
 * @param {integer} id - The ID of the PaymentPlansPage entry to update.
 * @returns {Promise<Object>} A promise that resolves to the response data from the PUT request.
 */
export function updatePaymentPlansPage (id, {}) {}


/**
 * DELETE
 * Deletes a specific PaymentPlansPage entry by ID.
 * @param {integer} id - The ID of the PaymentPlansPage entry to delete.
 * @returns {Promise<Object>} A promise that resolves to the response data from the DELETE request.
 */
export function deletePaymentPlansPage ({ id }) {}
