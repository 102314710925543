import React from "react";
import Select from "react-select";
import { useDarkMode } from "./../useDarkMode"; 

export const SelectComponent = React.forwardRef(({ ...props }, ref) => {
  const isDarkMode = useDarkMode();
  const rootStyle = getComputedStyle(document.documentElement);

  const theme = (t) => ({
    ...t,
    colors: {
      ...t.colors,
      primary: rootStyle.getPropertyValue("--ct-primary"),
      primary25: rootStyle.getPropertyValue("--select-option-hover"),
      danger: rootStyle.getPropertyValue("--ct-danger"),
      dangerLight: "Tomato",
      neutral5: rootStyle.getPropertyValue("--ct-input-disabled-bg"),
      neutral40: rootStyle.getPropertyValue("--ct-input-color"),
    },
  });
  const customStyles = {
    control: (styles, { hasValue }) => ({
      ...styles,
      backgroundColor: isDarkMode ? "#404954" : "#fff",
      color: hasValue ? "#fff" : isDarkMode ? "#fff" : "#333",
      border: isDarkMode ? "1px solid #4a525d" : "1px solid #ccc",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: isDarkMode ? "#404954" : "#fff",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isSelected
        ? "#fd5630" 
        : isFocused
        ? "#fd5630" 
        : isDarkMode
        ? "#404954"
        : "#fff",
      color: isSelected || isFocused ? "#fff" : isDarkMode ? "#fff" : "#404954",
    }),
    singleValue: (styles, { hasValue }) => ({
      ...styles,
      color: hasValue ? (isDarkMode ? "#fff" : "#333") : "#333",
    }),
  };
  return <Select ref={ref} styles={customStyles} theme={theme} {...props} />;
});
