export const ToastType = Object.freeze({
  UPDATE_PASSWORD: {
    ACTION_NAME: "updatePassword",
    MESSAGES: {
      PASSWORD_UPDATED_SUCCESSFULLY: "passwordUpdatedSuccessfully",
      PASSWORD_TOO_SMALL: "passwordTooSmall",
      FAILED_TO_UPDATE_PASSWORD: "failedToUpdatePassword"
    } 
  },
  LOGIN: {
    ACTION_NAME: "login",
    MESSAGES: {
      SUCCESSFULLY_LOGGED_IN: "successfullyLoggedIn",
      WRONG_CREDENTIALS: "wrongCredentials"
    } 
  },
  INTERNAL_ERRORS: {
    ACTION_NAME: "internalErrors",
    MESSAGES: {
      CONNECTION_ERROR: "connectionError",
      FETCHING_ERROR: "fetchingError",
      UPLOAD_ERROR: "uploadError",
      VALIDATION_ERROR: "validationError"
    }
  },
  REGISTER: {
    ACTION_NAME: "register",
    MESSAGES: {
      ACCOUNT_CREATED_SUCCESSFULLY: "accountCreatedSuccessfully",
      ERROR_CREATING_ACCOUNT: "errorCreatingAccount"
    }
  },
  DELETE_ACCOUNT: {
    ACTION_NAME: "deleteAccount",
    MESSAGES: {
      DELETED_ACCOUNT_SUCCESSFULLY: "deletedAccountSuccessfully",
      ERROR_DELETING_ACCOUNT: "errorDeletingAccount",
      DELETING_ACCOUNT: "deletingAccount"
    }
  },
  PAGE_DATA: {
    ACTION_NAME: "pageData",
    MESSAGES: {
      ERROR_PAGE_INFORMATION: "errorPageInformation"
    }
  },
  USER_UPDATE: {
    ACTION_NAME: "userUpdate",
    MESSAGES: {
      YOU_HAVE_ALREADY_ADDED_THAT_USER: "youHaveAlreadyAddedThatUser",
      USER_ADDED_SUCCESSFULLY: "userAddedSuccessfully",
      ERROR_UPDATE: "errorUpdate",
      YOU_CANT_ADD_YOURSELF: "youCantAddYourself"
    }
  },
  SWITCH_SHARING_STATE: {
    ACTION_NAME: "switchSharingState",
    MESSAGES: {
      UPDATE_STATE_SUCCESS: "updateStateSuccess",
      ERROR_UPDATE_STATE: "errorUpdateState"
    }
  },
  UPDATE_SHARING: {
    ACTION_NAME: "updateSharing",
    MESSAGES: {
      UPDATE_PROFILE_SUCCESS: "updateProfileSuccess",
      ERROR_UPDATE_PROFILE: "errorUpdateProfile"
    }
  },
  REMOVE_FROM_SHARING: {
    ACTION_NAME: "removeFromSharing",
    MESSAGES: {
      USER_REMOVED_SUCCESSFULLY: "userRemovedSuccessfully",
      ERROR_DELETE_USER: "errorDeleteUser"
    }
  },
  ADD_QR_CODE: {
    ACTION_NAME: "addQRCode",
    MESSAGES: {
      CREATE_QR_MESSAGE: "createdQRMessage",
      NO_ACTIVE_SUBSCRIPTION: "noActiveSubscription",
      SUBSCRIPTION_EXPIRED: "subscriptionExpired",
      DYNAMIC_QR_CODES_EXCEEDED: "dynamicQRCodesExceeded",
      STATIC_QR_CODES_LIMIT_EXCEEDED: "staticQRCodesLimitExceeded",
      URL_CONFIGURATION_ERROR: "URLConfigurationError",
      CUSTOM_QRL_TAKEN: "customURLTaken",
      UNSAVED_CHANGES_ERROR: "unsavedChangesError",
      ERROR_OCURRED: "errorOccured"
    }
  },
  RECOVERY_PASSWORD: {
    ACTION_NAME: "recoveryPassword",
    MESSAGES: {
      PASSWORD_RECOVERY_SENT: "passwordRecoverySent",
      ERROR_TRY_AGAIN_LATER: "errorTryAgainLater"
    }
  },
  FORGOT_PASSWORD: {
    ACTION_NAME: "forgotPassword",
    MESSAGES: {
      ERROR_OCURRED: "errorOcurred",
    }
  },
  GET_ALL_QR_CODES_FROM_USER: {
    ACTION_NAME: "getAllQRCodesFromUser",
    MESSAGES: {
      COULD_NOT_GET_QR_CODES: "couldNotGetQRCodes",
    }
  },
  GET_INVOICE: {
    ACTION_NAME: "getInvoice",
    MESSAGES: {
      ERROR_GETTING_INVOICES: "errorGettingInvoice",
    }
  },
  GET_CURRENT_PLAN: {
    ACTION_NAME: "getCurrentPlan",
    MESSAGES: {
      ERROR_GETTING_CURRENT_PLAN: "errorGettingCurrentPlan",
    }
  },
  CANCEL_SUBSCRIPTION: {
    ACTION_NAME: "cancelSubscription",
    MESSAGES: {
      SUBSCRIPTION_CANCELED: "subscriptionCanceled",
      SUBSCRIPTION_CANCELATION_SCHEDULED: "subscriptionCancelationScheduled",
      NO_ACTIVE_SUBSCRIPTION: "noActiveSubscription",
      SUBSCRIPTION_ALREADY_CANCELED: "subscriptionAlreadyCanceled",
      SUBSCRIPTION_ALREADY_SCHEDULE_FOR_CANCELATION: "subscriptionAlreadyScheduledForCancelation",
      UNEXPECTED_ERROR: "unexpectedError",
    }
  },
  CREATE_PAYMENT_INTENT: {
    ACTION_NAME: "createPaymentIntent",
    MESSAGES: {
      CANT_DOWNGRADE_PLAN: "cantDowngradePlan",
      SOMETHING_WENT_WRONG: "somethingWentWrong",
    }
  },
  GET_CURRENT_USER: {
    ACTION_NAME: "getCurrentUser",
    MESSAGES: {
      COULD_NOT_GET_USER: "couldNotGetUser"
    }
  },
  GET_QR_CODE_IDENTIFIER: {
    ACTION_NAME: "getQrCodeIdentifier",
    MESSAGES: {
      SOMETHING_WENT_WRONG: "somethingWentWrong"
    }
  },
  UPDATE_QR_CODE: {
    ACTION_NAME: "updateQrCode",
    MESSAGES: {
      QR_CODE_UPDATED_SUCCESSFULLY: "QRCodeUpdatedSuccessfully",
      NOT_ALLOWED_TO_EDIT_QR_CODE: "notAllowedToEditQRCode",
      ERROR_UPDATING_QR_CODE: "errorUpdatingQRCode"
    }
  },
  DELETE_QR_CODE: {
    ACTION_NAME: "deleteQrCode",
    MESSAGES: {
      DELETE_QR_CODES_SUCCESSFULLY: "deleteQRCodeSuccessfully",
      NOT_ALLOWED_TO_DELETE_QR_CODE: "notAllowedToDeleteQRCode",
      ERROR_DELETING_QR_CODE: "errorDeletingQRCode"
    }
  },
  FIND_QR_CODES_SCANS: {
    ACTION_NAME: "findQrCodesScans",
    MESSAGES: {
      COULD_NOT_GET_QR_CODES: "couldNotGetQRCodes"
    }
  }
});