export const API = "https://strapi.yrqr.pt/api";
//export const API = "http://localhost:1337/api"

export const LOCAL = "https://strapi.yrqr.pt";
//export const LOCAL = "http://localhost:1337"

export const APP = "https://backoffice.yrqr.pt";
// export const APP = "http://localhost:3000"

export const AUTH_TOKEN = "authToken";
export const BEARER = "Bearer";

export const STRIPE_PUBLIC_KEY =
  "pk_test_51PBIbxECkMhYUDC7xbPuuUXCsdIpGx9AHVwArKFTfORuJpNLx0NRClhnGiGOmdYrXd1wNiFxWYjcIndb0sBOCJXw00MQrLN5yi";
