export const scanFilters = {
  AUTOMATIC: 'AUTOMATIC',
  YESTERDAY: 'YESTERDAY',
  TODAY: 'TODAY',
  LAST48H: 'LAST48H',
  LAST7D: 'LAST7D',
  LAST30D: 'LAST30D',
  LAST60D: 'LAST60D',
  LAST90D: 'LAST90D',
  LAST12M: 'LAST12M',
  CUSTOMIZE: 'CUSTOMIZE',
}

export const timeReferences = {
  REFERENCE: 'REFERENCE',
  HOUR: 'HOUR',
  MONTH: 'MONTH',
  DAY: 'DAY',
}