import React from "react";
import { Link } from "react-router-dom";

// Components Imports
import { Group, Input, Label } from "../../components/Form";
import { Brand } from "../../components/Authentication";
import Loading from "../../components/Loading";

// Hooks Imports
import useAuth from "../../hooks/auth/useAuth";
import usePagesData from "../../hooks/pageData/usePagesData";

// Utils Imports
import { LOCAL } from "../../constant";
import { PageTypes } from "../../hooks/pageData/PageTypes";

const SignIn = () => {
  const { pageData, isPending: pageIsLoading, isError } = usePagesData({ pageType: PageTypes.SIGN_IN });
  
  const { doLogin } = useAuth();

  if (pageIsLoading || isError) return <Loading />;

  const logo = LOCAL + pageData.Logo.data.attributes.url;

  const backgroundStyle = {
    background: `url(${LOCAL + pageData.Image.data?.attributes.url})`,
    backgroundSize: "cover",
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  
    const payload = Object.fromEntries(new FormData(event.target).entries());

    const { email, password } = payload;

    doLogin({ identifier: email, password })
  };

  return (
    <>
      <div className="auth-fluid" style={backgroundStyle}>
        <div className="auth-fluid-form-box">
          <div className="align-items-center d-flex h-100">
            <div className="card-body">
              <Brand logo={logo} />

              <h4 className="mt-0">{pageData.Title}</h4>
              <p className="text-muted mb-4">{pageData.Description}</p>

              <form onSubmit={handleSubmit}>
                <Group>
                  <Label htmlFor="email">{pageData.Email.Label}</Label>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    placeholder={pageData.Email.Placeholder}
                  />
                </Group>
                <Group>
                  <Link to="/recovery" className="text-muted float-end">
                    <small>{pageData.RecoverPassword}</small>
                  </Link>
                  <Label htmlFor="password">{pageData.Password.Label}</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder={pageData.Password.Placeholder}
                  />
                </Group>
                <button type="submit" className="btn btn-md btn-primary d-none d-lg-inline-flex">{pageData.signInTitle}</button>
              </form>

              <footer className="footer footer-alt">
                <p className="text-muted">
                  {pageData.CreateAccountQuestion}
                  <Link to="/signUp" className="text-muted ms-1">
                    <b>{pageData.Signup}</b>
                  </Link>
                </p>
              </footer>
            </div>
          </div>
        </div>
        <div className="auth-fluid-right text-center">
          <div className="auth-user-testimonial">
            <h2 className="mb-3">{pageData.TitleImage}</h2>
            <p>{pageData.TextImage}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
