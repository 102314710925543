import { Link } from "react-router-dom";

// Components Imports
import ExternalLottie from "../../components/ExternalLottie";
import Loading from "../../components/Loading";

// Hooks Imports
import usePagesData from "../../hooks/pageData/usePagesData";

// Services
import { LottieTypes } from "../../services/apiCalls/lottie/LottieTypes";
import { PageTypes } from "../../hooks/pageData/PageTypes";

const Page404 = () => {
  const { pageData, isPending, isError } = usePagesData({ pageType: PageTypes.NOT_FOUND });
  
  if (isPending || isError) return <Loading />;

  return (
    <>
      <div className="d-none d-md-block">
        <div className="row">
          <div className="col-md-8 col-lg-6">
            <ExternalLottie lottieType={LottieTypes.NOT_FOUND} />
          </div>
          <div className="col-md-4 col-lg-6 container-fluid d-flex flex-column justify-content-center align-items-start" style={{ height: "100vh" }}>
            <h1 className="text-uppercase text-primary mt-3">{pageData.Title}</h1>
            <p className="text-muted mt-1">{pageData.Description}</p>

            <Link to='/' className="btn btn-secondary mt-3">
              <i className="mdi mdi-reply"></i> {pageData.HomeButton}
            </Link>
          </div>
        </div>
      </div>
      <div className="d-md-none d-flex align-items-center min-h-100vh pt-2 pt-sm-5 pb-4 pb-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-4 col-lg-5">
              <div className="card">
                <div className="card-body p-4">
                  <div className="text-center">
                    <h1 className="text-error text-primary mt-4">4<i className="mdi mdi-emoticon-sad"></i>4</h1>
                    <h4 className="text-uppercase text-primary mt-3">{pageData.Title}</h4>
                    <p className="text-muted mt-3">{pageData.Description}</p>

                    <Link to='/' className="btn btn-secondary mt-3">
                      <i className="mdi mdi-reply"></i> {pageData.HomeButton}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Page404;