import React, { forwardRef, useEffect, useId, useState } from "react";
import { AddressElement, useElements } from "@stripe/react-stripe-js";

import { Group, Input, Label, Switch, SelectComponent } from "../Form";

const InvoiceForm = forwardRef(
  (
    {
      pageData,
      invoiceDetails,
      setInvoiceDetails,
      onSubmit,
      disabled,
      hideSubmitButton = false,
    },
    ref
  ) => {
    const [shouldRender, setShouldRender] = useState(true);
    const [addressElementReady, setAddressElementReady] = useState(false);

    const particularId = useId();

    const elements = useElements();

    const isParticular = invoiceDetails.isParticular;

    useEffect(() => {
      if (!addressElementReady) return;

      setShouldRender(false);
      setTimeout(() => {
        setShouldRender(true);
      }, 0);
    }, [isParticular, addressElementReady]);

    const addressName = !isParticular
      ? {
          name: invoiceDetails.companyName,
        }
      : {
          firstName: invoiceDetails.firstName,
          lastName: invoiceDetails.lastName,
        };

    const addressElementOptions = {
      mode: "billing",
      autocomplete: {
        mode: "automatic",
      },
      fields: {
        phone: "always",
      },
      validation: {
        phone: {
          required: "always",
        },
      },
      display: {
        name: isParticular ? "split" : "organization",
      },
      defaultValues: {
        ...addressName,
        phone: invoiceDetails.contact,
        address: {
          line1: invoiceDetails.address,
          line2: invoiceDetails.address2,
          city: invoiceDetails.city,
          postal_code: invoiceDetails.postalCode,
          country: invoiceDetails.country,
        },
      },
    };

    const handleInvoiceDetailsChange = (event) => {
      let { name, value, checked } = event.target;

      if (name === "isParticular") {
        value = checked;
      }
      setInvoiceDetails((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    };

    const handleAddressChange = (event) => {
      const value = event.value;

      setInvoiceDetails((prev) => {
        return {
          ...prev,
          companyName: value.name,
          firstName: value.firstName,
          lastName: value.lastName,
          contact: value.phone,
          postalCode: value.address.postal_code,
          country: value.address.country,
          city: value.address.city,
          address: value.address.line1,
          address2: value.address.line2,
        };
      });
    };

    const handleDisabledInputFocus = () => {
      const addressElement = elements.getElement("address");

      if (disabled) {
        addressElement.blur();
      }
    };

    const [errors, setErrors] = useState({});

    const validateEmail = (email) => {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    };

    function validateNIFPortuguese(nif) {
      const re = /^[1235689][0-9]{8}$/;
      if (!re.test(nif)) {
        return false;
      }

      const nifArray = nif.split("").map(Number);
      let sum = 0;
      for (let i = 0; i < 8; i++) {
        sum += nifArray[i] * (9 - i);
      }
      const controlDigit = 11 - (sum % 11);
      const validControlDigit = controlDigit >= 10 ? 0 : controlDigit;

      return nifArray[8] === validControlDigit;
    }

    function validateNIFPortugueseBusiness(nif) {
      const re = /^[5689][0-9]{8}$/;
      if (!re.test(nif)) {
        return false;
      }

      const nifArray = nif.split("").map(Number);
      let sum = 0;
      for (let i = 0; i < 8; i++) {
        sum += nifArray[i] * (9 - i);
      }
      const controlDigit = 11 - (sum % 11);
      const validControlDigit = controlDigit >= 10 ? 0 : controlDigit;

      return nifArray[8] === validControlDigit;
    }

    const handleSubmit = (event) => {
      event.preventDefault();
      let newErrors = {};
      let isValid = true;

      if (!validateEmail(invoiceDetails.email)) {
        newErrors.email = pageData.errorEmail;
        isValid = false;
      }
      if (invoiceDetails.isParticular) {
        if (!validateNIFPortuguese(invoiceDetails.nif)) {
          newErrors.nif = pageData.nifError;
          isValid = false;
        }
      } else {
        if (!validateNIFPortugueseBusiness(invoiceDetails.nif)) {
          newErrors.nif = pageData.nifError;
          isValid = false;
        }
      }

      setErrors(newErrors);

      if (isValid) {
        onSubmit(event);
      }
    };

    const submitVisibility = hideSubmitButton ? { display: "none" } : {};

    return (
      <article>
        <section>
          <form onSubmit={handleSubmit} ref={ref} id="invoice-form">
            <div className="row">
              <Group columns={2}>
                <div className="row">
                  <Label>{pageData.invoiceFormTitle}</Label>
                </div>
                <Switch
                  id={particularId}
                  name="isParticular"
                  onLabel={`${pageData.switchLabelOnTitle}`}
                  offLabel={`${pageData.switchLabelOffTitle}`}
                  disabled={disabled}
                  checked={invoiceDetails.isParticular}
                  onChange={handleInvoiceDetailsChange}
                />
              </Group>
            </div>
            <div>
              <InvoiceInputs
                pageData={pageData}
                invoiceDetails={invoiceDetails}
                handleInvoiceDetailsChange={handleInvoiceDetailsChange}
                disabled={disabled}
                errors={errors}
              />
              {disabled && (
                <DisabledInvoiceInputs
                  pageData={pageData}
                  invoiceDetails={invoiceDetails}
                  disabled={disabled}
                />
              )}
              {shouldRender && !disabled && (
                <AddressElement
                  onChange={handleAddressChange}
                  onReady={() => setAddressElementReady(true)}
                  options={addressElementOptions}
                />
              )}
            </div>
            <div className="mt-3 mb-2 d-flex">
              <button
                style={submitVisibility}
                className="btn btn-primary"
                type="submit"
                name="submit"
              >
                {pageData.Next}
              </button>
            </div>
          </form>
        </section>
      </article>
    );
  }
);

function InvoiceInputs({
  pageData,
  invoiceDetails,
  handleInvoiceDetailsChange,
  disabled,
  errors,
}) {
  const emailId = useId();
  const nifId = useId();

  const nifObject = invoiceDetails.isParticular ? pageData.NIF : pageData.NIPC;

  return (
    <div className="row">
      <Group columns={2}>
        <Label htmlFor={emailId}>{pageData.Email.Label}</Label>
        <Input
          required
          type="text"
          name="email"
          id={emailId}
          placeholder={pageData.Email.Placeholder}
          value={invoiceDetails.email}
          onChange={handleInvoiceDetailsChange}
          disabled={disabled}
          error={errors.email}
        />
      </Group>
      <Group columns={2}>
        <Label htmlFor={nifId}>{nifObject.Label}</Label>
        <Input
          type="number"
          name="nif"
          id={nifId}
          placeholder={nifObject.Placeholder}
          value={invoiceDetails.nif}
          onChange={handleInvoiceDetailsChange}
          disabled={disabled}
          error={errors.nif}
        />
      </Group>
    </div>
  );
}

function DisabledInvoiceInputs({ pageData, invoiceDetails, disabled }) {
  const firstNameId = useId();
  const lastNameId = useId();
  const companyNameId = useId();
  const countryId = useId();
  const cityId = useId();
  const addressId = useId();
  const address2Id = useId();
  const postalCodeId = useId();
  const contactId = useId();

  const { country } = invoiceDetails;
  const countryOption = {
    value: country,
    label: country,
  };

  return (
    <>
      {invoiceDetails.isParticular ? (
        <div className="row">
          <Group columns={2}>
            <Label htmlFor={firstNameId}>{pageData.firstName.Label}</Label>
            <Input
              type="text"
              id={firstNameId}
              placeholder={pageData.firstName.Placeholder}
              defaultValue={invoiceDetails.firstName}
              disabled={disabled}
            />
          </Group>
          <Group columns={2}>
            <Label htmlFor={lastNameId}>{pageData.lastName.Label}</Label>
            <Input
              type="text"
              id={lastNameId}
              placeholder={pageData.lastName.Placeholder}
              defaultValue={invoiceDetails.lastName}
              disabled={disabled}
            />
          </Group>
        </div>
      ) : (
        <Group>
          <Label htmlFor={companyNameId}>{pageData.companyName.Label}</Label>
          <Input
            type="text"
            id={companyNameId}
            placeholder={pageData.companyName.Placeholder}
            defaultValue={invoiceDetails.companyName}
            disabled={disabled}
          />
        </Group>
      )}
      <div className="row">
        <Group columns={2}>
          <Label id={countryId}>{pageData.country.Label}</Label>
          <SelectComponent
            id={countryId}
            isDisabled={disabled}
            placeholder={pageData.country.Placeholder}
            options={[countryOption]}
            defaultValue={country && countryOption}
          />
        </Group>
        <Group columns={2}>
          <Label htmlFor={cityId}>{pageData.city.Label}</Label>
          <Input
            type="text"
            id={cityId}
            placeholder={pageData.city.Placeholder}
            defaultValue={invoiceDetails.city}
            disabled={disabled}
          />
        </Group>
      </div>
      <div className="row">
        <Group columns={2}>
          <Label htmlFor={addressId}>{pageData.address.Label}</Label>
          <Input
            type="text"
            id={addressId}
            placeholder={pageData.address.Placeholder}
            defaultValue={invoiceDetails.address}
            disabled={disabled}
          />
        </Group>
        <Group columns={2}>
          <Label htmlFor={address2Id}>{pageData.address2.Label}</Label>
          <Input
            type="text"
            id={address2Id}
            placeholder={pageData.address2.Placeholder}
            defaultValue={invoiceDetails.address2}
            disabled={disabled}
          />
        </Group>
      </div>
      <div className="row">
        <Group columns={2}>
          <Label htmlFor={postalCodeId}>{pageData.postalCode.Label}</Label>
          <Input
            type="text"
            id={postalCodeId}
            placeholder={pageData.postalCode.Placeholder}
            defaultValue={invoiceDetails.postalCode}
            disabled={disabled}
          />
        </Group>
        <Group columns={2}>
          <Label htmlFor={contactId}>{pageData.contact.Label}</Label>
          <Input
            type="text"
            id={contactId}
            placeholder={pageData.contact.Placeholder}
            defaultValue={invoiceDetails.contact}
            disabled={disabled}
          />
        </Group>
      </div>
    </>
  );
}

export default InvoiceForm;
