import makeRequest from "../../dataFetching"
import { API_QR_LINK_TREE_REDIRECT_PAGE } from "../../../utils/constants";
import { findQrLinkTreeRedirectMapping } from "../../dataMapping/qrLinkTreeRedirect";
/**
 * FIND
 * Fetches all QrLinkTreeRedirect entries.
 * @returns {Promise<Object>} A promise that resolves to the response data from the GET request.
 */
export function findQrLinkTreeRedirect () {
  return makeRequest(API_QR_LINK_TREE_REDIRECT_PAGE)
  .then(findQrLinkTreeRedirectMapping);
}

/**
 * CREATE LOCALIZATION
 * Creates a new QrLinkTreeRedirect localization entry.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function createLocalizationQrLinkTreeRedirect ({}) {}


/**
 * PUT
 * Updates an existing QrLinkTreeRedirect entry.
 * @param {integer} id - The ID of the QrLinkTreeRedirect entry to update.
 * @returns {Promise<Object>} A promise that resolves to the response data from the PUT request.
 */
export function updateQrLinkTreeRedirect (id, {}) {}


/**
 * DELETE
 * Deletes a specific QrLinkTreeRedirect entry by ID.
 * @param {integer} id - The ID of the QrLinkTreeRedirect entry to delete.
 * @returns {Promise<Object>} A promise that resolves to the response data from the DELETE request.
 */
export function deleteQrLinkTreeRedirect ({ id }) {}
