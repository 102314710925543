import makeRequest from "../../dataFetching"
import { API_FAQ, INFO_MENU } from "../../../utils/constants";
import { findFAQPageMapping } from "../../dataMapping/FAQPage";
import { infoMenuMapping } from "../../dataMapping/infoMenu";
/**
 * FIND
 * Fetches all FAQPage entries.
 * @returns {Promise<Object>} A promise that resolves to the response data from the GET request.
 */
export async function findFAQPage () {
  const responseFAQ = await makeRequest(API_FAQ);
  const responseInfoMenu = await makeRequest(INFO_MENU);
  
  const mappedFAQ = findFAQPageMapping(responseFAQ);
  const mappedInfoMenu = infoMenuMapping(responseInfoMenu);

  return { 
    pageData: mappedFAQ,
    infoCardData: mappedInfoMenu
  }
}

/**
 * CREATE LOCALIZATION
 * Creates a new FAQPage localization entry.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function createLocalizationFAQPage ({}) {}


/**
 * PUT
 * Updates an existing FAQPage entry.
 * @param {integer} id - The ID of the FAQPage entry to update.
 * @returns {Promise<Object>} A promise that resolves to the response data from the PUT request.
 */
export function updateFAQPage (id, {}) {}


/**
 * DELETE
 * Deletes a specific FAQPage entry by ID.
 * @param {integer} id - The ID of the FAQPage entry to delete.
 * @returns {Promise<Object>} A promise that resolves to the response data from the DELETE request.
 */
export function deleteFAQPage ({ id }) {}
