import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import "./../../styles/components/linkTreeForm.css";

import { Group, Input, Label } from "../Form";
import { formatImage } from "../../utils";
import LinkCard from "../LinkCard";

export default function LinkTreeForm({ qrCodeId, linkList, setLinkList, startingLinks, pageData, error}) {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [imagePreviews, setImagePreviews] = useState({});
  const [link, setLink] = useState({
    name: "",
    link: "",
    image: "",
    qr_code: qrCodeId,
    imagePreview: "",
  });
  
  const LinkImageInputRef = useRef(null);

  const handleLinkImageUploadClick = () => {
    LinkImageInputRef.current.click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLink({ ...link, image: file });
        setImagePreviews((prevImagePreviews) => {
          return {
            ...prevImagePreviews,
            [file.name]: reader.result,
          };
        });
        if (errors.image) {
          setErrors({ ...errors, image: undefined });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLinkChange = (e) => {
    const { name, value } = e.target;
    setLink({ ...link, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: undefined });
    }
  };
  function isValidUrl(string) {
    var regex =
      /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
    return regex.test(string);
  }

  const addItemToLinkTree = () => {
    let newErrors = {};

    const newLink = {
      Name: link.name,
      Link: link.link,
      image: link.image,
      qr_code: "",
    };
    

    if (!newLink.Name) {
      newErrors.name = pageData.linkNameMissing.Message;
    }
    if (!newLink.Link) {
      newErrors.link = pageData.urlLinkMissing.Message;
    }
    if (newLink.Link && !isValidUrl(newLink.Link)) {
      newErrors.link = pageData.invalidUrl.Message;
    }
    if (!newLink.image) {
      newErrors.image = pageData.linkImageMissing.Message;
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setLinkList((prevListaDeLinks) => {
      return [...prevListaDeLinks, newLink];
    });

    setLink({ name: "", link: "", image: "" });
    setErrors({});
  };

  useEffect(() => {
    setLinkList(startingLinks || []);

    const imagePreviewsMap = {};
    startingLinks?.forEach((link) => {
      imagePreviewsMap[link.image.name] = formatImage(link.image);
    });

    setImagePreviews(imagePreviewsMap);
  }, []);
  
  return (
    <div className="createQR--form">
      <div className="row border border-1 p-2 pt-3 align-items-center rounded">
        <div className="col-lg-2">
          <Group>
            <Label>{pageData.LinkImageLabel}</Label>
            {link.image ? (
              <div
                className="avatar-xl mb-2 m-auto"
                onClick={handleLinkImageUploadClick}
              >
                <div className="image-edit-container avatar-title bg-light rounded">
                  <img
                    src={URL.createObjectURL(link.image)}
                    alt="Preview"
                    className="image-preview"
                  />
                  <div className="edit-icon-overlay rounded">
                    <i className="mdi mdi-image-edit-outline h1 text-primary"></i>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="avatar-xl mb-2 m-auto preview-empty"
                onClick={handleLinkImageUploadClick}
              >
                <span className="avatar-title bg-light rounded">
                  <div className="text-center">
                    <i className="mdi mdi-image text-primary h1"></i>
                    <p className="mb-0">
                      <small className="mb-0 text-primary p-1">
                        {pageData.btnAddNewImageTitle}
                      </small>
                    </p>
                  </div>
                </span>
              </div>
            )}
            <input
              type="file"
              id="fileInput"
              ref={LinkImageInputRef}
              style={{ display: "none" }}
              accept={[".png", ".jpg"]}
              onChange={handleImageChange}
            />
            {errors?.image && (
              <div className="invalid-feedback">{errors.image}</div>
            )}
          </Group>
        </div>
        <div className="col-lg-10">
          <Group>
            <Label>{pageData.LinkName.Label}</Label>
            <Input
              onChange={handleLinkChange}
              value={link.name}
              name="name"
              width={"100%"}
              placeholder={pageData.LinkName.Placeholder}
              error={errors?.name}
            />
          </Group>
          <Group>
            <Label>{pageData.LinkURL.Label}</Label>
            <Input
              onChange={handleLinkChange}
              value={link.link}
              name="link"
              width={"100%"}
              placeholder={pageData.LinkURL.Placeholder}
              error={errors?.link}
            />
          </Group>
        </div>
        <div className="col-lg-12" style={{ textAlign: "right" }}>
          <button
            className="btn btn-primary addLink float-right"
            onClick={addItemToLinkTree}
          >
            {pageData.AddLink}
          </button>
        </div>
      </div>
      {error && <div className="invalid-feedback">{error}</div>}
      <ul>
        {linkList.map((item, index) => (
          <LinkCard
            key={"link-" + index}
            item={item}
            imagePreviews={imagePreviews}
            itemId={index}
            allItems={linkList}
            setAllItems={setLinkList}
            setImagePreviews={setImagePreviews}
            pageData={pageData}
          />
        ))}
      </ul>
    </div>
  );
}
