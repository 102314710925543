import { useRef, useState } from "react";

import { CSVLink } from "react-csv";
import {
  CityBarChart,
  CountryBarChart,
  OsPieChart,
  ScanLineChart,
  scanFilters,
} from "../../../components/Charts";
import { Group, RangeDate, SelectComponent } from "../../../components/Form";
import {
  formatDateForInput,
  generateChartDataFromProperties,
} from "../../../utils";

export function Statistics({ pageData, qrCodeDataUpdated }) {
  const chartFilterRef = useRef(null);
  const timeReferenceRef = useRef(null);
  const csvData = useRef([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [chartFilter, setChartFilter] = useState("");

  const handleFilterChange = (filter) => {
    setChartFilter(filter);
  };

  const handleFilterStartDateChange = (e) => {
    setStartDate(new Date(e.target.value));
  };
  const handleFilterEndDateChange = (e) => {
    setEndDate(new Date(e.target.value));
  };

  const filterIsApplied = () => {
    const selects = [chartFilterRef, timeReferenceRef];

    return selects.reduce((acc, cur) => {
      const select = cur.current;
      if (!select) return false;

      return acc || select.state.focusedOption !== undefined;
    }, false);
  };

  const handleFilterClear = () => {
    const selects = [chartFilterRef, timeReferenceRef];

    selects.forEach((select) => {
      if (!select.current) return;

      select.current.clearValue();
    });
  };

  const filterOptions = pageData.FilterScans.Options.map((o) => {
    return { label: o.Text, value: o.Value };
  });

  const referenceOptions = pageData.TimeReference.Options.map((o) => {
    return { label: o.Text, value: o.Value };
  });

  const graphScans = qrCodeDataUpdated?.scans?.data || [];

  const [operatingSystem, country, city] = generateChartDataFromProperties(
    graphScans,
    ["operatingSystem", "country", "city"]
  );

  const currentFilterIcon = filterIsApplied()
    ? "mdi-filter-off-outline"
    : "mdi-filter-outline";

  const noInformationAvailable = (
    <div className="d-flex justify-content-center align-items-center min-graph-height">
      <h5 className="text-center">{pageData.NoInformationAvailable}</h5>
    </div>
  );

  return (
    <>
      <div className="row">
        <div className="col-xl-3 col-lg-4">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between mt-2 mb-3">
                <h4>{"Filtros"}</h4>
                <button
                  className="btn btn-outline-primary"
                  onClick={handleFilterClear}
                >
                  <i className={`mdi ${currentFilterIcon} float-end`}></i>
                </button>
              </div>
              <Group>
                <SelectComponent
                  ref={chartFilterRef}
                  id="filter-scans"
                  name="filter-scans"
                  isClearable={true}
                  placeholder={pageData.FilterScans.Title}
                  onChange={(s) => handleFilterChange(s?.value)}
                  options={filterOptions}
                />
              </Group>
              {chartFilter === scanFilters.CUSTOMIZE && (
                <Group>
                  <RangeDate
                    isDisabled={false}
                    removeRow={true}
                    columns={1}
                    startName="startDate"
                    endName="endDate"
                    startDate={formatDateForInput(startDate)}
                    endDate={formatDateForInput(endDate)}
                    handleStartDateChange={handleFilterStartDateChange}
                    handleEndDateChange={handleFilterEndDateChange}
                    startDateLabel={pageData.Start}
                    endDateLabel={pageData.End}
                    pageData={pageData}
                  />
                </Group>
              )}
              <Group>
                <SelectComponent
                  ref={timeReferenceRef}
                  id="time-reference-selector"
                  name="time-reference-selector"
                  isClearable={true}
                  placeholder={pageData.TimeReference.Title}
                  onChange={(s) => handleFilterChange(s?.value)}
                  options={referenceOptions}
                />
              </Group>
            </div>
          </div>
        </div>

        <div className="col-xl-9 col-lg-8">
          <div className="card card-h-100">
            <div className="card-body">
              {graphScans.length > 0 && (
                <CSVLink
                  className="p-0 float-end"
                  data={csvData.current}
                  filename={pageData.ExportFileName}
                >
                  {pageData.ExportButton}
                  <i className="mdi mdi-download ms-1"></i>
                </CSVLink>
              )}
              <h4 className="mt-2 mb-3">{pageData.ScanActivity}</h4>
              {graphScans.length > 0 ? (
                <ScanLineChart
                  scans={graphScans}
                  filterType={chartFilter}
                  startDate={startDate}
                  endDate={endDate}
                  csvData={csvData}
                  pageData={pageData}
                />
              ) : (
                noInformationAvailable
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-4 col-lg-5">
          <div className="card min-graph-heigth">
            <div className="card-body min-graph-height">
              <h4 className="mt-2 mb-3">{pageData.ScansByOS}</h4>
              {operatingSystem.length === 0 ? (
                noInformationAvailable
              ) : (
                <OsPieChart data={operatingSystem} />
              )}
            </div>
          </div>
        </div>

        <div className="col-xl-8 col-lg-7">
          <div className="card min-graph-heigth">
            <div className="card-body min-graph-height">
              <h4 className="mt-2 mb-3">{pageData.ScansByCountry}</h4>
              {country.length === 0 ? (
                noInformationAvailable
              ) : (
                <CountryBarChart data={country} />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="card min-graph-heigth">
          <div className="card-body min-graph-height">
            <h4 className="mt-2 mb-3">{pageData.ScansByCity}</h4>
            {city.length === 0 ? (
              noInformationAvailable
            ) : (
              <CityBarChart data={city} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
