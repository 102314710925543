export function Switch({
  id,
  checked,
  type = "primary",
  onLabel = "",
  offLabel = "",
  ...props
}) {
  if (!id) throw new Error("Id required");
  return (
    <>
      <input type="checkbox" id={id} data-switch={type} checked={checked} {...props} />
      <label
        htmlFor={id}
        data-on-label={onLabel}
        data-off-label={offLabel}
      ></label>
    </>
  );
}
