import React from 'react';

import useCreateChartData from '../../hooks/useCreateChartData';

import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { validateDateInterval } from '../../utils';
import { scanFilters, timeReferences } from './constants';

/**
 * 
 * @param {Object[]} data array of objects with the interval and the number of scans
 * @param {String[]} header array of strings that will be the header of the CSV
 * @param {String} total word that symbolizes the total, specially important for translation purposes
 * @returns an array of arrays with the data formatted to be downloaded as a CSV
 */
function makeCsvData(data, header, total) {
  const csvData = [header];

  const formattedMonths = data.map(entry => [entry.month, entry.scans.toString()]);
  csvData.push(...formattedMonths);

  const scanTotal = data.reduce((acc, entry) => acc + entry.scans, 0);
  csvData.push([total, scanTotal.toString()]);

  return csvData;
}

const ScanLineChart = ({ scans, filterType, osFilter, cityFilter, countryFilter, startDate, endDate, csvData, pageData }) => {
  let filteredScans = scans;

  if (osFilter) {
    filteredScans = filteredScans.filter((scan) => scan.attributes.operatingSystem === osFilter);
  }

  if (cityFilter) {
    filteredScans = filteredScans.filter((scan) => scan.attributes.city === cityFilter);
  }

  if (countryFilter) {
    filteredScans = filteredScans.filter((scan) => scan.attributes.country === countryFilter);
  }

  const data = useCreateChartData(filteredScans, filterType, startDate, endDate, pageData);

  // csvData might not be passed as prop, therefore, not necessary
  if (csvData) {
    csvData.current = makeCsvData(data, [pageData.CSVInterval, pageData.CSVScanNumber], pageData.CSVTotal)
  }

  let xAxisDataKey;

  switch (filterType) {
    case timeReferences.HOUR:
      xAxisDataKey = 'hour';
      break;
    case timeReferences.DAY:
      xAxisDataKey = 'day';
      break;
    case scanFilters.LAST30D:
    case timeReferences.MONTH:
    case scanFilters.LAST60D:
    case scanFilters.LAST7D:
    case scanFilters.LAST90D:
      xAxisDataKey = 'month';
      break;
    case scanFilters.LAST48H:
      xAxisDataKey = 'month';
      break;

    case scanFilters.CUSTOMIZE: {
      switch (validateDateInterval(startDate, endDate)) {
        case "day":
          xAxisDataKey = 'day';
          break;
        case "month":
          xAxisDataKey = 'month';
          break;
        default:
          xAxisDataKey = 'month';
          break;
      }
      break;
    }
    default:
      xAxisDataKey = 'month';
      break;
  }

  const rootStyle = getComputedStyle(document.documentElement);
  const primaryColor = rootStyle.getPropertyValue("--ct-secondary");
  const strokeColor = rootStyle.getPropertyValue("--ct-input-placeholder-color");

  return (
    <ResponsiveContainer width="95%" height={300}>
      <LineChart data={data}>
        <CartesianGrid
          horizontal={false}
          vertical={false}
          stroke={strokeColor}
        />
        <XAxis
          dataKey={xAxisDataKey}
          tickFormatter={(value) => (filterType === timeReferences.DAY ? value.split(' ')[0] : value)}
          axisLine={false}
          stroke={strokeColor}
          padding={{ right: 20, left: 20 }}
        />
        <YAxis
          stroke={strokeColor}
          axisLine={false}
          padding={{ top: 20, bottom: 20 }}
        />
        <Tooltip />
        <Line
          type="monotone"
          dataKey="scans"
          stroke={primaryColor}
          strokeWidth={5}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
export { ScanLineChart };

