const typeClassMap = (type) => {
  switch (type) {
    case "checkbox":
      return "form-check-label";
    default:
      return "form-label";
  }
}

export function Label({ type = "", className = "", ...props }) {
  const typeClassName = typeClassMap(type);

  return (
    <label className={`${typeClassName} ${className}`} {...props} />
  );
}