import React, { useState } from "react";

export function RangeDate({ type, className = "", ...props }) {
  const [today] = useState(new Date().toISOString().split("T")[0]);
  const validationClassStartDate = props.errorDate1 ? "is-" : "";
  const validationClassEndDate = props.errorDate2 ? "is-invalid" : "";

  const columnClasses = props.columns === 1 ? "col-12" : "col-12 col-sm-6";
  const min = props.min === true ? { min: today } : {};

  const inputClassNames = props.inputClassName || "";

  const rowClasses = props.removeRow ? "col-12" : "row g-3 col-12";

  const addedInputClasses = props.removeRow ? "mb-3" : "";

  return (
    <div className={rowClasses}>
      <div className={columnClasses}>
        <div className="grid-container">
          <label className="form-label" htmlFor={props.startName}>
            {props.startDateLabel}
          </label>
          <input
            className={`form-control ${validationClassStartDate} ${inputClassNames} ${addedInputClasses}`}
            type={"date"}
            disabled={props.isDisabled}
            onChange={props.handleStartDateChange}
            value={props.startDate}
            name={props.startName}
            placeholder={props.placeholder}
            {...min}
          />
          {props.errorDate1 && (
            <div className="invalid-feedback">{props.errorDate1} </div>
          )}
        </div>
      </div>
      <div className={columnClasses}>
        <div className="grid-container">
          <label className="form-label" htmlFor={props.endName}>
            {props.endDateLabel}
          </label>
          <input
            className={`form-control ${validationClassEndDate} ${inputClassNames}`}
            type={"date"}
            disabled={!props.startDate || props.isDisabled}
            onChange={props.handleEndDateChange}
            value={props.endDate}
            name={props.endName}
            placeholder={props.placeholder}
            min={props.startDate}
          />
          {props.errorDate2 && (
            <div className="invalid-feedback">{props.errorDate2} </div>
          )}
        </div>
      </div>
    </div>
  );
}
