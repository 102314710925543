import { getToken } from "../../../helpers";
import { API } from "../../../constant";

export default async function makeRequest(requestUrl, method = "GET", body = {}, requestType) {
    const myHeader = new Headers();
    myHeader.append("Authorization", `Bearer ${getToken()}`);
    myHeader.append("Content-Type", "application/json",);
    if (!requestUrl.startsWith("/"))
        requestUrl = "/" + requestUrl
    if (requestUrl.endsWith("/"))
        requestUrl = requestUrl.substring(0, requestUrl.length - 1);

    if (requestType?.toUpperCase() === "USER") {
        const response = await fetch(
            API +
            requestUrl,
            {
                method: method,
                headers: myHeader,
                body: JSON.stringify(body),
            }
        );
        const data = await response.json();
        return data;
    }

    if (method.toUpperCase() !== "GET") {
        const response = await fetch(
            API +
            requestUrl,
            {
                method: method,
                headers: myHeader,
                body: JSON.stringify({ data: { ...body } }),
            }
        );
        const data = await response.json();
        return data;
    }

    const response = await fetch(
        API +
        requestUrl,
        {
            method: method,
            headers: myHeader,
        }
    );
    const data = await response.json();
    return data;
}