import makeRequest from "../../dataFetching"
import { API_CONTACT_US, INFO_MENU } from "../../../utils/constants";
import { findContactPageMapping } from "../../dataMapping/contactPage";
import { infoMenuMapping } from "../../dataMapping/infoMenu";
/**
 * FIND
 * Fetches all ContactPage entries.
 * @returns {Promise<Object>} A promise that resolves to the response data from the GET request.
 */
export async function findContactPage () {
  const responseContact = await makeRequest(API_CONTACT_US);
  const responseInfoMenu = await makeRequest(INFO_MENU);
  
  const mappedContact = findContactPageMapping(responseContact);
  const mappedInfoMenu = infoMenuMapping(responseInfoMenu);

  return { 
    pageData: mappedContact,
    infoCardData: mappedInfoMenu
  }
}

/**
 * CREATE LOCALIZATION
 * Creates a new ContactPage localization entry.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function createLocalizationContactPage ({}) {}


/**
 * PUT
 * Updates an existing ContactPage entry.
 * @param {integer} id - The ID of the ContactPage entry to update.
 * @returns {Promise<Object>} A promise that resolves to the response data from the PUT request.
 */
export function updateContactPage (id, {}) {}


/**
 * DELETE
 * Deletes a specific ContactPage entry by ID.
 * @param {integer} id - The ID of the ContactPage entry to delete.
 * @returns {Promise<Object>} A promise that resolves to the response data from the DELETE request.
 */
export function deleteCheckoutPage ({ id }) {}
