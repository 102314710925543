import React, { useRef, useState } from "react";

import { LOCAL } from "../../constant";
const UploadFileForm = ({
  acceptedtypes,
  multipleFiles,
  maxSize,
  filesState,
  startingFile,
  pageData,
  error,
}) => {
  const [file, setFile] = useState(formatFile(startingFile) || null);
  const fileInputRef = useRef(null);
  const fileIsUploadedImage = file?.type?.includes("image");
  const fileIsImage = file?.mime?.includes("image") || fileIsUploadedImage;

  const fileURL = !fileIsUploadedImage
    ? LOCAL + file?.url
    : URL.createObjectURL(file);

  function formatFile(file) {
    if (!file) return;

    return {
      ...file,
      size: file.size * 1024,
    };
  }

  const updateFileSize = (fileSizeInBytes) => {
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
    return fileSizeInMB.toFixed(2);
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelected = (event) => {
    const selectedFile = event.target.files[0];
    filesState(selectedFile);
    setFile(selectedFile);
  };

  return (
    <>
      <div className="uploadFileForm--form">
        <div className="uploadFileForm--InsertFileForm">
          {file ? (
            <div className="uploadFileForm--FileList">
              <div className="uploadFileForm--FileInfo">
                {fileIsImage && (
                  <div className="card p-2">
                    <div className="row g-0 align-items-center">
                      <div className="col-md-2">
                        <img
                          className="img-fluid rounded-start"
                          src={fileURL}
                          alt={file.name}
                        />
                      </div>
                      <div className="col-md-10">
                        <div className="card-body">
                          <h5 className="card-title mb-1">{file.name}</h5>
                          <small>
                            {pageData.fileSizeTitle}
                            {updateFileSize(file.size)} mb
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!fileIsImage && (
                  <div className="card p-2">
                    <div className="card-body">
                      <h5 className="card-title mb-1">{file.name}</h5>
                      <small>
                        {pageData.fileSizeTitle}
                        {updateFileSize(file.size)} mb
                      </small>
                    </div>
                  </div>
                )}
                {!fileIsImage && startingFile && (
                  <>
                    <a href={fileURL} target="_blank" rel="noreferrer noopener">
                      {pageData.PreviousFile}
                    </a>
                  </>
                )}
              </div>
            </div>
          ) : (
            <p>{pageData.MaxSize}</p>
          )}
          <button className="btn btn-primary" onClick={handleUploadClick}>
            {pageData.Upload}
            <br />
          </button>
          <input
            type="file"
            id="fileInput"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept={acceptedtypes}
            onChange={handleFileSelected}
            error={error}
          />
          {error && <div className="invalid-feedback">{error}</div>}
          
        </div>
      </div>
    </>
  );
};

export default UploadFileForm;
