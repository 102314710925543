import { Link } from "react-router-dom";

export default function BackNavigation({ text, url = "" }) {
  const id = "backNavigation";
  const targetUrl = url || -1;

  return (
    <div id={id} className="position-fixed top-50 start-0" style={{ zIndex: 2000 }}>
      <Link
        to={targetUrl}
        className="p-2"
        data-bs-container={`#${id}`}
        data-bs-toggle="tooltip"
        title={text}
      >
        <i className="mdi mdi-chevron-left text-dark h1"></i>
      </Link>
    </div>
  );
}