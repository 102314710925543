import React from "react"

// Components Imports
import { Hero, CTABanner, HeroButton, Section, SupportCards } from "../../components/Landing";
import InfoCard from "../../components/InfoCard";
import Loading from '../../components/Loading';

// Hooks Imports
import usePagesData from "../../hooks/pageData/usePagesData";

// Utils Imports
import { LOCAL } from '../../constant';
import { PageTypes } from "../../hooks/pageData/PageTypes";

const Support = () => {
    const supportId = "support";

    const { pageData: pageContent, isPending, isError } = usePagesData({ pageType: PageTypes.SUPPORT });

    if (isPending || isError) return <Loading />;

    const pageData = pageContent.pageData;
    const infoCardData = pageContent.infoCardData;

    const header = pageData.Header;
    const bannerCTA = pageData.BannerCTA;
    const headerSection_1 = pageData.HeaderSection_1;

    const headerImage = LOCAL + header.Image.data.attributes.url;

    const supportCards = (
        pageData.Card.map(card => {
            const image = LOCAL + card.Image.data.attributes.url;
            const title = card.Title;
            const description = card.Description;
            const url = card.URL;

            return { image, title, description, url };
        })
    )

    return (
        <>
            <Hero
                title={header.Title}
                subtitle={header.Subtitle}
                image={headerImage}
            >
                <HeroButton id={supportId} text={header.ButtonText} icon={header.ButtonIconClass} />
            </Hero>

            <Section
                isFirstSection={true}
                titles={headerSection_1.Title.map((t) => t.Text)}
                descriptions={[headerSection_1.Subtitle]}
                icon={headerSection_1.Icon}
                navigationId={supportId}
            >
                <SupportCards cards={supportCards} />
            </Section>

            <CTABanner text={bannerCTA.Title} button={bannerCTA.Button} url={bannerCTA.URL} />
            <InfoCard infoCardData={infoCardData} />
        </>
    )
}

export default Support 