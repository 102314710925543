import { LOCAL } from "../../../constant"
import makeRequest from "./makeRequest"

export function getAllFiles() {
  return new Promise(function (resolve, reject) {
      makeRequest(`/upload/files/`, "GET").then(data => {
          try {
              if (data.error) throw data.error.message
              if (!data) {
                  reject({})
                  return
              }

              resolve(data)
          }
          catch (error) {
              console.error(error)
          }
      })
  })
}

export function deleteFile(id) {
  return new Promise(function (resolve, reject) {
      makeRequest(`/upload/files/${id}`, "DELETE").then(data => {
          try {
              if (data.error) throw data.error.message
              if (!data) {
                  reject({})
                  return
              }

              resolve(data)
          }
          catch (error) {
              console.error(error)
              //toast.error("Erro na eliminação de um ficheiro!")
          }
      })
  })
}

export async function fetchLottie(url = ""){
    try {
        const res = await fetch(LOCAL + url);
        
        return await res.json();
    } catch (error) {
        console.error(error);
    }
}