import { forwardRef } from "react";

const typeClassMap = (type) => {
  switch (type) {
    case "checkbox":
      return "form-check-input";
    default:
      return "form-control";
  }
};

export const Input = forwardRef(({ type, className = "", error, ...props }, ref) => {
  const typeClassName = typeClassMap(type);
  const validationClass = error ? "is-invalid" : "";

  return (
    <>
      <input
        type={type}
        className={`${typeClassName} ${className} ${validationClass} `}
        ref={ref}
        {...props}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </>
  );
});
