import { LottieTypes } from "./LottieTypes"
import makeRequest from "../../dataFetching"
import { 
  API_LOTTIE_CONTACT, 
  API_LOTTIE_PLANS_AND_PRICING, 
  API_LOTTIE_QR_INACTIVE,
  API_LOTTIE_NOT_FOUND
} from "../../../utils/constants";
import { lottieMapping } from "../../dataMapping/lottie";
/**
 * Fetches the Lottie animation data based on the provided Lottie type.
 * @param {LottieTypes} lottieTypes - The type of Lottie animation to fetch.
 * @returns {Promise<Object>} A promise that resolves to the response data from the API request.
 * If the Lottie type is not recognized, the promise is rejected with an error message.
 */
export async function fetchLottie(lottieType){
  switch(lottieType) {
    case LottieTypes.CONTACT:
      return makeRequest(API_LOTTIE_CONTACT)
        .then(lottieMapping);
    case LottieTypes.PLANS_AND_PRICING:
      return makeRequest(API_LOTTIE_PLANS_AND_PRICING)
        .then(lottieMapping); 
    case LottieTypes.QR_INACTIVE:
      return makeRequest(API_LOTTIE_QR_INACTIVE)
        .then(lottieMapping);
    case LottieTypes.NOT_FOUND:
      return makeRequest(API_LOTTIE_NOT_FOUND)
        .then(lottieMapping);
    default:
      return Promise.reject({ type: 'error', message: 'Lottie animation not found' });
  }
}