export function Group({ columns = 1, className = "", children, ...props }) {
  const rowClass = (() => {
    switch (columns) {
      case 2:
        return "col-md-6";
      case 3:
        return "col-md-4";
      case 4:
        return "col-md-3";
      default:
        return "";
    }
  })();

  return (
    <div className={`mb-3 ${rowClass} ${className}`} {...props} >
      {children}
    </div>
  );
}