import makeRequest from "../../dataFetching"
import { API_SUPPORT_CATEGORIES, INFO_MENU } from "../../../utils/constants";
import { findSupportCategoryPageMapping } from "../../dataMapping/supportCategoryPage";
import { infoMenuMapping } from "../../dataMapping/infoMenu";

/**
 * FIND
 * Fetches all SupportCategoryPage entries.
 * @returns {Promise<Object>} A promise that resolves to the response data from the GET request.
 */
export async function findSupportCategoryPage ({ theme }) {
  const responseSupportCategory = await makeRequest(`${API_SUPPORT_CATEGORIES}/${theme}`);
  const responseInfoMenu = await makeRequest(INFO_MENU);
  
  const mappedSupportCategory = findSupportCategoryPageMapping(responseSupportCategory);
  const mappedInfoMenu = infoMenuMapping(responseInfoMenu);

  return { 
    pageData: mappedSupportCategory,
    infoCardData: mappedInfoMenu
  }
}

/**
 * CREATE LOCALIZATION
 * Creates a new SupportCategoryPage localization entry.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function createLocalizationSupportCategoryPage ({}) {}


/**
 * PUT
 * Updates an existing SupportCategoryPage entry.
 * @param {integer} id - The ID of the SupportCategoryPage entry to update.
 * @returns {Promise<Object>} A promise that resolves to the response data from the PUT request.
 */
export function updateSupportCategoryPage (id, {}) {}


/**
 * DELETE
 * Deletes a specific SupportCategoryPage entry by ID.
 * @param {integer} id - The ID of the SupportCategoryPage entry to delete.
 * @returns {Promise<Object>} A promise that resolves to the response data from the DELETE request.
 */
export function deleteSupportCategoryPage ({ id }) {}
