export function AskedQuestions({ questions, symbol = "Q." }) {
  const questionElements = questions.map((question, index) => {
    return <Question key={index}
      symbol={symbol}
      question={question.question}
      answer={question.answer}
    />;
  });

  return (
    <div className="row row-cols-md-2 pt-5">
      {questionElements}
    </div>
  )
}

function Question({ symbol, question, answer }) {
  return (
    <div className="">
      <div className="faq-question-q-box">{symbol}</div>
      <h4 className="faq-question" data-wow-delay=".1s">{question}</h4>
      <p className="faq-answer mb-4">{answer}</p>
    </div>
  );
}