import { forwardRef } from "react";

const sizeMap = (size) => {
  switch (size) {
    case "sm":
      return "modal-sm";
    case "lg":
      return "modal-lg";
    case "full-width":
      return "modal-full-width";
    case "scroll":
      return "modal-dialog-scrollable";
    default:
      return "";
  }
}

const positionMap = (position) => {
  switch (position) {
    case "top":
      return "modal-top";
    case "bottom":
      return "modal-bottom";
    case "center":
      return "modal-dialog-centered";
    default:
      return "";
  }
}

const coloredHeaderClasses = (headerColor) => {
  if (headerColor === "") return "";

  let className = "modal-colored-header";
  className += ` ${headerColor}`;

  return className;
}

const Modal = forwardRef(({ id, size = "", position = "", headerColor = "", staticBackdrop = false, title, footer = { close: "", confirm: { text: "", onClick: () => { }, color: "btn-primary" } }, children }, closeButtonRef) => {
  const sizeClass = sizeMap(size);
  const positionClass = positionMap(position);
  const headerColorClasses = coloredHeaderClasses(headerColor);

  const dynamicClasses = `${sizeClass} ${positionClass}`;

  const showFooter = footer.close || footer.confirm.text;
  const showClose = footer.close !== "";
  const showConfirm = footer.confirm.text !== "";

  const staticAttributes = staticBackdrop ?
    { "data-bs-backdrop": "static", "data-bs-keyboard": "false" }
    : "";

  return (
    <div className="modal fade" id={id} {...staticAttributes} tabIndex="-1" role="dialog" aria-labelledby={id} aria-hidden="true">
      <div className={`modal-dialog ${dynamicClasses}`}>
        <div className="modal-content">
          <div className={`modal-header ${headerColorClasses}`}>
            <h4 className="modal-title">{title}</h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true" ref={closeButtonRef}></button>
          </div>
          <div className="modal-body">
            {children}
          </div>
          {showFooter &&
            <div className="modal-footer">
              {
                showClose &&
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{footer.close}</button>
              }
              {
                showConfirm &&
                <button type="button" className={`btn ${footer.confirm.color}`} onClick={footer.confirm.onClick}>{footer.confirm.text}</button>
              }
            </div>}
        </div>
      </div>
    </div >
  )
});

export default Modal;