import Lottie from 'react-lottie';

import useLottie from "../../hooks/lottie/useLottie";

export default function ExternalLottie({ lottieType }) {
  const { defaultOptions, animationIsLoading } = useLottie(lottieType);

  if(animationIsLoading) return;

  return (<Lottie options={defaultOptions} />);
}