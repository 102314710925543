
import { PricePlan } from "./PricePlan";

export default function Pricing({ isPreview = false, paymentPlans, recommendedText, button = { text: "", onClick: null } }) {
  const planCards = paymentPlans.map((plan, index) => {
    const planData = plan.attributes;

    const isRecommended = index === 1;
    return (
      <PricePlan
        key={plan.id + planData.Title}
        isPreview={isPreview}
        recommended={isRecommended ? recommendedText : null}
        name={planData.Title}
        icon="mdi mdi-diamond-stone"
        price={planData.Price}
        currency={planData.Currency}
        interval={planData.FrequencyLabel}
        frequency={planData.PaymentFrequency}
        features={planData.DescriptionItems.map((i) => i.Text)}
        slug={planData.Slug}
        button={{
          text: button.text || planData.Button,
          onClick: button.onClick !== null ? () => button.onClick(planData.Slug) : null
        }}
      />
    )
  });

  const marginClasses = !isPreview ? "mt-sm-5 mt-3 mb-3 g-4" : "my-2";

  return (
    <div className={`row ${marginClasses}`}>
      {planCards}
    </div>
  )
}