import makeRequest from "../../dataFetching";
import { API_AUTH_LOCAL, API_AUTH_REGISTER, API_AUTH_UPDATE_PASSWORD, API_AUTH_RECOVERY_PASSWORD } from "../../../utils/constants";
import { loginMapping, registerMapping, updatePasswordMapping, recoveryPasswordMapping, updatePasswordTokenMapping } from "../../dataMapping/auth";

/**
 * login
 * Authenticates a user using their identifier and password.
 * @param {Object} params - The parameters for login.
 * @param {string} params.identifier - The identifier (username or email) of the user.
 * @param {string} params.password - The password of the user.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function login ({ identifier, password }) {
  return makeRequest(API_AUTH_LOCAL, { 
    method: "POST", 
    body: {
      identifier,
      password
    }
  })
  .then(loginMapping);
}

/**
 * register
 * Registers a new user with the provided username, email, password, and role.
 * @param {Object} params - The parameters for registration.
 * @param {string} params.username - The username of the new user.
 * @param {string} params.email - The email of the new user.
 * @param {string} params.password - The password of the new user.
 * @param {string} params.role - The role of the new user.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function register ({ username, email, password, role }) {
  return makeRequest(API_AUTH_REGISTER, { 
    method: "POST", 
    body: {
      username,
      email, 
      password, 
      role
    }
  })
  .then(registerMapping);
}

/**
 * updatePassword
 * Updates the password for a user.
 * @param {Object} params - The parameters for updating the password.
 * @param {string} params.username - The username of the user.
 * @param {string} params.email - The email of the user.
 * @param {string} params.password - The new password for the user.
 * @param {string} params.role - The role of the user.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function updatePassword ({ currentPassword, password, passwordConfirmation }) {
  return makeRequest(API_AUTH_UPDATE_PASSWORD, { 
    method: "POST",
    body: {
      currentPassword,
      password,
      passwordConfirmation
    }
  })
  .then(updatePasswordMapping);
}

/**
 * recoveryPassword
 * Send a token to the user
 * @param {Object} params - The parameters for updating the password.
 * @param {string} params.email - The email of the user.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function recoveryPassword ({ email }) {
  return makeRequest(API_AUTH_RECOVERY_PASSWORD, { 
    method: "POST",
    body: {
      email
    }
  })
  .then(recoveryPasswordMapping);
}


/**
 * updatePasswordToken
 * Updates the password for a user.
 * @param {Object} params - The parameters for updating the password.
 * @param {string} params.password - The password of the user.
 * @param {string} params.passwordConfirmation - The password confirmation.
 * @param {string} params.token - The recovery token.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function updatePasswordToken ({ password, passwordConfirmation, token }) {
  return makeRequest(API_AUTH_RECOVERY_PASSWORD, { 
    method: "POST",
    body: {
      password,
      passwordConfirmation,
      code: token,
    }
  })
  .then(updatePasswordTokenMapping);
}