import { scanFilters, timeReferences } from '../components/Charts/constants';
import { validateDateInterval } from '../utils';

export default function useCreateChartData(scans, filterType, startDate, endDate, pageData) {
  // Define the 'now' variable
  const now = new Date();
  const yesterday = new Date(now.getTime() - 24 * 60 * 60 * 1000);
  const yesterdayStart = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 0, 0, 0);
  const yesterdayEnd = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 23, 59, 59);
  let filteredScans = [];

  switch (filterType) {
    case timeReferences.HOUR:
      filteredScans = scans.filter(scan => {
        const createdAt = new Date(scan.attributes.createdAt);
        return now - createdAt <= 60 * 60 * 1000;
      });
      break;
    case timeReferences.DAY:
    case scanFilters.TODAY:
      filteredScans = scans.filter(scan => {
        const createdAt = new Date(scan.attributes.createdAt);
        return now - createdAt <= 24 * 60 * 60 * 1000;
      });
      break;
    case scanFilters.LAST12M:
      filteredScans = scans.filter(scan => {
        const createdAt = new Date(scan.attributes.createdAt);
        const twelveMonthsAgo = new Date(now.getTime() - 12 * 30 * 24 * 60 * 60 * 1000);
        return createdAt >= twelveMonthsAgo && createdAt <= now;
      });
      break;
    case scanFilters.LAST30D:
    case timeReferences.MONTH:
      filteredScans = scans.filter(scan => {
        const createdAt = new Date(scan.attributes.createdAt);
        const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
        return createdAt >= thirtyDaysAgo && createdAt <= now;
      });
      break;
    case scanFilters.LAST60D:
      filteredScans = scans.filter(scan => {
        const createdAt = new Date(scan.attributes.createdAt);
        const sixtyDaysAgo = new Date(now.getTime() - 60 * 24 * 60 * 60 * 1000);
        return createdAt >= sixtyDaysAgo && createdAt <= now;
      });
      break;
    case scanFilters.LAST90D:
      filteredScans = scans.filter(scan => {
        const createdAt = new Date(scan.attributes.createdAt);
        const ninetyDaysAgo = new Date(now.getTime() - 90 * 24 * 60 * 60 * 1000);
        return createdAt >= ninetyDaysAgo && createdAt <= now;
      });
      break;
    case scanFilters.LAST48H:
      const fortyEightHoursAgo = new Date(now.getTime() - 48 * 60 * 60 * 1000);
      filteredScans = scans.filter(scan => {
        const createdAt = new Date(scan.attributes.createdAt);
        return createdAt >= fortyEightHoursAgo && createdAt <= now;
      });
      break;
    case scanFilters.YESTERDAY:
      filteredScans = scans.filter(scan => {
        const createdAt = new Date(scan.attributes.createdAt);
        return createdAt >= yesterdayStart && createdAt <= yesterdayEnd;
      });
      break;
    case scanFilters.CUSTOMIZE:
      filteredScans = scans.filter(scan => {
        const createdAt = new Date(scan.attributes.createdAt);
        return createdAt >= startDate && createdAt <= endDate;
      });
      break;
    case scanFilters.LAST7D:
      filteredScans = scans.filter(scan => {
        const createdAt = new Date(scan.attributes.createdAt);
        const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        return createdAt >= sevenDaysAgo && createdAt <= now;
      });
      break;

    default:
      filteredScans = scans;
      break;
  }
  // Create an object to store the scan counts for each hour, day, or month
  const scanCounts = {};

  if (filterType === timeReferences.HOUR) {
    // Generate the labels for each 10-minute interval in the last hour range
    const lastHour = now.getHours();
    for (let i = lastHour; i >= lastHour - 1; i--) {
      for (let j = 5; j >= 0; j--) {
        const hourLabel = `${i < 10 ? '0' + i : i}:${j * 10 < 10 ? '0' + j * 10 : j * 10}`;
        scanCounts[hourLabel] = 0;
      }
    }
  } else if (filterType === timeReferences.DAY || filterType === scanFilters.TODAY || filterType === scanFilters.YESTERDAY) {
    // Generate the labels for each hour interval in the last 24 hours
    const lastDay = now.getDate();
    for (let i = 23; i >= 0; i--) {
      const hourLabel = `${i < 10 ? '0' + i : i}:00`;
      scanCounts[hourLabel] = 0;
    }
  } else if (filterType === scanFilters.LAST30D || filterType === timeReferences.MONTH) {

    const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
    const dayLabels = [];
    let currentDate = new Date(thirtyDaysAgo);
    while (currentDate <= now) {
      const dayLabel = currentDate.toLocaleDateString('pt-PT', { month: '2-digit', day: '2-digit' });
      scanCounts[dayLabel] = 0;
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }
  else if (filterType === scanFilters.LAST7D) {
    const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    let currentDate = new Date(sevenDaysAgo);
    while (currentDate <= now) {
      const dayLabel = currentDate.toLocaleDateString('pt-PT', { month: '2-digit', day: '2-digit' });
      scanCounts[dayLabel] = 0;
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }
  else if (filterType === scanFilters.LAST60D) {
    // Generate the labels for each day in the last 60 days
    for (let i = 59; i >= 0; i--) {
      const dayLabel = new Date(now.getTime() - i * 24 * 60 * 60 * 1000).toLocaleDateString('pt-PT', { month: '2-digit', day: '2-digit' });
      scanCounts[dayLabel] = 0;
    }
  }
  else if (filterType === scanFilters.LAST90D) {
    const ninetyDaysAgo = new Date(now.getTime() - 90 * 24 * 60 * 60 * 1000);
    let currentDate = new Date(ninetyDaysAgo);
    while (currentDate <= now) {
      const dayLabel = currentDate.toLocaleDateString('pt-PT', { month: '2-digit', day: '2-digit' });
      scanCounts[dayLabel] = 0;
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }
  else if (filterType === scanFilters.LAST48H) {
    const fortyEightHoursAgo = new Date(now.getTime() - 48 * 60 * 60 * 1000);
    let currentHour = new Date(fortyEightHoursAgo);
    while (currentHour <= now) {
      const hourLabel = currentHour.toLocaleString('pt-PT', { month: '2-digit', day: '2-digit', hour: '2-digit' }) + ":00";
      scanCounts[hourLabel] = 0;
      currentHour.setTime(currentHour.getTime() + 60 * 60 * 1000);
    }
  }
  else if (filterType === scanFilters.CUSTOMIZE) {
    let interval = validateDateInterval(startDate, endDate)
    switch (interval) {
      case "day":
        for (let i = 23; i >= 0; i--) {
          const hourLabel = `${i < 10 ? '0' + i : i}:00`;
          scanCounts[hourLabel] = 0;
        }
        break;
      case "month":
        const startDay = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        const endDay = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
        const dayLabels = [];

        let currentDay = new Date(startDay.getTime());

        while (currentDay <= endDay) {
          const dayLabel = currentDay.toLocaleDateString('pt-PT', { month: '2-digit', day: '2-digit' });
          dayLabels.push(dayLabel);
          currentDay.setDate(currentDay.getDate() + 1);
        }

        dayLabels.forEach(day => {
          scanCounts[day] = 0;
        });
        break;
      default:
        break;
    }
  }
  else {
    // Generate the labels for each month
    const monthLabels = pageData.months.data.map(month => month.attributes.Name);
    monthLabels.forEach(month => {
      scanCounts[month] = 0;
    });
  }

  // Iterate through the filtered scans and count them for each hour, day, or month
  filteredScans?.forEach(scan => {
    const createdAt = new Date(scan.attributes.createdAt);

    if (filterType === timeReferences.HOUR) {
      const hourLabel = `${createdAt.getHours() < 10 ? '0' + createdAt.getHours() : createdAt.getHours()}:${Math.floor(createdAt.getMinutes() / 10) * 10}`;
      scanCounts[hourLabel]++;
    } else if (filterType === timeReferences.DAY || filterType === scanFilters.TODAY || filterType === scanFilters.YESTERDAY) {
      const hourLabel = `${createdAt.getHours() < 10 ? '0' + createdAt.getHours() : createdAt.getHours()}:00`;
      scanCounts[hourLabel]++;
    } else if (filterType === scanFilters.LAST30D || filterType === timeReferences.MONTH || filterType === scanFilters.LAST60D || filterType === scanFilters.LAST7D || filterType === scanFilters.LAST90D) {
      const dayLabel = createdAt.toLocaleDateString('pt-PT', { month: '2-digit', day: '2-digit' });
      scanCounts[dayLabel]++;
    }
    else if (filterType === scanFilters.LAST48H) {
      const hourLabel = createdAt.toLocaleString('pt-PT', { month: '2-digit', day: '2-digit', hour: '2-digit' }) + ":00";
      scanCounts[hourLabel]++;
    }

    else if (filterType === scanFilters.CUSTOMIZE) {
      switch (validateDateInterval(startDate, endDate)) {
        case "day":
          const hourLabel = `${createdAt.getHours() < 10 ? '0' + createdAt.getHours() : createdAt.getHours()}:00`;
          scanCounts[hourLabel]++;
          break;
        case "month":
          const dayLabel = createdAt.toLocaleDateString('pt-PT', { month: '2-digit', day: '2-digit' });
          scanCounts[dayLabel]++;
          break;
        default:
          break;
      }
    }
    else {
      const monthLabel = createdAt.toLocaleString('pt-PT', { month: 'long' });
      scanCounts[monthLabel]++;
    }
  });

  // Convert the scanCounts object into an array of objects with hour, day, or month and scan properties
  const data = Object.keys(scanCounts).map(key => ({
    [filterType === timeReferences.HOUR ? 'hour' : (filterType === timeReferences.DAY ? 'day' : 'month')]: key,
    scans: scanCounts[key]
  }));

  return data;
};