import { useState, useEffect, useCallback } from "react";
import { findCheckoutPage } from "../../services/apiCalls/checkoutPage";
import { findSettingsPage } from "../../services/apiCalls/settingsPage";
import { findUsersPage } from "../../services/apiCalls/usersPage";
import { findHomePage } from "../../services/apiCalls/homePage";
import { findCreateQrPage } from "../../services/apiCalls/createQrPage";
import { findForgotPasswordPage } from "../../services/apiCalls/ForgotPasswordPage";
import { findSingInPage } from "../../services/apiCalls/signInPage";
import { findMyQrCodesPage } from "../../services/apiCalls/myQrCodesPage";
import { findNotFoundPage } from "../../services/apiCalls/notFound";
import { findContactPage } from "../../services/apiCalls/contactPage";
import { findPaymentsPage } from "../../services/apiCalls/paymentsPage";
import { findPlansAndPricingPage } from "../../services/apiCalls/plansAndPricingPage";
import { findProfilePage } from "../../services/apiCalls/profilePage";
import { findSignUpPage } from "../../services/apiCalls/signUpPage";
import { findStatisticsPage } from "../../services/apiCalls/statisticsPage";
import { findSupportCategoryPage } from "../../services/apiCalls/supportCategoryPage";
import { findSupportPage } from "../../services/apiCalls/supportPage";
import { findFAQPage } from "../../services/apiCalls/FAQPage";
import { findQrLinkTreeRedirect } from "../../services/apiCalls/qrCodeLinkTreesPage";
import { findQrCodeDetailsPage } from "../../services/apiCalls/qrCodeDetailsPage";
import { findPaymentPlansPage } from "../../services/apiCalls/paymentPlansPage";
import { findFooter } from "../../services/apiCalls/footer";
import { toast } from "../../services/toast";
import { ToastType } from "../../services/toast/ToastType";
import { PageTypes } from "./PageTypes";
import { internalErrors } from "../../utils/error";
import { useParams } from "react-router-dom";

export default function usePagesData ({ pageType }) {
  const [pageData, setPageData] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [isError, setIsError] = useState(false);
  const detailTheme = useParams().theme;

  const handleFind = useCallback((callback) => {
    setIsPending(true);
    setIsError(false);

    callback
      .then(res => setPageData(res))
      .catch(err => {
        setIsError(true);
        if (!internalErrors(err)) toast({ 
          actionName: ToastType.PAGE_DATA.ACTION_NAME,
          actionMessage: ToastType.PAGE_DATA.MESSAGES.ERROR_PAGE_INFORMATION
        });
      })
      .finally(() => setIsPending(false));
  }, []);

  useEffect(() => {

    if(!pageType) return;

    switch (pageType) {
      case PageTypes.CHECKOUT_PAGE:
        handleFind(findCheckoutPage());
        break;
      case PageTypes.SETTING:
        handleFind(findSettingsPage());
        break;
      case PageTypes.USERS_PAGE:
        handleFind(findUsersPage());
        break;
      case PageTypes.HOME:
        handleFind(findHomePage());
        break;
      case PageTypes.CREATE_QR:
        handleFind(findCreateQrPage());
        break;
      case PageTypes.FORGOT_PASSWORD:
        handleFind(findForgotPasswordPage());
        break;
      case PageTypes.SIGN_IN:
        handleFind(findSingInPage());
        break;
      case PageTypes.MY_QR_CODES:
        handleFind(findMyQrCodesPage());
        break;
      case PageTypes.NOT_FOUND:
        handleFind(findNotFoundPage());
        break;
      case PageTypes.CONTACT:
        handleFind(findContactPage());
        break;
      case PageTypes.PAYMENTS:
        handleFind(findPaymentsPage());
        break;
      case PageTypes.PLANS_AND_PRICING:
        handleFind(findPlansAndPricingPage());
        break;
      case PageTypes.PROFILE_PAGE:
        handleFind(findProfilePage());
        break;
      case PageTypes.SIGN_UP:
        handleFind(findSignUpPage());
        break;
      case PageTypes.STATISTICS:
        handleFind(findStatisticsPage());
        break;
      case PageTypes.SUPPORT_CATEGORY:
        handleFind(findSupportCategoryPage({ theme: detailTheme}));
        break;
      case PageTypes.SUPPORT:
        handleFind(findSupportPage());
        break;
      case PageTypes.FAQ:
        handleFind(findFAQPage());
        break;
      case PageTypes.QR_LINK_TREE_REDIRECT:
        handleFind(findQrLinkTreeRedirect());
        break;
      case PageTypes.QR_CODE_DETAILS:
        handleFind(findQrCodeDetailsPage());
        break;
      case PageTypes.PAYMENT_PLANS:
        handleFind(findPaymentPlansPage());
        break;
      case PageTypes.FOOTER:
        handleFind(findFooter());
        break;
      default:
        toast({ 
          actionName: ToastType.PAGE_DATA.ACTION_NAME,
          actionMessage: ToastType.PAGE_DATA.MESSAGES.ERROR_PAGE_INFORMATION
        });
        break;
    }
  }, []);
  
  return { pageData, isPending, isError };
}