import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { addSubscription, confirmPayment } from '../../../services/apiCalls/db';
import { toast } from '../../../utils';

export function PaymentForm({ pageData, invoiceDetails, currentPlan, paymentIntentId, subscriptionId, setIsProcessingPayment }) {
  const stripe = useStripe();
  const elements = useElements();

  const [isSuccess, setIsSuccess] = useState(false);

  const toggleSuccessDialog = () => {
    setIsSuccess(prev => !prev);
  }

  const handleSuccessfullPayment = async () => {
    const subscriptionDetails = {
      ...invoiceDetails,
      payment_plan: currentPlan.id,
      PaymentIntentId: paymentIntentId,
      SubscriptionId: subscriptionId,
    }

    try {
      await addSubscription(subscriptionDetails);
      toggleSuccessDialog();

    } catch (error) {
      return toast(pageData.CouldNotStoreYourSubscription);
    }
  }

  const handlePaymentSubmit = async (event) => {
    event.preventDefault();
    
    if (!stripe || !elements) return;

    setIsProcessingPayment(true);

    const { error, paymentIntent } = await confirmPayment(stripe, elements);

    if (error) {
      // No need to translate since Stripe reads the user prefered language
      toast({ Type: "error", Message: error.message });
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      handleSuccessfullPayment();
    } else {
      toast(pageData.UnexpectedError);
      console.error("Payment status: " + paymentIntent.status);
    }

    setIsProcessingPayment(false);
  }

  const successPopUp = pageData.SuccessfulPayment;

  return (
    <>
      {
        isSuccess &&
        <>
          <div id="success-alert-modal" data-bs-backdrop="static" data-bs-keyboard="false" className="modal fade show d-block" tabIndex="-1" aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-sm">
              <div className="modal-content modal-filled bg-success">
                <div className="modal-body p-4">
                  <div className="text-center">
                    <i className="dripicons-checkmark h1"></i>
                    <h4 className="mt-2 text-white">{successPopUp.Title}</h4>
                    <p className="mt-3">{successPopUp.Description}</p>
                    <Link to="/payments" className="btn btn-outline-light my-2">
                      {successPopUp.Confirm}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>
      }
      <form onSubmit={handlePaymentSubmit} id="payment-form">
        <PaymentElement />
        {/* <button type="submit" disabled={isProcessing}>
          {isProcessing ? pageData.ProcessingButton : pageData.PayButton}
        </button> */}
      </form>
    </>
  )
}