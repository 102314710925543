import { API } from "../../../constant";
import { getToken } from "../../../helpers";
import { deleteFile } from "./file";
import makeRequest from "./makeRequest";

export function getAllQRCodes(options = { includeScans: false }) {
    
    const { includeScans } = options;

    const baseURL = "/qr-codes";
    const query = includeScans ? "?populate[scans]=*" : "";

    return new Promise(function (resolve, reject) {
        makeRequest(baseURL + query, "GET", {}).then(data => {
            try {
                if (data.error) throw data.error.message
                if (!data) {
                    reject({})
                    return
                }

                var allQRCodes = data.data.map(qrCode => { return { id: qrCode.id, ...qrCode.attributes } })
                resolve(allQRCodes)
            }
            catch (error) {
                console.error(error)
                // toast.error("Erro na obtenção de QR Codes!")
            }
        })
    })
}
export function CheckIdentifier(identifier) {
    return new Promise(function (resolve, reject) {
        makeRequest(`/qr-codes?identifier=${identifier}`, "GET", {}).then(data => {
            try {
                if (data.error) throw data.error.message
                if (!data) {
                    reject({})
                    return
                }

                var allQRCodes = data.data.map(qrCode => { return { id: qrCode.id, ...qrCode.attributes } })
                resolve(allQRCodes)
            }
            catch (error) {
                console.error(error)
                // toast.error("Erro na obtenção de QR Codes!")
            }
        })
    })
}
export function getAllQRCodesFromUser() {
    return new Promise(function (resolve, reject) {
        makeRequest("/qr-codes?populate[scans]=*&populate[image][fields][0]=url")
            .then(res => {
                try {
                    if (res.error) throw res.error.message
                    if (!res) {
                        reject({})
                        return
                    }

                    const qrCodes = res.data.map(q => {
                        return {
                            id: q.id,
                            role: res.meta.roles[q.id],
                            ...q.attributes
                        }
                    });
                    resolve(qrCodes);
                }
                catch (error) {
                    console.error(error)
                    reject("CouldNotGetQRCodes");
                }
            })
    })
}
export function getQRCode(id) {
    return new Promise(function (resolve, reject) {
        try {
            makeRequest(`/qr-codes/${id}?populate[qr_code_unsaved_change]=*&populate[scans]=*&populate[mediaField]=[fields][0]=url&populate[image]=[fields][0]=url&populate[qr_code_linktrees][populate][image][fields][0]=url`, "GET", {}).then(data => {
                if (data.error) return reject(data.error.message)
                if (!data) {
                    reject({})
                    return
                }

                var qrCode = { id: data.data.id, ...data.data.attributes, role: data.meta.role }

                resolve(qrCode)
            })
        }
        catch (error) {
            console.error(error)
        }
    })
}
export function getQRCodeByIdentifier(id) {
    return new Promise(function (resolve, reject) {
        try {
            makeRequest(`/qr-code/identifier/${id}`, "GET", {}).then(data => {
                if (data.error) throw data.error.message
                if (!data) {
                    reject({})
                    return
                }

                var qrCode = { ...data }

                resolve(qrCode)
            })
        }
        catch (error) {
            console.error(error)
            // toast.error("Erro na obtenção do QR Code!")
        }
    })
}

/**
* Adds media files to the body object by uploading them to a server and replacing the file instances with the returned IDs.
*
* @param {Object} body - The body object that may contain File instances in the 'image' and 'mediaField' properties.
* @returns {Promise<Object>} A promise that resolves to the body object with File instances replaced by the IDs of the uploaded files.
* @throws Will throw an error if the server returns an error during file upload.
*/
async function addMediaFiles(body) {
    // Is old (don't need to upload)
    const oldFileId = body?.mediaField?.data?.at(0)?.id;

    if (oldFileId) {
        return { ...body, mediaField: oldFileId };
    }

    // Is new (newly updated)
    if (body.image instanceof File) {
        var formData = new FormData()
        formData.append("files", body.image)

        const imageResponse = await fetch(`${API}/upload`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
                body: formData
            })

        const imageData = await imageResponse.json();

        body = { ...body, image: imageData[0].id }
        if (imageData.error) throw imageData.error.message
    }
    if (body.mediaField instanceof File) {
        var formData = new FormData()
        formData.append("files", body.mediaField)

        const mediaFieldResponse = await fetch(`${API}/upload`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
                body: formData
            })

        const mediaFieldData = await mediaFieldResponse.json();

        body = { ...body, mediaField: mediaFieldData[0].id }
        if (mediaFieldData.error) throw mediaFieldData.error.message
    }

    return body;
}

function addLinkTrees(qrCodeId, linktree) {
    if (!linktree) return;

    return new Promise(async (resolve, reject) => {
        for (const link of linktree) {
            link.qr_code = qrCodeId;

            if (link.image instanceof File) {
                var formData = new FormData()
                formData.append("files", link.image)

                const imageResponse = await fetch(`${API}/upload`,
                    {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${getToken()}`,
                        },
                        body: formData
                    })

                const imageData = await imageResponse.json();

                link.image = imageData[0].id
                if (imageData.error) throw imageData.error.message
            }

            await makeRequest(`/qr-code-linktrees/`, "POST", link);
        }
        resolve();
    })
}

export function addQRCode(body) {
    return new Promise(async function (resolve, reject) {
        if (body.name === "" || body.description === "") throw "missing data"

        body = await addMediaFiles(body);

        try {
            let linktree;
            if (body.qr_code_linktrees) {
                linktree = body.qr_code_linktrees;
                delete body.qr_code_linktrees;
            }
            makeRequest(`/qr-codes/`, "POST", body)
                .then(async (data) => {
                    if (data.error) return reject(data.error.message)
                    if (!data) {
                        reject({})
                        return
                    }

                    var qrCode = { id: data.data.id, ...data.data.attributes }

                    await addLinkTrees(qrCode.id, linktree);

                    resolve(qrCode)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        catch (error) {
            console.error(error)
            reject("UnsavedChangesError");
            //toast.error("Erro na criação de alterações não guardadas do QR Code!")
        }
    })
}

async function getScansLinksFiles(qrCodeId) {
    try {
        const res = await makeRequest(`/qr-codes/${qrCodeId}?populate[0]=qr_code_linktrees&populate[1]=qr_code_linktrees.image&populate[2]=image&populate[3]=mediaField&populate[4]=scans`, "GET");

        const data = res.data.attributes

        return {
            image: data?.image?.data?.at(0)?.id,
            mediaField: data?.mediaField?.data?.at(0)?.id,
            links: data?.qr_code_linktrees?.data?.map(link => link.id),
            scans: data?.scans?.data?.map(scan => scan.id)
        }
    } catch (err) {
        console.error(err);
    }
}

async function deleteScans(scansIds) {
    const deleteScansPromises = scansIds.map(id => makeRequest(`/scans/${id}`, "DELETE"));

    return await Promise.all(deleteScansPromises);
}

export async function deleteQRCode(id) {
    try {
        const deletePromises = [];

        const { image, mediaField, links, scans } = await getScansLinksFiles(id);

        const res = await makeRequest(`/qr-codes/${id}`, "DELETE");

        if (res.error) {
            return Promise.reject(res.error.message);
        }

        if (image) {
            deletePromises.push(deleteFile(image));
        }

        if (mediaField) {
            deletePromises.push(deleteFile(mediaField));
        }

        if (links) {
            deletePromises.push(deleteLinks(links));
        }

        if (scans) {
            deletePromises.push(deleteScans(scans));
        }

        await Promise.all(deletePromises);

        var qrCode = { id: res.data.id, ...res.data.attributes }
        return qrCode;
    }
    catch (error) {
        console.error(error);
        return error;
    }
}

/**
* Deletes all link trees associated with a given QR code.
*
* @param {string} qrCodeId - The ID of the QR code.
* @param {string[]} idsToIgnore - Ids of links that were already in that tree, do not need to be removed.
* @returns {Promise<Array>} A promise that resolves to an array of responses from the DELETE requests for each link tree.
* @throws Will throw an error if the GET request for the QR code or any of the DELETE requests for the link trees fail.
*/
async function deleteLinksFromQRCode(qrCodeId, idsToIgnore) {
    try {
        const qrCode = await makeRequest(`/qr-codes/${qrCodeId}?&populate[qr_code_linktrees][populate][image]=*`, "GET")

        const linkTreeIds = qrCode.data.attributes.qr_code_linktrees.data.map(link => link.id);

        const linksToDelete = linkTreeIds.filter(link => !idsToIgnore.toString().includes(link));

        return await deleteLinks(linksToDelete);

    } catch (error) {
        console.error('Error fetching QR code:', error);
        throw error;
    }
}

/**
* Deletes the specified link trees.
*
* @param {string[]} linksToDelete - The IDs of the link trees to delete.
* @returns {Promise} A promise that resolves to an array of responses from the DELETE requests for each link tree.
* @throws Will throw an error if any of the DELETE requests for the link trees fail.
*/
async function deleteLinks(linksToDelete) {
    const deletePromises = linksToDelete.map(link => {
        return makeRequest(`/qr-code-linktrees/${link}`, "DELETE");
    })

    try {
        return Promise.all([...deletePromises]);
    } catch (error) {
        console.error('Error deleting link trees:', error);
        throw error;
    }
}

export function updateQRCode(id, body) {
    return new Promise(async function (resolve, reject) {
        try {
            await getQRCode(id).then(QRbeforeUpdate => {
                if (QRbeforeUpdate.image.data) {
                    if (!body.image || body.image !== QRbeforeUpdate.image.data.id) {
                        deleteFile(QRbeforeUpdate.image.data.id)
                    }
                }
            })

            // For QRCodes that posses media files
            if (body.mediaField || body.image) {
                body = await addMediaFiles(body);
            }

            // For QRCodes of the type linktree
            if (body.type === "Lista de Links" && body.qr_code_linktrees) {
                let linktree;
                if (body.qr_code_linktrees) {
                    linktree = body.qr_code_linktrees;
                    delete body.qr_code_linktrees;
                }

                const linksToKeep = linktree?.filter(link => link.id !== undefined)?.map(link => link.id);
                await deleteLinksFromQRCode(id, linksToKeep);

                const newLinkTreeItems = linktree?.filter(link => link.publishedAt === undefined)
                await addLinkTrees(id, newLinkTreeItems);
            }


            makeRequest(`/qr-codes/${id}`, "PUT", body).then(data => {
                try {
                    if (data.error) throw data.error.message
                    if (!data) {
                        reject({})
                        return
                    }

                    var qrCode = { id: data.data.id, ...data.data.attributes }

                    resolve(qrCode)
                }
                catch (error) {
                    console.error(error)
                }
            })
        } catch (error) {
            console.error(error)
        }
    })
}
export function smallUpdateQRCode(id, body) {

    return new Promise(function (resolve, reject) {
        makeRequest(`/qr-codes/${id}`, "PUT", body).then(data => {
            try {
                if (data.error) throw data.error.message
                if (!data) {
                    reject({})
                    return
                }

                //toast.success("Qr-Code atualizado com sucesso!")
                resolve(data)
            }
            catch (error) {
                console.error(error)
                //toast.error("Erro na atualização dos Qr-Code!")
            }
        })

    })

}