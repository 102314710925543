import makeRequest from "../../dataFetching"
import { API_TOAST_MESSAGES } from "../../../utils/constants";
import { findOneToastMessagesMapping } from "../../dataMapping/toastMessages";

/**
 * FIND
 * Fetches all ToastMessages entries.
 * @returns {Promise<Object>} A promise that resolves to the response data from the GET request.
 */
export function findToastMessages () {}

/**
 * FIND ONE
 * Fetches all ToastMessages entries.
 * @returns {Promise<Object>} A promise that resolves to the response data from the GET request.
 */
export function findOneToastMessages ({ action, message }) {
  return makeRequest(`${API_TOAST_MESSAGES}/${action}/${message}`)
  .then(findOneToastMessagesMapping);
}

/**
 * CREATE LOCALIZATION
 * Creates a new ToastMessages localization entry.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function createLocalizationToastMessages ({}) {}


/**
 * PUT
 * Updates an existing ToastMessages entry.
 * @param {integer} id - The ID of the ToastMessages entry to update.
 * @returns {Promise<Object>} A promise that resolves to the response data from the PUT request.
 */
export function updateToastMessages (id, {}) {}


/**
 * DELETE
 * Deletes a specific ToastMessages entry by ID.
 * @param {integer} id - The ID of the ToastMessages entry to delete.
 * @returns {Promise<Object>} A promise that resolves to the response data from the DELETE request.
 */
export function deleteToastMessages ({ id }) {}
