import React from "react"

// Components Imports
import { Hero, CTABanner, HeroButton, Section, Accordion, AskedQuestions } from "../../components/Landing";
import BackNavigation from "../../components/BackNavigation";
import InfoCard from "../../components/InfoCard";
import Loading from "../../components/Loading";

// Hooks Imports
import usePagesData from "../../hooks/pageData/usePagesData";
import { PageTypes } from "../../hooks/pageData/PageTypes";

const SuportDetails = () => {
    const answersId = "answers";
    
    const { pageData: pageContent, isPending, isError } = usePagesData({ pageType: PageTypes.SUPPORT_CATEGORY });

    if (isPending || isError) return <Loading />;

    const pageData = pageContent.pageData;
    const infoCardData = pageContent.infoCardData;

    const header = pageData.Header;
    const bannerCTA = pageData.BannerCTA;
    const faqCards = pageData.faq_cards.data;

    const numberOfQuestions = faqCards.length;
    const tooManyQuestions = numberOfQuestions > 8;

    const getQuestions = () => {
        if (tooManyQuestions) {
            const faqs = faqCards.map(f => {
                const faq = f.attributes;
                return {
                    title: faq.question,
                    description: faq.answer,
                };
            });

            return <Accordion items={faqs} />
        }

        const faqs = faqCards.map(f => {
            const { question, answer } = f.attributes;
            return {
                question,
                answer,
            };
        });

        return <AskedQuestions questions={faqs} symbol="P." />
    }

    const questions = getQuestions();

    return (
        <>
            <BackNavigation text={pageData.goBackTitle} />
            <Hero
                title={pageData.heroTitle}
                subtitle={pageData.heroSubtitle}
                image={header.Image.data.attributes.url}
            >
                <HeroButton id={answersId} text={pageData.btnHeroTitle} />
            </Hero>
            <Section
                isFirstSection={true}
                titles={[header.Subtitle, header.Subtitle]}
                descriptions={[pageData.sectionDescription]}
                navigationId={answersId}
                icon="mdi-tools"
            >
                {questions}
            </Section>
            <CTABanner text={bannerCTA.Title} button={bannerCTA.Button} url={bannerCTA.URL} />
            <InfoCard infoCardData={infoCardData} />
        </>
    )

}

export default SuportDetails