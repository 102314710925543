// https://docs.stripe.com/elements/appearance-api

const rootStyle = getComputedStyle(document.documentElement);

const appearance = {
  theme: "stripe",

  variables: {
    colorPrimary: rootStyle.getPropertyValue("--ct-input-border-color"),
    colorText: rootStyle.getPropertyValue("--ct-input-color"),
    colorDanger: rootStyle.getPropertyValue("--ct-danger"),
    fontFamily: rootStyle.getPropertyValue("--ct-body-font-family"),
    colorBody: rootStyle.getPropertyValue("--ct-body-color"),
  },

  rules: {
    ".Input": {
      border: `1px solid ${rootStyle.getPropertyValue(
        "--ct-input-border-color"
      )}`,
      fontWeight: "400",
      color: rootStyle.getPropertyValue("--ct-input-color"),
      paddingBottom: ".45rem",
      paddingLeft: ".9rem",
      paddingRight: ".9rem",
      paddingTop: ".45rem",
      borderRadius: ".25rem",
      fontSize: "0.9rem",
      lineHeight: "1.5",
      backgroundColor: rootStyle.getPropertyValue("--ct-input-bg"),
      transition:
        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    },
    ".Input:focus": {
      borderColor: rootStyle.getPropertyValue("--ct-input-focus-border-color"),
      boxShadow: "none",
    },
    ".Label": {
      color: rootStyle.getPropertyValue("--ct-body-color"),
      fontSize: "14.4px",
      marginBottom: "8px",
      marginTop: "1em",
    },
    ".Input--invalid": {
      boxShadow: "none",
    },
    ".Error": {
      color: "#dc3545",
      fontSize: "12.6px",
      marginTop: "0.25rem",
      fontWeight: "400",
      fontFamily: "Nunito, sans-serif",
    },
  },
};

export default appearance;
