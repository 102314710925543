import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuthContext } from "./context/AuthContext";

import CreateQR from "./pages/CreateQR";
import FAQ from "./pages/Faq";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
import Page404 from "./pages/Page404";
import PageContact from "./pages/PageContact";
import PlansAndPrices from "./pages/PlansAndPrices";
import Profile from "./pages/Profile";
import QRDetails from "./pages/QrCodeDetails";
import Recovery from "./pages/Recovery";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import SuportDetails from "./pages/SuportDetails";
import Support from "./pages/Support";
import Users from "./pages/Users";
import MyQrCodes from "./pages/MyQrCodes";
import Settings from "./pages/Settings";
import Statistics from "./pages/Statistics";
import QrLinkTreeRedirect from "./pages/QrLinkTreeRedirect";
import Checkout from "./pages/Checkout";
import Payments from "./pages/Payments";
import SelectPlan from "./pages/Payments/SelectPlan";

const AppRoutes = () => {
  const { user, isLoading } = useAuthContext();

  if (isLoading) return null;
  const isLoggedIn = user != null;

  const signInNavigation = <Navigate to="/signin" />;
  const homeNavigation = <Navigate to="/" />;

  return (
    <Routes>
      {["/", "/home"].map((path, index) => (
        <Route
          key={index}
          path={path}
          element={isLoggedIn ? <Navigate to="/Statistics" /> : <Home />}
        />
      ))}

      <Route path="/PlansAndPrices" element={<PlansAndPrices />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/Support" element={<Support />} />
      <Route path="/Support/:theme" element={<SuportDetails />} />
      <Route path="/Contact" element={<PageContact />} />
      <Route
        path="/signin"
        element={!isLoggedIn ? <SignIn /> : homeNavigation}
      />
      <Route
        path="/recovery"
        element={!isLoggedIn ? <Recovery /> : homeNavigation}
      />
      <Route
        path="/forgotPassword"
        element={<Navigate to="/recovery" replace={true} />}
      />
      <Route
        path="/forgotPassword/:token"
        element={!isLoggedIn ? <ForgotPassword /> : homeNavigation}
      />
      <Route
        path="/signUp"
        element={!isLoggedIn ? <SignUp /> : homeNavigation}
      />
      <Route
        path="/profile"
        element={isLoggedIn ? <Profile /> : signInNavigation}
      />
      <Route
        path="/users"
        element={isLoggedIn ? <Users /> : signInNavigation}
      />
      <Route
        path="/create"
        element={isLoggedIn ? <CreateQR /> : signInNavigation}
      />
      <Route
        path="/QrDetails/:id"
        element={isLoggedIn ? <QRDetails /> : signInNavigation}
      />
      <Route
        path="/settings"
        element={isLoggedIn ? <Settings /> : signInNavigation}
      />
      <Route
        path="/payments"
        element={isLoggedIn ? <Payments /> : signInNavigation}
      />
      <Route
        path="/Statistics"
        element={isLoggedIn ? <Statistics /> : signInNavigation}
      />
      <Route
        path="/payments/selectPlan"
        element={isLoggedIn ? <SelectPlan /> : signInNavigation}
      />
      <Route
        path="/checkout"
        element={isLoggedIn ? <Checkout /> : signInNavigation}
      />
      <Route
        path="/MyQrCodes"
        element={isLoggedIn ? <MyQrCodes /> : signInNavigation}
      />
      <Route path="/Linktree/:identifier" element={<QrLinkTreeRedirect />} />

      <Route path="/*" element={<Page404 />} />
    </Routes>
  );
};

export default AppRoutes;
