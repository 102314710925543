import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./styles/fonts.css";
import "./styles/global.css";
import "./styles/overrides.css";
import "./styles/vars.css";

import Navbar from "./components/Navbar";
import AppRoutes from "./Routes";
import { useThemeSelect } from "./components/ThemeSelector";

const App = () => {
  useThemeSelect();

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        progress={undefined}
        theme="light"
      />
      {/* <Navbar> */}
        <AppRoutes />
      {/* </Navbar> */}
    </>
  );
};

export default App;
