import { useState, useEffect } from "react";

const useDarkMode = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    document.body.getAttribute("data-layout-color") === "dark"
  );

  useEffect(() => {
    const observer = new MutationObserver(() => {
      setIsDarkMode(document.body.getAttribute("data-layout-color") === "dark");
    });

    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ["data-layout-color"],
    });

    return () => observer.disconnect();
  }, []);

  return isDarkMode;
};
export { useDarkMode };
