import React, { useState } from "react";
import { Link } from "react-router-dom";

import { API } from "../../constant";
import { roles } from "../../constants/qrCode";
import QRPreview from "../QRPreview";

let qrCodeDimensions = { width: 120, height: 120 };

const QRDetailsCard = ({ userId, qrCode, pageData }) => {
  if (!pageData) throw new Error("pageData is required");
  
  const [shouldDownload, setShouldDownload] = useState(false)
  const [shouldCopy, setShouldCopy] = useState(false)
  const [shouldPrint, setShouldPrint] = useState(false)

  const ribbonClass = qrCode.role === roles.ADMIN ? "ribbon-two-success" :
    qrCode.role === roles.COLABORATOR ? "ribbon-two-warning" : "";
  const ribbonText = qrCode.role === roles.ADMIN ? pageData.adminTitle :
    qrCode.role === roles.COLABORATOR ? pageData.collaboratorTitle : "";

  const qrCodeTypeTitle = pageData.qr_code_type_cards.data.find(t => qrCode.type === t.attributes.Type)?.attributes?.Title;

  const createdBy = pageData.createdByTitle + qrCode.owner.username;

  const createdAtDate = new Date(qrCode.createdAt);
  const createdAt = pageData.CreatedAtLabel + " " + createdAtDate.toLocaleDateString();

  const updatedAtDate = new Date(qrCode.updatedAt);
  const updatedAt = pageData.UpdatedAtLabel + " " + updatedAtDate.toLocaleDateString();

  const identifier = `${API}/redirect/${qrCode.identifier}`;

  const url = qrCode.type !== "Wifi" && qrCode.url !== ""
    ? qrCode.url : "";

  const scans = qrCode?.scans?.data ? qrCode.scans.data.length : 0;

  const stateBadgeClasses = qrCode.state ? "badge bg-success" : "badge bg-danger";

  const detailsPage = `/QrDetails/${qrCode.id}`;

  return (
    <div className={`card ${ribbonClass ? "ribbon-box" : ""}`}>
      <div className="card-body">
        {
          qrCode.role !== roles.OWNER &&
          <div className={`ribbon-two ${ribbonClass} float-start`}>
            <span>
              {ribbonText}
            </span>
          </div>
        }
        <div className="row g-3">
          <div className="col-6 col-lg-2 col-md-3">
            <QRPreview
              pageData={pageData}
              width={qrCodeDimensions.width} 
              height={qrCodeDimensions.height}
              qrCode={qrCode}
              shouldCopy={shouldCopy}
              setShouldCopy={setShouldCopy}
              shouldDownload={shouldDownload}
              setShouldDownload={setShouldDownload}
              shouldPrint={shouldPrint}
              setShouldPrint={setShouldPrint}
            />
          </div>
          <div className="col-6 col-lg-2 col-md-3 d-flex flex-column justify-content-center">
            <span className="fs-5 fw-bold text-primary">
              {qrCodeTypeTitle}
            </span>
            <span className="fs-4">
              {qrCode.name}
            </span>
          </div>
          <div className="col-lg-2 d-lg-flex d-none flex-column justify-content-center">
            {
              qrCode.owner.id !== userId &&
              <span>
                <i className="mdi mdi-account-outline"></i> {createdBy}
              </span>
            }
            <span>
              <i className="mdi mdi-plus"></i> {createdAt}
            </span>
            <span>
              <i className="mdi mdi-pencil"></i> {updatedAt}
            </span>
          </div>
          <div className="col-xl-2 d-xl-flex d-none flex-column justify-content-center">
            <span className="d-inline-block text-truncate">
              <i className="mdi mdi-web"></i> {identifier}
            </span>
            {
              url &&
              <span className="d-inline-block text-truncate">
                <i className="mdi mdi-link"></i> {url}
              </span>
            }
          </div>
          <div className="col-6 col-lg-2 col-xl-1 col-md-2 d-flex flex-column justify-content-center">
            {
              qrCode.static ?
                <h4 className="text-center">
                  <span className="text-black badge bg-warning">
                    {pageData.spanStaticQR}
                  </span>
                </h4>
                :
                <div className="d-flex flex-column align-items-center">
                  <span className="fs-2 fw-bold">
                    {scans}
                  </span>
                  <span className="text-primary">
                    {pageData.Scans}
                  </span>
                </div>
            }

          </div>
          <div className="col-6 col-lg-2 col-xl-1 col-md-2 d-flex flex-column justify-content-center">
            <h4 className="text-center">
              <span className={stateBadgeClasses}>
                {qrCode.state ?
                  pageData.Active :
                  pageData.Inactive
                }
              </span>
            </h4>
          </div>
          <div className="col-lg-2 col-md-2 d-flex justify-content-between justify-content-md-center align-items-center px-4 px-md-0">
            <Link to={detailsPage} className="btn btn-action px-1">
              <i className="mdi mdi-eye font-20"></i>
            </Link>
            <button
              className="btn px-1 btn-action"
              onClick={() => setShouldCopy(true)}
            >
              <i className="mdi mdi-content-copy font-20"></i>
            </button>
            <button
              className="btn px-1 btn-action"
              onClick={() => setShouldDownload(true)}
            >
              <i className="mdi mdi-download font-20"></i>
            </button>
            <button
              className="btn px-1 btn-action"
              onClick={() => setShouldPrint(true)}
            >
              <i className="mdi mdi-printer font-20"></i>
            </button>
          </div>
        </div >
      </div>
    </div >
  );
}

export default QRDetailsCard;