import React from "react";

import Loading from "../../components/Loading";
import Pricing from "../../components/Pricing";
import { LOCAL } from "../../constant";
import usePagesData from "../../hooks/pageData/usePagesData";
import { PageTypes } from "../../hooks/pageData/PageTypes";

const SelectPlan = () => {
  const { pageData, isPending, isError } = usePagesData({ pageType: PageTypes.PAYMENT_PLANS });

  if (isPending || isError) return <Loading />;

  const featureImage = LOCAL + pageData.FeatureIcon.data.attributes.url;

  const paymentPlans = pageData.payment_plans.data;

  const features = pageData.Features;
  const featureItems = features.map((feature) => {
    return (
      <div key={`feature-${feature.Text}`} className="card">
        <div className="card-body border">
          <img src={featureImage} alt="" className="img-fluid me-2" />
          <span className="">{feature.Text}</span>
        </div>
      </div>
    );
  });
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <h4 className="page-title">{pageData.Title}</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="header-title">{pageData.SelectPlan}</h4>
                <div className="row d-flex justify-content-center">
                  <Pricing
                    paymentPlans={paymentPlans}
                    recommendedText={pageData.RecomendedLabel}
                  />
                  <span className="my-2 text-center">
                    {pageData.IVANotification}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {features && features.length > 0 && (
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3">
                    {pageData.PlansIncludeTitle}
                  </h4>
                  <div className="row">
                    <ul className="row row-cols-md-2 row-cols-lg-3">
                      {featureItems}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SelectPlan;
