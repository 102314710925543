import makeRequest from "../../dataFetching";
import { API_USERS_PAGE } from "../../../utils/constants";
import { findUsersPageMapping } from "../../dataMapping/usersPage";

/**
 * FIND
 * Fetches all UsersPage entries.
 * @returns {Promise<Object>} A promise that resolves to the response data from the GET request.
 */
export function findUsersPage () {
  return makeRequest(API_USERS_PAGE)
  .then(findUsersPageMapping);
}

/**
 * CREATE LOCALIZATION
 * Creates a new UsersPage localization entry.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function createLocalizationUsersPage ({}) {}


/**
 * PUT
 * Updates an existing UsersPage entry.
 * @param {integer} id - The ID of the UsersPage entry to update.
 * @returns {Promise<Object>} A promise that resolves to the response data from the PUT request.
 */
export function updateUsersPage (id, {}) {}


/**
 * DELETE
 * Deletes a specific UsersPage entry by ID.
 * @param {integer} id - The ID of the UsersPage entry to delete.
 * @returns {Promise<Object>} A promise that resolves to the response data from the DELETE request.
 */
export function deleteUsersPage ({ id }) {}
