import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";

import "../../styles/pages/Statistics.css";

// Components Imports
import Loading from "../../components/Loading";
import { CityBarChart, CountryBarChart, OsPieChart, ScanLineChart, scanFilters } from "../../components/Charts";
import { Group, RangeDate, SelectComponent } from "../../components/Form";
import { StatusCard } from "./components";

// Utils Imports
import { 
  countUniqueScans, 
  formatDateForInput, 
  generateChartDataFromProperties, 
  getAllCities, 
  getAllCountries, 
  getAllOperatingSystems, 
  getQrCodeOptions,
  getScansDataSingle 
} from "../../utils";

// Hooks Imports
import { useAuthContext } from "../../context/AuthContext";
import useFindQrCodeScans from "../../hooks/qrCode/useFindQrCodeScans";
import useAllScans from "../../hooks/qrCode/useAllScans";
import usePagesData from "../../hooks/pageData/usePagesData";
import { PageTypes } from "../../hooks/pageData/PageTypes";

const Statistics = () => {
  const { user } = useAuthContext();
  const { qrCodes } = useFindQrCodeScans();
  const { AllScans } = useAllScans(qrCodes);

  const chartFilterRef = useRef(null);
  const [chartFilter, setChartFilter] = useState("");

  const specificQrFilterRef = useRef(null);
  const [specificQrFilter, setSpecificQrFilter] = useState(false);

  const chartOsFilterRef = useRef(null);
  const [chartOsFilter, setChartOsFilter] = useState("");

  const chartCountryFilterRef = useRef(null);
  const [chartCountryFilter, setChartCountryFilter] = useState("");

  const chartCityFilterRef = useRef(null);
  const [chartCityFilter, setChartCityFilter] = useState("");

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const csvData = useRef([]);

  const countNumberOfScans = (scans) => {
    if (Array.isArray(scans)) return scans.length;
    return 0;
  };

  const handleSortByOs = (e) => {
    setChartOsFilter(e);
  };

  const handleSortByCountry = (e) => {
    setChartCountryFilter(e);
  };

  const handleSortByCity = (e) => {
    setChartCityFilter(e);
  };

  const handleSortBySpecificQr = (qrCodeName) => {
    const foundQrCode = qrCodes.find((qr) => qr.name === qrCodeName);
    setSpecificQrFilter(getScansDataSingle(foundQrCode));
  };

  const handleFilterClear = () => {
    const selects = [
      chartFilterRef,
      specificQrFilterRef,
      chartOsFilterRef,
      chartCountryFilterRef,
      chartCityFilterRef
    ];

    selects.forEach((select) => {
      if (!select.current) return;
      select.current.clearValue();
    });
  };

  const filterIsApplied = () => {
    const selects = [
      chartFilterRef,
      specificQrFilterRef,
      chartOsFilterRef,
      chartCountryFilterRef,
      chartCityFilterRef
    ];

    return selects.reduce((acc, cur) => {
      const select = cur.current;
      if (!select) return false;

      return acc || select.state.focusedOption !== undefined;
    }, false);
  };

  const handleFilterChange = (filter) => {
    setChartFilter(filter);
  };

  const getCurrentQRCodeName = () => {
    const qrCodeFilterSelect = specificQrFilterRef.current;
    if (!qrCodeFilterSelect) return "";

    const { focusedOption } = qrCodeFilterSelect.state;

    if (focusedOption) return focusedOption.label;

    return "";
  };

  const handleStartDateChange = (e) => {
    setStartDate(new Date(e.target.value));
  };

  const handleEndDateChange = (e) => {
    setEndDate(new Date(e.target.value));
  };

  const graphScans = specificQrFilter ? specificQrFilter.scans.data : AllScans?.scans?.data;

  const [operatingSystem, country, city] = generateChartDataFromProperties(
    graphScans,
    ["operatingSystem", "country", "city"]
  );

  const { pageData, isPending, isError } = usePagesData({ pageType: PageTypes.STATISTICS });
  
  if (!user || !AllScans || !qrCodes || isPending || isError) return <Loading />;

  const currentFilterIcon = filterIsApplied() ? "mdi-filter-off-outline" : "mdi-filter-outline";

  const qrCodeFooterName = getCurrentQRCodeName();
  const specificQrCodeFiltered = specificQrFilter ? true : false;

  const qrTotalFooter = {
    show: specificQrCodeFiltered,
    label: pageData.SelectLabel,
    value: qrCodeFooterName,
  };

  const scanTotalFooter = {
    show: specificQrCodeFiltered,
    label: qrCodeFooterName + ":",
    value: specificQrCodeFiltered
      ? countNumberOfScans(specificQrFilter.scans.data)
      : 0,
  };

  const uniqueScanTotalFooter = {
    show: specificQrCodeFiltered,
    label: qrCodeFooterName + ":",
    value: specificQrCodeFiltered
      ? countUniqueScans(specificQrFilter.scans.data)
      : 0,
  };

  const noInformationAvailable = (
    <div className="d-flex justify-content-center align-items-center min-graph-height">
      <h5 className="text-center">{pageData.NoInformationAvailable}</h5>
    </div>
  );

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <h4 className="page-title">{pageData.Title}</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <StatusCard
              label={pageData.QRCodeTotal}
              value={qrCodes?.length || 0}
              icon="mdi-qrcode"
              footer={qrTotalFooter}
            />
          </div>
          <div className="col-md-4">
            <StatusCard
              label={pageData.ScanTotal}
              value={countNumberOfScans(AllScans.scans.data)}
              icon="mdi-qrcode-scan"
              footer={scanTotalFooter}
            />
          </div>
          <div className="col-md-4">
            <StatusCard
              label={pageData.UniqueScanTotal}
              value={countNumberOfScans(AllScans.scans.data)}
              icon="mdi-account"
              footer={uniqueScanTotalFooter}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-xl-3 col-lg-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between mt-2 mb-3">
                  <h4>{pageData.FilterLabel}</h4>
                  <button
                    className="btn btn-outline-primary"
                    onClick={handleFilterClear}
                  >
                    <i className={`mdi ${currentFilterIcon} float-end`}></i>
                  </button>
                </div>
                <Group>
                  <SelectComponent
                    ref={chartFilterRef}
                    id="filter-scans"
                    name="filter-scans"
                    isClearable={true}
                    placeholder={pageData.FilterScans.Title}
                    onChange={(s) => handleFilterChange(s?.value)}
                    options={pageData.FilterScans.Options.map((o) => ({
                      label: o.Text,
                      value: o.Value,
                    }))}
                  />
                </Group>
                {chartFilter === scanFilters.CUSTOMIZE && (
                  <Group>
                    <RangeDate
                      isDisabled={false}
                      removeRow={true}
                      columns={1}
                      startName="startDate"
                      endName="endDate"
                      startDate={formatDateForInput(startDate)}
                      endDate={formatDateForInput(endDate)}
                      handleStartDateChange={handleStartDateChange}
                      handleEndDateChange={handleEndDateChange}
                      startDateLabel={pageData.Start || "Início"}
                      endDateLabel={pageData.End || "Fim"}
                      pageData={pageData}
                    />
                  </Group>
                )}
                <Group>
                  <SelectComponent
                    ref={specificQrFilterRef}
                    id="filter-scans-by-qrcodes"
                    name="filter-scans-by-qrcodes"
                    isClearable={true}
                    placeholder={pageData.AllQRCodes}
                    onChange={(s) => handleSortBySpecificQr(s?.value)}
                    options={getQrCodeOptions(qrCodes)}
                    isOptionDisabled={(option) => option.static}
                  />
                </Group>
                <Group>
                  <SelectComponent
                    ref={chartOsFilterRef}
                    id="filter-scans-by-os"
                    name="filter-scans-by-os"
                    isClearable={true}
                    placeholder={pageData.AllOperativeSystems}
                    onChange={(s) => handleSortByOs(s?.value)}
                    options={getAllOperatingSystems(qrCodes).map((os) => ({
                      label: os,
                      value: os,
                    }))}
                  />
                </Group>
                <Group>
                  <SelectComponent
                    ref={chartCountryFilterRef}
                    id="filter-scans-by-country"
                    name="filter-scans-by-country"
                    isClearable={true}
                    placeholder={pageData.AllCountries}
                    onChange={(s) => handleSortByCountry(s?.value)}
                    options={getAllCountries(qrCodes).map((c) => ({
                      label: c,
                      value: c,
                    }))}
                  />
                </Group>
                <Group>
                  <SelectComponent
                    ref={chartCityFilterRef}
                    id="filter-scans-by-city"
                    name="filter-scans-by-city"
                    isClearable={true}
                    placeholder={pageData.AllCities}
                    onChange={(s) => handleSortByCity(s?.value)}
                    options={getAllCities(qrCodes).map((c) => ({
                      label: c,
                      value: c,
                    }))}
                  />
                </Group>
              </div>
            </div>
          </div>

          <div className="col-xl-9 col-lg-8">
            <div className="card card-h-100">
              <div className="card-body">
                {graphScans.length > 0 && (
                  <CSVLink
                    className="p-0 float-end"
                    data={csvData.current}
                    filename={pageData.ExportFileName}
                  >
                    {pageData.ExportButton}
                    <i className="mdi mdi-download ms-1"></i>
                  </CSVLink>
                )}
                <h4 className="mt-2 mb-3">{pageData.ScanActivity}</h4>
                {graphScans.length > 0 ? (
                  <ScanLineChart
                    scans={graphScans}
                    filterType={chartFilter}
                    osFilter={chartOsFilter}
                    cityFilter={chartCityFilter}
                    countryFilter={chartCountryFilter}
                    startDate={startDate}
                    endDate={endDate}
                    csvData={csvData}
                    pageData={pageData}
                  />
                ) : (
                  noInformationAvailable
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-4 col-lg-5">
            <div className="card min-graph-height">
              <div className="card-body min-graph-height">
                <h4 className="mt-2 mb-3">{pageData.ScansByOperativeSystem}</h4>
                {operatingSystem.length === 0 ? (
                  noInformationAvailable
                ) : (
                  <OsPieChart data={operatingSystem} />
                )}
              </div>
            </div>
          </div>

          <div className="col-xl-8 col-lg-7">
            <div className="card min-graph-height">
              <div className="card-body min-graph-height">
                <h4 className="mt-2 mb-3">{pageData.ScansByCountry}</h4>
                {country.length === 0 ? (
                  noInformationAvailable
                ) : (
                  <CountryBarChart data={country} />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="card min-graph-height">
            <div className="card-body">
              <h4 className="mt-2 mb-3">{pageData.ScansByCity}</h4>
              {city.length === 0 ? (
                noInformationAvailable
              ) : (
                <CityBarChart data={city} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Statistics;
