import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';

import { Input } from "../../components/Form";
import Loading from '../../components/Loading';
import Modal from "../../components/Modal";
import Pricing from "../../components/Pricing";
import { STRIPE_PUBLIC_KEY } from '../../constant';
import { useAuthContext } from "../../context/AuthContext";
import { cancelPaymentIntent } from "../../services/apiCalls/db";
import appearance from "../../styles/components/StripeElements";
import { InvoiceElements, PaymentForm, Summary } from "./components";
import { STEPS } from "./constants";

import usePayment from "../../hooks/usePayment";

import usePagesData from "../../hooks/pageData/usePagesData";
import { PageTypes } from "../../hooks/pageData/PageTypes";


const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const plansModalId = "plansModal";

const Checkout = () => {
  const { pageData, isPending } = usePagesData({ pageType: PageTypes.CHECKOUT_PAGE });

  const { createPayment, paymentInformation, subscriptionId } = usePayment({
    errorMessages: {
      CantDowngradePlan: pageData?.CantDowngradePlan ?? ""
    }
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  let currentPlanSlug = searchParams.get("plan");
  const [currentPlan, setCurrentPlan] = useState(undefined);
  const closePlansModalRef = useRef(null);

  const [invoiceDetails, setInvoiceDetails] = useState();
  const [currentStep, setCurrentStep] = useState(STEPS.INVOICE);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const { user, isLoading: userIsLoading } = useAuthContext();

  const resetInvoiceDetails = useCallback(
    () => {
      setInvoiceDetails({
        isParticular: user.isParticular || "",
        companyName: user.companyName || "",
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        email: user.email || "",
        contact: user.contact || "",
        nif: user.nif || "",
        address: user.address || "",
        postalCode: user.postalCode || "",
        country: user.country || "",
        address2: user.address2 || "",
        city: user.city || "",
      });
    },
    [user],
  );

  useEffect(() => {
    if (!user) return;

    resetInvoiceDetails();
  }, [user, resetInvoiceDetails]);

  if (userIsLoading || isPending) return <Loading />;

  const currentPlanSlugIsValid = (planSlug = "") => {
    return pageData.payment_plans.data.some(p => p.attributes.Slug === planSlug);
  }

  if (!currentPlanSlugIsValid(currentPlanSlug)) {
    currentPlanSlug = pageData.payment_plans.data[0].attributes.Slug;
  }

  const getCurrentPlanData = (planSlug) => {
    return pageData.payment_plans.data.find(p => p.attributes.Slug === planSlug);
  }

  const handlePlanChange = (planSlug) => {
    setSearchParams({ plan: planSlug }, { replace: true });

    const currentPlanData = getCurrentPlanData(planSlug);
    setCurrentPlan({ id: currentPlanData.id, ...currentPlanData.attributes });

    closePlansModalRef.current.click();
  }

  const handleFirstCancel = () => {
    navigate(-1);
  }

  const handleSecondCancel = () => {
    cancelPaymentIntent(subscriptionId);
    setCurrentStep(STEPS.INVOICE);
  }

  const currentPlanData = getCurrentPlanData(currentPlanSlug);
  if (currentPlan === undefined && currentPlanData) {
    setCurrentPlan({ id: currentPlanData.id, ...currentPlanData.attributes });
  }

  const unselectedPlans = pageData.payment_plans.data.filter(p => p.attributes.Slug !== currentPlanSlug);

  const plansModal = (
    <Modal
      ref={closePlansModalRef}
      id={plansModalId}
      position="center"
      size="lg"
      title={pageData.SelectYourPlan}>
      <Pricing
        isPreview={true}
        paymentPlans={unselectedPlans}
        recommendedText={pageData.RecommendedText}
        button={{
          text: pageData.ChoosePlanButton,
          onClick: handlePlanChange
        }}
      >
      </Pricing>
    </Modal>

  )

  const submitFormId = currentStep === STEPS.INVOICE ? "invoice-form" : "payment-form";

  const clientSecret = paymentInformation.clientSecret;

  if (currentPlan === undefined) return (<Loading />);

  const payButtonText = `${pageData.PayButton} ${paymentInformation.price.total} ${pageData.Currency}`;

  return (
    <>
      {plansModal}
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <button type="submit" disabled={isProcessingPayment} form={submitFormId} className="btn btn-primary float-end">
                  {
                    currentStep === STEPS.INVOICE ?
                      pageData.Next
                      :
                      isProcessingPayment ?
                        pageData.ProcessingButton :
                        payButtonText
                  }
                  {
                    currentStep === STEPS.INVOICE ?
                      <i className="mdi mdi-arrow-right ms-2"></i>
                      :
                      <i className="mdi mdi-credit-card ms-2"></i>
                  }
                </button>
                {
                  currentStep === STEPS.INVOICE ?
                    <button type="button" onClick={handleFirstCancel} className="btn btn-outline-secondary float-end me-2">
                      <i className="mdi mdi-arrow-left me-2"></i>{pageData.Cancel}
                    </button>
                    :
                    <button type="button" onClick={handleSecondCancel} className="btn btn-outline-secondary float-end me-2">
                      <i className="mdi mdi-arrow-left me-2"></i>{pageData.Cancel}
                    </button>
                }
              </div>
              <h4 className="page-title">{pageData.Title}</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8">

            <div className="card">
              <div className="card-body">
                <h4 className="mt-2 mb-3">{pageData.Plan}</h4>
                <div className="row g-3">
                  <div className="col-md-8 col-lg-6">
                    <Input value={currentPlan.Title} readOnly={true} />
                  </div>
                  <div className="col-md-4 col-lg-6 d-flex justify-content-md-end">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target={`#${plansModalId}`}
                      disabled={currentStep === STEPS.PAYMENT}
                    >
                      {pageData.ChangePlan}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <h4 className="mt-2 mb-3">{pageData.Billing}</h4>
                {stripePromise &&
                  <Elements
                    stripe={stripePromise}
                    options={{ appearance }}
                  >
                    <InvoiceElements
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      pageData={pageData}
                      invoiceDetails={invoiceDetails}
                      setInvoiceDetails={setInvoiceDetails}
                      createPayment={() => createPayment({ currentPlan })}
                    />
                  </Elements>
                }
              </div>
            </div>

            {stripePromise && currentStep === STEPS.PAYMENT && clientSecret &&
              <div className="card">
                <div className="card-body">
                  <h4 className="mt-2 mb-3">{pageData.PaymentMethod}</h4>
                  <Elements
                    stripe={stripePromise}
                    options={{ appearance, clientSecret }}
                  >
                    <PaymentForm
                      pageData={pageData}
                      invoiceDetails={invoiceDetails}
                      currentPlan={currentPlan}
                      paymentIntentId={clientSecret}
                      subscriptionId={subscriptionId}
                      setIsProcessingPayment={setIsProcessingPayment}
                    />
                  </Elements>
                </div>
              </div>
            }

          </div>
          <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                <Summary
                  pageData={pageData}
                  currentPlan={currentPlan}
                  price={paymentInformation.price}
                />
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default Checkout