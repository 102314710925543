import { useElements } from '@stripe/react-stripe-js';
import React from 'react';

import InvoiceForm from '../../../components/InvoiceForm';
import { updateCustomer } from "../../../services/apiCalls/db";
import { toast } from "../../../utils";
import { STEPS } from "../constants";
import { InvoicePreview } from "./";

const InvoiceElements = ({ currentStep, setCurrentStep, pageData, invoiceDetails, setInvoiceDetails, createPayment }) => {
  const elements = useElements();

  const validateExtraFields = (invoiceDetails) => {
    const isParticular = invoiceDetails.isParticular;

    const nameEmpty =
      (invoiceDetails.compnayName === "" && !isParticular) ||
      ((invoiceDetails.firstName === "" || invoiceDetails.lastName === "") && isParticular);

    const emailEmpty = invoiceDetails.email === "";
    const nifEmpty = invoiceDetails.nif === "";

    return !nameEmpty && !emailEmpty && !nifEmpty;
  }

  const handlePaymentStep = async (event) => {
    event.preventDefault();

    const addressElement = elements.getElement("address");
    const { complete } = await addressElement.getValue();

    if (!complete) return;

    const allElementsFilled = validateExtraFields(invoiceDetails);

    if (!allElementsFilled) {
      toast(pageData.FillAllFields);
      return;
    }

    await updateCustomer(invoiceDetails);

    const createdPaymentIntent = await createPayment();

    if (createdPaymentIntent) {
      setCurrentStep(STEPS.PAYMENT);
    }
  }

  return (
    <>
      {currentStep === STEPS.INVOICE ?
        <InvoiceForm
          pageData={pageData}
          invoiceDetails={invoiceDetails}
          setInvoiceDetails={setInvoiceDetails}
          onSubmit={handlePaymentStep}
          hideSubmitButton={true}
        />
        :
        <InvoicePreview
          invoiceDetails={invoiceDetails}
        />
      }
    </>
  )
}

export { InvoiceElements };

