import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

export function OsPieChart({ data = [] }) {
  const rootStyle = getComputedStyle(document.documentElement);
  const android = rootStyle.getPropertyValue("--chart-android");
  const ios = rootStyle.getPropertyValue("--chart-ios");
  const windows = rootStyle.getPropertyValue("--chart-windows");
  const macOS = rootStyle.getPropertyValue("--chart-macos");
  const unknown = rootStyle.getPropertyValue("--chart-unknown");

  const COLORS = {
    Android: android,
    iOS: ios,
    Windows: windows,
    macOS: macOS,
    Unknown: unknown,
  }

  return (
    <ResponsiveContainer width="95%" height={300}>
      <PieChart>
        <Pie
          dataKey="scans"
          data={data}
          outerRadius={80}
          innerRadius={60}
          paddingAngle={5}
          label={entry => entry.name}
        >
          {data.map((entry, index) => (
            <Cell key={`os-${index}`} fill={COLORS[entry.name]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
} 