import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

// Components Imports
import { Brand } from "../../components/Authentication";
import { Group, Input, Label } from "../../components/Form";
import Loading from "../../components/Loading";

// Hooks Imports
import usePagesData from "../../hooks/pageData/usePagesData";
import useAuth from "../../hooks/auth/useAuth";

// Utils Imports
import { LOCAL } from "../../constant";
import { PageTypes } from "../../hooks/pageData/PageTypes";

export default function Recovery() {
  const { pageData, isPending, isError } = usePagesData({ pageType: PageTypes.SIGN_IN });
  const { doRecovery } = useAuth();
  
  if (isPending || isError) return <Loading />;

  const logo = LOCAL + pageData.Logo.data.attributes.url;

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const payload = Object.fromEntries(formData.entries());
    const { email } = payload;

    if (!email) {
      toast.warning(pageData.PleaseFillInAllFields.Message);
      return;
    }
    
    doRecovery({ email });
  };
  const backgroundStyle = {
    backgroundImage: `url(${LOCAL + pageData.Image.data?.attributes.url})`,
    backgroundSize: "cover",
  };
  return (
    <div className="auth-fluid" style={backgroundStyle}>
      <div className="auth-fluid-form-box">
        <div className="align-items-center d-flex h-100">
          <div className="card-body">
            <Brand logo={logo} />

            <h4 className="mt-0">{pageData.RecoveryPopUp.Title}</h4>
            <p className="text-muted mb-4">
              {pageData.RecoveryPopUp.Description}
            </p>
            <form onSubmit={handleSubmit}>
              <Group>
                <Label htmlFor="email">
                  {pageData.RecoveryPopUp.Inputs[0].Label}
                </Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  required=""
                  placeholder={pageData.RecoveryPopUp.Inputs[0].Placeholder}
                />
              </Group>
              <div className="mb-0 text-center d-grid">
                <button className="btn btn-primary" type="submit">
                  <i className="mdi mdi-lock-reset"></i>{" "}
                  {pageData.RecoveryPopUp.Confirm}
                </button>
              </div>
            </form>

            <footer className="footer footer-alt">
              <p className="text-muted">
                <Link to="/signin" className="text-muted ms-1">
                  <b>{pageData.RecoveryPopUp.Cancel}</b>
                </Link>
              </p>
            </footer>
          </div>
        </div>
      </div>

      <div className="auth-fluid-right text-center">
        <div className="auth-user-testimonial">
          <h2 className="mb-3">{pageData.TitleImage}</h2>
          <p>{pageData.TextImage}</p>
        </div>
      </div>
    </div>
  );
}
