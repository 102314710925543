import makeRequest from "../../dataFetching"
import { API_SIGN_IN } from "../../../utils/constants";
import { findSignInMapping } from "../../dataMapping/signIn";
/**
 * FIND
 * Fetches all SignIn entries.
 * @returns {Promise<Object>} A promise that resolves to the response data from the GET request.
 */
export function findSingInPage () {
  return makeRequest(API_SIGN_IN)
  .then(findSignInMapping);
}

/**
 * CREATE LOCALIZATION
 * Creates a new SignIn localization entry.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function createLocalizationSignIn ({}) {}


/**
 * PUT
 * Updates an existing SignIn entry.
 * @param {integer} id - The ID of the SignIn entry to update.
 * @returns {Promise<Object>} A promise that resolves to the response data from the PUT request.
 */
export function updateSignIn (id, {}) {}


/**
 * DELETE
 * Deletes a specific SignIn entry by ID.
 * @param {integer} id - The ID of the SignIn entry to delete.
 * @returns {Promise<Object>} A promise that resolves to the response data from the DELETE request.
 */
export function deleteSignIn ({ id }) {}
