import makeRequest from "../../dataFetching"
import { API_FOOTER } from "../../../utils/constants";
import { findFooterMapping } from "../../dataMapping/footer";
/**
 * FIND
 * Fetches all Footer entries.
 * @returns {Promise<Object>} A promise that resolves to the response data from the GET request.
 */
export function findFooter () {
  return makeRequest(API_FOOTER)
  .then(findFooterMapping);
}

/**
 * CREATE LOCALIZATION
 * Creates a new Footer localization entry.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function createLocalizationFooter ({}) {}


/**
 * PUT
 * Updates an existing Footer entry.
 * @param {integer} id - The ID of the Footer entry to update.
 * @returns {Promise<Object>} A promise that resolves to the response data from the PUT request.
 */
export function updateFooter (id, {}) {}


/**
 * DELETE
 * Deletes a specific Footer entry by ID.
 * @param {integer} id - The ID of the Footer entry to delete.
 * @returns {Promise<Object>} A promise that resolves to the response data from the DELETE request.
 */
export function deleteFooter ({ id }) {}
