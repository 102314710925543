import "../../styles/components/loading.scss"

const Loading = () => {
  return (
    <div id="loading-component">
      <div className="loader">
        <span className="loader-block"></span>
        <span className="loader-block"></span>
        <span className="loader-block"></span>
        <span className="loader-block"></span>
        <span className="loader-block"></span>
        <span className="loader-block"></span>
        <span className="loader-block"></span>
        <span className="loader-block"></span>
        <span className="loader-block"></span>
      </div>
    </div>
  );
}

export default Loading;