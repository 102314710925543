export function Hero({ title, subtitle, image, children }) {
  return (
    <header className="hero-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-5">
            <div className="mt-md-4">
              <h2 className="text-white fw-normal mb-4 mt-3 hero-title">
                {title}
              </h2>

              <p className="mb-4 font-16 text-white-50">
                {subtitle}
              </p>

              {children}
            </div>
          </div>
          <div className="col-md-5 offset-md-2">
            <div className="text-md-end mt-3 mt-md-0 d-flex justify-content-center">
              <img src="https://picsum.photos/500/350" alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}