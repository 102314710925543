import { LOCAL } from "../constant";

export const tiposDinamicosQR = ["Vcard", "Negócio", "Imagens", "Lista de Links", "Menu", "Páginas Web", "MP3", "Cupon", "Apps", "Wifi", "Comentários", "Página de Destino", "Vídeo", "PDF", "Lista de Reprodução", "Texto"]
export const tiposEstaticosQR = ["Páginas Web", "Texto", "Wifi"];

export function getScansData(qrCodes) {
  const scansData = [];

  qrCodes?.forEach((qrCode) => {
    const qrCodeScans = qrCode.scans.data;

    qrCodeScans.forEach((scan) => {
      scansData.push(scan);
    });
  });

  const AllScansnew = {
    scans: {
      data: scansData,
    },
  };

  return AllScansnew;
}

export function getScansDataSingle(qrCode) {
  if (!qrCode) return;

  const { scans } = qrCode;
  return { scans };
}
export function countUniqueScans(scans) {
  const uniqueScans = new Set();
  scans.forEach((scan) => {
    const { ip_address, city, country } = scan.attributes;
    const scanKey = `${ip_address}-${city}-${country}`;
    uniqueScans.add(scanKey);
  });
  return uniqueScans.size;
}

export function getQrCodeOptions(qrCodes = []) {
  return qrCodes.map((qrCode) => {
    const { name, static: isStatic } = qrCode;
    return { value: name, label: name, static: isStatic };
  });
}

export function getAllCountries(qrCodes) {
  const countries = new Set();

  qrCodes.forEach((qrCode) => {
    const scans = qrCode.scans.data;

    scans.forEach((s) => {
      const scan = s.attributes;
      const { country } = scan;
      if (country) {
        countries.add(country);
      }
    });
  });

  return Array.from(countries);
}

export function getAllCities(qrCodes) {
  const cities = new Set();

  qrCodes.forEach((qrCode) => {
    const scans = qrCode.scans.data;

    scans.forEach((s) => {
      const scan = s.attributes;
      const { city } = scan;
      if (city) {
        cities.add(city);
      }
    });
  });

  return Array.from(cities);
}

export function getAllOperatingSystems(qrCodes) {
  const operatingSystems = new Set();

  qrCodes.forEach((qrCode) => {
    const scans = qrCode.scans.data;

    scans.forEach((s) => {
      const scan = s.attributes;
      const os = scan.operatingSystem;
      if (os) {
        operatingSystems.add(os);
      }
    });
  });

  return Array.from(operatingSystems);
}

export function formatDateForInput(dateObj) {
  const year = dateObj.getFullYear();
  // Pad the month and day with leading zeros if necessary
  const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // January is 0!
  const day = String(dateObj.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function validateDateInterval(date1, date2) {
  const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
  const diffDays = Math.round(Math.abs((date1 - date2) / oneDay));

  if (date1.getDay() === date2.getDay()) {
    return "day";
  } else if (date1.getMonth() === date2.getMonth() || diffDays >= 31) {
    return "month";
  }
}

function isValidUrl(string) {
  var regex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
  return regex.test(string);
}

/**
 * Turns scans into an array of objects formatted to be used in a chart
 * @param {Object[]} allScans array of scan objects
 * @param {String[]} properties array of all properties that need to be read in each scan
 * @returns Example: [{name: "Windows", scans: 8}, {name: "Mac", scans: 5}, {name: "Linux", scans: 3}]
 */
export function generateChartDataFromProperties(allScans, properties) {
  // [{Windows: 8}, {Mac: 5}, {Linux: 3}]
  const propertyCounters = properties.map((property) => {
    const scanCountByProperty = {};
    allScans?.forEach((scan) => {
      const prop = scan.attributes[property];
      if (prop) {
        scanCountByProperty[prop] = (scanCountByProperty[prop] || 0) + 1;
      }
    });

    return scanCountByProperty;
  });

  // [{name: "Windows", scans: 8}, {name: "Mac", scans: 5}, {name: "Linux", scans: 3}]
  const formattedProperties = properties.map((property) => {
    const propertyCounter = propertyCounters[properties.indexOf(property)];
    const propertyArray = Object.keys(propertyCounter).map((key) => {
      return { name: key, scans: propertyCounter[key] };
    });

    return propertyArray;
  });

  return formattedProperties;
}

/**
 * Validates all the fields in a qrCode object
 * @param {Object} qrCode data of a given qr code
 * @param {Object} wifi wifi settings of a wifi qr code
 * @param {Object} files files that qr code might need (images, videos, pdfs, mp3s, etc...)
 * @param {Object} linkList if a qr code is a link tree, it will need links
 * @param {ArrayObject} descriptionErrors Array with descriptions of all errors
 * @returns if the qrCode object is valid according to its type
//  */

export function handleFieldValidation(
  qrCode,
  wifi,
  useUrl,
  generateCustomUrl,
  files,
  linkList,
  descriptionErrors
) {
  const errors = {};
  
  const typesWithoutUrlValidation = [
    "Imagens",
    "Lista de Links",
    "MP3",
    "Wifi",
    "Comentários",
    "PDF",
    "Texto",
  ];

  const requiresUrlValidation = !typesWithoutUrlValidation.includes(
    qrCode.type
  );

  const errorDictionary = descriptionErrors.reduce((acc, error) => {
    acc[error.Name] = error.Message;
    return acc;
  }, {});

  if (!qrCode.name) {
    errors.name = errorDictionary['nameRequired'] || '';
  }
  if (!qrCode.startDate) {
    errors.startDate = errorDictionary['startDateRequired'] || '';
  }
  if (!qrCode.endDate) {
    errors.endDate = errorDictionary['endDataRequired'] || '';
  }
  if (!qrCode.description) {
    errors.description = errorDictionary['descriptionRequired'] || '';
  }
  if (
    qrCode.type === "Imagens" ||
    qrCode.type === "MP3" ||
    qrCode.type === "PDF"
  ) {
    if (!files) {
      switch (qrCode.type) {
        case "Imagens":
          errors.files = errorDictionary['imageFileRequired'] || '';
          break;
        case "MP3":
          errors.files = errorDictionary['mp3FileRequired'] || '';
          break;
        case "PDF":
          errors.files = errorDictionary['pdfFileRequired'] || '';
          break;
        default:
          errors.files = errorDictionary['fileRequired'] || '';
          break;
      }
    }
  }
  if (
    (qrCode.type === "Texto" || qrCode.type === "Comentários") &&
    !qrCode.text
  ) {
    errors.text =
      qrCode.type === "Comentários"
        ? errorDictionary['commentRequired'] || ''
        : errorDictionary['textRequired'] || '';
  }
  if (qrCode.type === "Cupon" && !useUrl) {
    if (!files) {
      errors.files = errorDictionary['imageFileRequired'] || '';
    }
  }
  if (qrCode.type === "Vídeo" && !useUrl) {
    if (!files) {
      errors.files = errorDictionary['videoRequired'] || '';
    }
  }
  if (qrCode.type === "Menu" && !useUrl) {
    if (!files) {
      errors.files = errorDictionary['imageFileRequired'] || '';
    }
  }

  if (requiresUrlValidation && !qrCode.url && useUrl) {
    errors.url = errorDictionary['urlRequired'] || '';
  }

  if (generateCustomUrl && !qrCode.identifier) {
    errors.urlConfig = errorDictionary['urlConfigRequired'] || '';
  }
  if (requiresUrlValidation && qrCode.url && !isValidUrl(qrCode.url)) {
    errors.url = errorDictionary['invalidUrl'] || '';
  }

  if (qrCode.identifier && !isValidUrl(qrCode.identifier)) {
    errors.identifier = errorDictionary['invalidUrlId'] || '';
  }

  if (qrCode.type === "Wifi") {
    if (!wifi.wifiName) {
      errors.wifiName = errorDictionary['wifiNameRequired'] || '';
    }
    if (!wifi.wifiIncription) {
      errors.wifiIncription = errorDictionary['wifiEncrypRequired'] || '';
    }
    if (!wifi.wifiPassword) {
      errors.wifiPassword = errorDictionary['wifiPasswordRequired'] || '';
    }
  }

  if (qrCode.type === "Lista de Links" && linkList.length <= 0) {
    errors.linkList = errorDictionary['linkListRequired'] || '';
  }

  return errors;
}

/**
 * Parses the url of a wifi connection
 * @param {String} url the url stored in a wifi qr code
 * @returns object with all the wifi properties
 */
export function parseWifiUrl(url) {
  if (!url) return;

  if (typeof url !== "string") return;

  return {
    wifiIncription: url.split(":")[2].split(";")[0],
    wifiName: url.split(":")[3].split(";")[0],
    wifiPassword: url.split(":")[4].split(";")[0],
    isHidden: url.split(":")[5].split(";")[0] === "false" ? false : true,
  };
}

/**
 * Converts a wifi object and converts it into a url string
 * @param {Object} wifi object with all the wifi properties
 * @returns url in a string format ready to use for a wifi qr code
 */
export function stringifyWifiUrl(wifi) {
  if (!wifi) return "";

  return `WIFI:T:${wifi.wifiIncription};S:${wifi.wifiName};P:${wifi.wifiPassword};H:${wifi.isHidden}`;
}

/**
 * Receives an image url or a file image and returns a valid url that can be used in an img tag
 * @param {string | Object | File} image
 * @returns a valid url image for an img element
 */
export function formatImage(image) {
  if (!image) return "";

  const { url, data } = image;

  if (typeof url === "string") return url;

  if (typeof data?.url === "string") return LOCAL + data.url;

  if (typeof data?.attributes?.url === "string") return LOCAL + data?.attributes?.url;

  if (typeof image === "string") return image;

  try {
    return URL.createObjectURL(image);
  } catch (error) {
    return "";
  }
}

export const validateCurrentPassword = (value, errorMessage) => {
  if (!value.trim()) return errorMessage;
  return "";
};

export const validatePassword = (value, errorMessage) => {
  if (!value.trim()) return errorMessage;
  return "";
};

export const validatePasswordConfirmation = (value, password, errorMessage) => {
  value = value || "";
  password = password || "";
  if (value.trim() !== password.trim()) return errorMessage;
  return "";
};