import React, { useRef, useState } from "react";
import { ReactSVG } from "react-svg";

import { LOCAL } from "../../constant";
import { formatImage } from "../../utils";
import { Group, Input, Label } from "../Form";

export default function QRStyleEditor({ qrCode, setQRCode, handleValueChange, pageData }) {
  const [hoveredStyle, setHoveredStyle] = useState(null);

  const qrStyles = pageData.qr_styles.data;
  
  const qrCornerStyles = pageData.qr_corner_styles.data;
  const qrStyleElements = qrStyles.map((s) => {
    const style = s.attributes;

    return (
      <div key={"style-" + style.Name} className="styleQr">
        <label
          className="createQR--style"
          onMouseOver={() => setHoveredStyle(style)}
          onMouseOut={() => setHoveredStyle(null)}
        >
          <input
            onChange={handleValueChange}
            type="radio"
            name="dotFormat"
            value={style.Style}
            className="input-radio-img"
          />
          <ReactSVG
            src={LOCAL + style.Active.data.attributes.url}
            alt=""
            className={
              (qrCode.dotFormat === style.Style ||
              (hoveredStyle && hoveredStyle.Name === style.Name)
                ? "active radio-img"
                : "radio-img") +
              (qrCode.dotFormat === style.Style ? " active-current" : "")
            }
          />
        </label>
      </div>
    );
  });

  const qrCornerStyleElements = qrCornerStyles.map((s) => {
    const style = s.attributes;

    return (
      <div className="styleQr" key={"corner-" + style.Name}>
        <label
          className="createQR--style"
          onMouseOver={() => setHoveredStyle(style)}
          onMouseOut={() => setHoveredStyle(null)}
        >
          <input
            onChange={() =>
              setQRCode({
                ...qrCode,
                cornerDotFormat: style.Dot,
                cornerSquareFormat: style.Square,
              })
            }
            type="radio"
            name="cornerFormat"
            value={style}
            className="input-radio-img"
          />
          <ReactSVG
            src={LOCAL + style.Active.data.attributes.url}
            alt=""
            fill="red"
            className={
              ((qrCode.cornerDotFormat === style.Dot &&
                qrCode.cornerSquareFormat === style.Square) ||
              (hoveredStyle && hoveredStyle.Name === style.Name)
                ? "active radio-img"
                : "radio-img") +
              (qrCode.cornerDotFormat === style.Dot &&
              qrCode.cornerSquareFormat === style.Square
                ? " active-current"
                : "")
            }
          />
        </label>
      </div>
    );
  });

  const handleImageRemove = (e) => {
    if (qrCode.image) {
      setQRCode((old) => {
        return {
          ...old,
          image: null,
        };
      });
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setQRCode((old) => {
            return {
              ...old,
              image: e.target.files[0],
            };
          });
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const LinkImageInputRef = useRef(null);
  const handleLinkImageUploadClick = () => {
    LinkImageInputRef.current.click();
  };

  return (
    <div className="createQR--form">
      <div className="row">
        <div className="col-lg-7 col-sm-12">
          <Group className="parent-style">
            <Label>{pageData.QRCodeStyle}</Label>
            <div className="container-style">{qrStyleElements}</div>
          </Group>
        </div>
        <div className="col-lg mt-4 col-sm-6">
          <div className="row g-2">
            <Group>
              <Label>{pageData.MarginColor}</Label>
              <div className="">
                <Input
                  disabled={false}
                  name="dotColor"
                  type="color"
                  onChange={handleValueChange}
                  value={qrCode.dotColor || "#000000"}
                />
              </div>
            </Group>
          </div>
        </div>
        <div className="col-lg mt-4 col-sm-6">
          <div className="row g-2">
            <Group>
              <Label>{pageData.BackgroundColor}</Label>
              <div className="">
                <Input
                  disabled={false}
                  name="backgroundColor"
                  type="color"
                  onChange={handleValueChange}
                  value={qrCode.backgroundColor || "#000000"}
                />
              </div>
            </Group>
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-lg-7 col-sm-12">
          <Group className="parent-style">
            <Label>{pageData.Corners}</Label>
            <div className="container-style"> {qrCornerStyleElements}</div>
          </Group>
        </div>
        <div className="col-lg mt-4 col-sm-6">
          <div className="row g-2">
            <Group>
              <Label>{pageData.MarginColor}</Label>
              <div className="">
                <Input
                  name="cornerDotColor"
                  type="color"
                  onChange={handleValueChange}
                  value={qrCode.cornerDotColor || "#000000"}
                />
              </div>
            </Group>
          </div>
        </div>
        <div className="col-lg mt-4 col-sm-6">
          <div className="row g-2">
            <Group>
              <Label>{pageData.BackgroundColor}</Label>
              <div className="">
                <Input
                  name="cornerSquareColor"
                  type="color"
                  onChange={handleValueChange}
                  value={qrCode.cornerSquareColor || "#000000"}
                />
              </div>
            </Group>
          </div>
        </div>
      </div>
      <hr />
      <Group>
        <Label> {pageData.AddLogo}</Label>
        <div>
          {qrCode.image ? (
            <>
              <div
                className="avatar-xl mb-2"
                onClick={handleLinkImageUploadClick}
              >
                <div className="image-edit-container avatar-title bg-light rounded">
                  <img
                    src={formatImage(qrCode?.image)}
                    alt="Preview"
                    className="image-preview"
                  />
                  <div className="edit-icon-overlay rounded">
                    <i className="mdi mdi-image-edit-outline h1 text-primary"></i>
                  </div>
                </div>
              </div>
              <button
                className="btn btn-outline-secondary float-start"
                onClick={handleImageRemove}
              >
                <i className="mdi mdi-delete me-2"></i>
                {pageData.RemoveLogotipo}
              </button>
            </>
          ) : (
            <div
              className="avatar-xl mb-2 preview-empty"
              onClick={handleLinkImageUploadClick}
            >
              <span className="avatar-title bg-light rounded">
                <div className="text-center">
                  <i className="mdi mdi-image text-primary h1"></i>
                  <p className="mb-0">
                    <small className="mb-0 text-primary p-1">
                      {pageData.UploadLogo}
                    </small>
                  </p>
                </div>
              </span>
            </div>
          )}
        </div>
        <input
          type="file"
          id="fileInput"
          ref={LinkImageInputRef}
          style={{ display: "none" }}
          accept={".png,.jpg"}
          onChange={handleImageChange}
        />
      </Group>
    </div>
  );
}
