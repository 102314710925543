//export const BASE = "http://localhost:1337";
export const BASE = "https://strapi.yrqr.pt";
export const API = BASE + "/api";

// ENDPOINTS

// PAGES
export const API_HOME = API + "/home";
export const API_STATISTICS = API + "/statistics-page";
export const API_QR_LINK_TREE_REDIRECT_PAGE =
  API + "/qr-link-tree-redirect-page";
export const API_PLANS_AND_PRICING = API + "/plans-and-pricing";
export const API_FAQ = API + "/faq";
export const API_SUPPORT = API + "/support";
export const API_CONTACT_US = API + "/contact-us";
export const API_CONTACT = API + "/contact";
export const API_SIGN_IN = API + "/sign-in";
export const API_SIGN_UP = API + "/sign-up";
export const API_FORGOT_PASSWORD = API + "/forgot-password";
export const API_NOT_FOUND = API + "/not-found404";
export const API_SETTINGS_PAGE = API + "/settings-page";
export const API_CREATE_QR_PAGE = API + "/create-qr-page";
export const API_MY_QR_CODES_PAGE = API + "/my-qr-codes-page";
export const API_PROFILE_PAGE = API + "/profile-page";
export const API_QR_CODE_DETAILS_PAGE = API + "/qr-code-details-page";
export const API_QR_INACTIVE = API + "/qr-inactive";
export const API_USERS_PAGE = API + "/users-page";
export const API_PAYMENTS_PAGE = API + "/payments-page";
export const API_PAYMENTS_PLAN_PAGE = API + "/payments-plan-page";
export const API_CHECKOUT_PAGE = API + "/checkout-page";
export const API_SUPPORT_CATEGORIES = API + "/support-categories";
export const API_FOOTER = API + "/footer";

// INFO_MENU
export const INFO_MENU = API + "/info-menu";

// CONTACT
export const API_CONTACT_FORM = API + "/contacts";

// MENU
export const API_MENU = API + "/menu";
export const API_INFO_MENU = API + "/info-menu";

// UPLOAD
export const API_UPLOAD = API + "/upload";
export const API_UPLOAD_FILES = API_UPLOAD + "/files";

// LOTTIES
export const API_LOTTIE_CONTACT = API + "/lotties/contact";
export const API_LOTTIE_PLANS_AND_PRICING = API + "/lotties/plans_and_pricing";
export const API_LOTTIE_QR_INACTIVE = API + "/lotties/qr_inactive";
export const API_LOTTIE_NOT_FOUND = API + "/lotties/not_found";

// AUTH
export const API_AUTH = API + "/auth";
export const API_AUTH_LOCAL = API_AUTH + "/local";
export const API_AUTH_REGISTER = API_AUTH_LOCAL + "/register";
export const API_AUTH_UPDATE_PASSWORD =
  API_AUTH + "/change-password?populate[avatar]=*";
export const API_AUTH_RECOVERY_PASSWORD = API_AUTH + "/forgot-password";

// USERS
export const API_USERS = API + "/users";
export const API_USERS_CURRENT = API_USERS + "/me?populate=*";

// USERS_PERMISSIONS
export const API_USERS_PERMISSIONS = API + "/users-permissions";
export const API_USERS_PERMISSIONS_CURRENT = API_USERS_PERMISSIONS + "/me";
export const API_USERS_PERMISSIONS_EMAIL = API_USERS_PERMISSIONS + "/email";

// QR_CODES
export const API_QRCODES = API + "/qr-codes";
export const API_QRCODES_FROM_USER =
  API_QRCODES + "?populate[scans]=*&populate[image][fields][0]=url";
export const API_QR_CODES_SCANS = API_QRCODES + "?populate[scans]=*";
export const API_QR_CODES_IDENTIFIER_CHECK = API_QRCODES + "?identifier=";
export const API_QRCODES_IDENTIFIER = API_QRCODES + "/identifier";
export const API_QRCODES_USER = API_QRCODES + "/users";
export const API_QRCODES_IDENTIFIER_CHECK = API_QRCODES_IDENTIFIER + "/check";

// QR_CODES_LINKTREES
export const API_QR_CODE_LINKTREES = API + "/qr-code-linktrees";

//SCANS
export const API_SCANS = API + "/scans";

// TOAST_MESSAGES
export const API_TOAST_MESSAGES = API + "/toast-message";

// USER ROLES
export const ROLES = {
  ADMIN: "ADMIN",
  COLLABORATOR: "COLLABORATOR",
};

// USER STATES
export const STATES = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};

// CONFIG
export const AUTH_TOKEN = "authToken";

// TOKEN TEST
export const TOKEN_TEST =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAwMiwiaWF0IjoxNzIxOTc1OTExLCJleHAiOjE3MjQ1Njc5MTF9.OF2kvOX6jaHQXRoyJSanWd2wIvMg3Ka6yEDc_oz5HvM";
