import { AUTH_TOKEN } from "../../utils/constants";

/**
 * Retrieves the authentication token from local storage
 * @returns {String} The authentication token if it exists, otherwise an empty string
 */
export const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN) ?? "";
};

/**
 * Stores the provided authentication token in local storage
 * @param {String} token - The authentication token to be stored
 * @returns {void}
 */
export const setToken = (token) => {
  if (token) localStorage.setItem(AUTH_TOKEN, token);
};

/**
 * Removes the authentication token from local storage
 * @returns {void}
 */
export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
};