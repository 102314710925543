import makeRequest from "../../dataFetching"
import { API_CONTACT_FORM } from "../../../utils/constants";
import { contactMapping } from "../../dataMapping/contact";

/**
 * FIND
 * Fetches all contact entries.
 * @returns {Promise<Object>} A promise that resolves to the response data from the GET request.
 */
export function findContact() {}

/**
 * FIND ONE
 * Fetches a specific contact entry by ID.
 * @param {integer} id - The ID of the contact entry to fetch.
 * @returns {Promise<Object>} A promise that resolves to the response data from the GET request.
 */
export function findOneContact (id) {}

/**
 * CREATE
 * Creates a new contact entry.
 * @param {Object} contact - The contact details.
 * @param {string} contact.email - The email of the contact.
 * @param {string} contact.name - The name of the contact.
 * @param {string} contact.subject - The subject of the contact.
 * @param {string} contact.message - The message of the contact.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function createContact ({ email, name, subject, message }) {
  return makeRequest(API_CONTACT_FORM, {
    method: "POST",
    body: { data: {
      email,
      name,
      subject,
      message
    }}
  })
  .then(contactMapping);
}


/**
 * PUT
 * Updates an existing contact entry.
 * @param {integer} id - The ID of the contact entry to update.
 * @param {Object} contact - The updated contact details.
 * @param {string} contact.email - The updated email of the contact.
 * @param {string} contact.name - The updated name of the contact.
 * @param {string} contact.subject - The updated subject of the contact.
 * @param {string} contact.message - The updated message of the contact.
 * @returns {Promise<Object>} A promise that resolves to the response data from the PUT request.
 */
export function updateContact (id, { email, name, subject, message }) {}


/**
 * DELETE
 * Deletes a specific contact entry by ID.
 * @param {integer} id - The ID of the contact entry to delete.
 * @returns {Promise<Object>} A promise that resolves to the response data from the DELETE request.
 */
export function deleteContact ({ id }) {
  return makeRequest(`${API_CONTACT_FORM}/${id}`, {
    method: "DELETE"
  })
  .then(contactMapping);
}