import React, { forwardRef } from 'react';
import { useElements } from '@stripe/react-stripe-js';

// Components Imports
import InvoiceForm from "../../../components/InvoiceForm";

// Hooks Imports
import { useAuthContext } from "../../../context/AuthContext";

// Utils Imports
import { getUser, updateMe } from "../../../services/apiCalls/db";
import { toast } from '../../../utils';

const StripeElements = forwardRef(({ pageData, data, setData, isEditingInfo, setIsEditingInfo }, ref) => {
  const { user, setUser } = useAuthContext();
  
  const elements = useElements();

  const handleInfoUpdate = async (event) => {
    event.preventDefault();

    const addressElement = elements.getElement("address");
    const { complete } = await addressElement.getValue();

    if (!complete) return;

    const finalData = { ...user, ...data };
    
    updateMe(finalData).
      then(() => {
        getUser()
          .then(data => {
            setUser(data);
            setIsEditingInfo(false)
          });
        toast(pageData.updatedInformation);
      })
      .catch(err => {
        toast(pageData[err])
      })
  };

  return (
    <InvoiceForm
      pageData={pageData}
      invoiceDetails={data}
      setInvoiceDetails={setData}
      onSubmit={handleInfoUpdate}
      disabled={!isEditingInfo}
      hideSubmitButton={true}
      ref={ref}
    />
  )
});

export { StripeElements };

