import { useState } from "react";
import { internalErrors } from "../utils/error";
import { toast } from "../services/toast";
import { createPaymentIntent as createPaymentInt, cancelSubscription, getCurrentPlan, getInvoices } from "../services/apiCalls/db";
import { ToastType } from "../services/toast/ToastType";

export const STEPS = {
  INVOICE: "INVOICE",
  PAYMENT: "PAYMENT",
};

const INITIAL_PAYMENT_STATE = {
  clientSecret: "",
  paymentIntentId: "",
  price: {
    totalExcludingTax: 0,
    tax: 0,
    discount: 0,
    total: 0,
  }
};

export default function usePayment () {
  const [subscriptionId, setSubscriptionId] = useState("");
  const [paymentInformation, setPaymentInformation] = useState(INITIAL_PAYMENT_STATE);
  const [isPending, setIsPending] = useState(false);
  const [isError, setIsError] = useState(false);
  const [currentPlan, setCurrentPlan] = useState({ name: "", isContract: false });
  const [invoices, setInvoices] = useState([]);

  const createPayment = async ({ currentPlan }) => {
    try {
      const res = await createPaymentInt(currentPlan.id)

      const { subscriptionId, paymentInformation } = res;

      setPaymentInformation(paymentInformation);
      setSubscriptionId(subscriptionId);

      return true;
    } catch (err) {
      if(!internalErrors(err)) {
        if (err === "You can't downgrade your plan") {
          toast({
            actionName: ToastType.CREATE_PAYMENT_INTENT.ACTION_NAME,
            actionMessage: ToastType.CREATE_PAYMENT_INTENT.MESSAGES.CANT_DOWNGRADE_PLAN
          });
        } else {
          toast({
            actionName: ToastType.CREATE_PAYMENT_INTENT.ACTION_NAME,
            actionMessage: ToastType.CREATE_PAYMENT_INTENT.MESSAGES.SOMETHING_WENT_WRONG
          })
        }
      }
      return false;
    }
  };

  const doGetInvoice = () => {
    setIsPending(true);
    setIsError(false);

    getInvoices()
      .then((res) => {
        setInvoices(res);
      })
      .catch(err => {
        setIsError(true);
        if (!internalErrors(err)) toast({
          actionName: ToastType.GET_INVOICE.ACTION_NAME,
          actionMessage: ToastType.GET_INVOICE.MESSAGES.ERROR_GETTING_INVOICES
        });
      })
      .finally(() => setIsPending(false));;
  }

  const doGetCurrentPlan = () => {
    setIsPending(true);
    setIsError(false);

    getCurrentPlan()
      .then((res) => {
        setCurrentPlan(res);
      })
      .catch(err => {
        setIsError(true);
        if (!internalErrors(err)) toast({
          actionName: ToastType.GET_CURRENT_PLAN.ACTION_NAME,
          actionMessage: ToastType.GET_CURRENT_PLAN.MESSAGES.ERROR_GETTING_CURRENT_PLAN
        });
      })
      .finally(() => setIsPending(false));;
  }

  const doCancelSubscription = () => {
    setIsPending(true);
    setIsError(false);

    cancelSubscription()
      .then((res) => {
        if(res.message === "Subscription canceled") {
          toast({
            actionName: ToastType.CANCEL_SUBSCRIPTION.ACTION_NAME,
            actionMessage: ToastType.CANCEL_SUBSCRIPTION.MESSAGES.SUBSCRIPTION_CANCELED
          });
        } else if (res.message === "Subscription cancelation scheduled") {
          toast({
            actionName: ToastType.CANCEL_SUBSCRIPTION.ACTION_NAME,
            actionMessage: ToastType.CANCEL_SUBSCRIPTION.MESSAGES.SUBSCRIPTION_CANCELATION_SCHEDULED
          });
        }
      })
      .catch((err) => {
        setIsError(true);
        if (!internalErrors(err)) {
          if (err === "No active subscription found") {
            toast({
              actionName: ToastType.CANCEL_SUBSCRIPTION.ACTION_NAME,
              actionMessage: ToastType.CANCEL_SUBSCRIPTION.MESSAGES.NO_ACTIVE_SUBSCRIPTION
            });
          } else if (err === "Subscription already canceled") {
            toast({
              actionName: ToastType.CANCEL_SUBSCRIPTION.ACTION_NAME,
              actionMessage: ToastType.CANCEL_SUBSCRIPTION.MESSAGES.SUBSCRIPTION_ALREADY_CANCELED
            });
          } else if (err === "Subscription already scheduled for cancelation") {
            toast({
              actionName: ToastType.CANCEL_SUBSCRIPTION.ACTION_NAME,
              actionMessage: ToastType.CANCEL_SUBSCRIPTION.MESSAGES.SUBSCRIPTION_ALREADY_SCHEDULE_FOR_CANCELATION
            });
          } else {
            toast({
              actionName: ToastType.CANCEL_SUBSCRIPTION.ACTION_NAME,
              actionMessage: ToastType.CANCEL_SUBSCRIPTION.MESSAGES.UNEXPECTED_ERROR
            });
          }
        }
      })
      .finally(() => setIsPending(false));
  }

  return { doGetInvoice, doGetCurrentPlan, doCancelSubscription, createPayment, paymentInformation, subscriptionId, invoices, currentPlan, isPending, isError };
}
