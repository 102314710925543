import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { login, register, updatePassword, recoveryPassword, updatePasswordToken } from "../../services/apiCalls/auth";
import { setToken } from "../../helpers";
import { toast } from "../../services/toast";
import { ToastType } from "../../services/toast/ToastType";
import { internalErrors } from "../../utils/error";


export default function useAuth () {
  const navigate = useNavigate();
  const { setUser } = useAuthContext();
  const [isPending, setIsPending] = useState(false);
  const [isError, setIsError] = useState(false);

  const doLogin = ({ identifier, password }) => {
    setIsPending(true);
    setIsError(false);
    
    login({ identifier, password })
      .then((data) => {
        toast({
          actionName: ToastType.LOGIN.ACTION_NAME,
          actionMessage: ToastType.LOGIN.MESSAGES.SUCCESSFULLY_LOGGED_IN
        });
        setToken(data.jwt);
        setUser(data.user);
        navigate("/Statistics");
      })
      .catch((err) => {
        setIsError(true);
        if(!internalErrors(err)) toast({
          actionName: ToastType.LOGIN.ACTION_NAME,
          actionMessage: ToastType.LOGIN.MESSAGES.WRONG_CREDENTIALS
        });
      })
      .finally(() => setIsPending(false));
  };
  
  const doRegister = ({ username, email, password }) => {
    setIsPending(true);
    setIsError(false);
  
    register({ username, email, password, role: 1 })
        .then((data) => {
          toast({
            actionName: ToastType.REGISTER.ACTION_NAME,
            actionMessage: ToastType.REGISTER.MESSAGES.ACCOUNT_CREATED_SUCCESSFULLY
          });
          setToken(data.jwt);
          setUser(data.user);
          navigate("/profile");
        })
        .catch((err) => {
          setIsError(true);
          if(!internalErrors(err)) toast({
            actionName: ToastType.REGISTER.ACTION_NAME,
            actionMessage: ToastType.REGISTER.MESSAGES.ERROR_CREATING_ACCOUNT
          });
        })
        .finally(() => setIsPending(false));
  };

  const doUpdatePassword = ({ currentPassword, password, passwordConfirmation }) => {
    setIsPending(true);
    setIsError(false);

    updatePassword({ currentPassword, password, passwordConfirmation })
      .then(data => {
        if (data.user) {
          toast({
            actionName: ToastType.UPDATE_PASSWORD.ACTION_NAME,
            actionMessage: ToastType.UPDATE_PASSWORD.MESSAGES.PASSWORD_UPDATED_SUCCESSFULLY
          });
          setUser((prev) => {
            return {
              ...prev,
              ...data.user,
            };
          });
        }
      })
      .catch(err => {
        setIsError(true);
        if(!internalErrors(err)) {
          if (err.message.includes("at least")) {
            toast({
              actionName: ToastType.UPDATE_PASSWORD.ACTION_NAME,
              actionMessage: ToastType.UPDATE_PASSWORD.MESSAGES.PASSWORD_TOO_SMALL
            });
          } else {
            toast({
              actionName: ToastType.UPDATE_PASSWORD.ACTION_NAME,
              actionMessage: ToastType.UPDATE_PASSWORD.MESSAGES.FAILED_TO_UPDATE_PASSWORD
            });
          }
        } 
      })
      .finally(() => setIsPending(false));
  };

  const doRecovery = ({ email }) => {
    setIsPending(true);
    setIsError(false);

    recoveryPassword({ email })
    .then((res) => {
      if(res.ok) {
        toast({
          actionName: ToastType.RECOVERY_PASSWORD.ACTION_NAME,
          actionMessage: ToastType.RECOVERY_PASSWORD.MESSAGES.PASSWORD_RECOVERY_SENT
        });
      } else {
        toast({
          actionName: ToastType.RECOVERY_PASSWORD.ACTION_NAME,
          actionMessage: ToastType.RECOVERY_PASSWORD.MESSAGES.ERROR_TRY_AGAIN_LATER
        });
      }
    })
    .catch((err) => {
      setIsError(true);
      if (!internalErrors(err)) toast({
        actionName: ToastType.RECOVERY_PASSWORD.ACTION_NAME,
        actionMessage: ToastType.RECOVERY_PASSWORD.MESSAGES.ERROR_TRY_AGAIN_LATER
      });
    })
    .finally(() => setIsPending(false));
  }

  const doUpdatePasswordToken = ({ password, passwordConfirmation, token }) => {
    setIsPending(true);
    setIsError(false);

    updatePasswordToken({ password, passwordConfirmation, token })
      .then(res => {
        const { jwt, user } = res;
        setToken(jwt);
        setUser(user);
        navigate("/", { replace: true });
      })
      .catch((err) => {
        setIsError(true);
        if (!internalErrors(err)) toast({
          actionName: ToastType.FORGOT_PASSWORD.ACTION_NAME,
          actionMessage: ToastType.FORGOT_PASSWORD.MESSAGES.ERROR_OCURRED
        })
      })
      .finally(() => setIsPending(false));
  }

  return { doLogin, doRegister, doUpdatePassword, doRecovery, doUpdatePasswordToken, isPending, isError };
}