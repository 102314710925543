import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useHistory } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { LOCAL } from "../../constant";
import { stringifyWifiUrl } from "../../utils";
import "../../styles/pages/CreateQR.css";

import { Input, Group, Label, TextArea } from "../../components/Form";
import QRPreview from "../../components/QRPreview";
import QrCodeInputSwitch from "../../components/QrCodeInputSwitch";
import QRStyleEditor from "../../components/QRStyleEditor";
import { PageTypes } from "../../hooks/pageData/PageTypes";
import usePagesData from "../../hooks/pageData/usePagesData";

const downloadModalId = "downloadModal";
const InputComponent = [
  "Página de Destino",
  "Páginas Web",
  "Wifi",
  "Texto",
  "Comentários",
];
const INITIAL_QR_CODE = {
  name: "",
  description: "",
  dotColor: "",
  dotFormat: "square",
  backgroundColor: "#FFFFFF",
  cornerDotFormat: "square",
  cornerDotColor: "#000000",
  cornerSquareFormat: "square",
  cornerSquareColor: "#000000",
  url: "",
  text: "",
  identifier: "",
  static: false,
  type: "",
  startDate: "",
  endDate: "",
};

const INITIAL_WIFI = {
  wifiName: "",
  wifiPassword: "",
  wifiIncription: "",
  isHidden: false,
};

let qrCodeDimensions = { width: 180, height: 180 };

const QRCodeCreator = ({ content, img }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [qrCode, setQRCode] = useState(INITIAL_QR_CODE);
  const [wifi, setWifi] = useState(INITIAL_WIFI);
  const [linkList, setLinkList] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [files, setFiles] = useState("");
  const [urlPreference] = useState(true);
  const [errors, setErrors] = useState({});
  
  const navigate = useNavigate();
  const { user, isLoading: userIsLoading } = useAuthContext();
  
  const closeModalRef = useRef(null);
  
  useEffect(() => {
    if (!user) return;
    
    setQRCode((prev) => ({
      ...prev,
      approval: user?.needsApproval ? "Pending" : "Approved",
      users_permissions_user: user?.id,
    }));
  }, [user]);
  
  useEffect(() => {
    if (qrCode.type !== "Wifi") return;
    setQRCode((prev) => ({
      ...prev,
      url: stringifyWifiUrl(wifi),
    }));
  }, [wifi]);
  
  useEffect(() => {
    const typesWithFiles = [ "MP3", "PDF", "Imagens", "Vídeo", "Menu", "Cupon" ];
    
    if (typesWithFiles.includes(qrCode.type) && !urlPreference) {
      setQRCode((prev) => ({ ...prev, url: "", mediaField: files }));
    }
  }, [files, qrCode.type, urlPreference]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [])

  const isMobile = () => window.innerWidth <= 768;

  const closeModal = () => closeModalRef.current.click();

  const { pageData, isPending } = usePagesData({ pageType: PageTypes.CREATE_QR });
  
  if(windowWidth < 1200) {
    qrCodeDimensions = { width: 150, height: 150 };
  } else {
    qrCodeDimensions = { width: 180, height: 180 };
  }

  if (isPending) return;

  function handleValueChange(event) {
    const { name, value } = event.target;
    setQRCode((old) => ({
      ...old,
      [name]: value,
    }));
  }
  
  const handleStaticChange = (type) => {
    setQRCode({
      name: "",
      description: "",
      dotColor: "",
      dotFormat: "square",
      backgroundColor: "#FFFFFF",
      cornerDotFormat: "square",
      cornerDotColor: "#000000",
      cornerSquareFormat: "square",
      cornerSquareColor: "#000000",
      url: "",
      text: "",
      identifier: "",
      static: false,
      type: type,
      startDate: "",
      endDate: "",
    });
  };

  const getCurrentTypeContent = (type, dynamicTypes) => {
    const currentType = dynamicTypes.find((t) => t.attributes.Type === type);

    const content = {
      typeTitle: currentType?.attributes.Title || "",
      typeDescription: currentType?.attributes.Description || "",
      typePhoneImage: currentType?.attributes.PhoneImage.data.attributes.url || "",
    };

    return content;
  };

  const dynamicTypes = pageData.dynamic_qr_code_types.data;

  const dynamicTypeElements = dynamicTypes.map((type) => {
    const typeData = type.attributes;
    const isSelected = selectedType === typeData.Type ? "selected" : "";

    return (
      <div key={"type-" + typeData.Title} className={`col-lg-3 col-md-12`}>
        <div className="grid-container">
          <div
            className={`card hoverCard ${isSelected}`}
            key={"dynamic-" + typeData.Type}
            onClick={(e) => {
              handleStaticChange(typeData.Type);
              setSelectedType(typeData.Type);
              if (isMobile()) {
                setIsCollapsed(true);
                window.location.hash = "";
                setTimeout(() => {
                  window.location.hash = "content-Prev";
                }, 0);
              }
            }}
          >
            <div className="card-body p-2 d-flex justify-content-start align-items-center">
              <img
                className="icon small"
                src={LOCAL + typeData.Image.data.attributes.url}
                alt={typeData.Type}
                width={35}
                height={35}
              />
              <h5 className="card-title text-center mb-0 ms-2">{typeData.Title}</h5>
            </div>
          </div>
        </div>
      </div>
    );
  });

  const typeContent = getCurrentTypeContent(qrCode.type, dynamicTypes);

  const showAccordion = InputComponent.includes(qrCode.type);
  return (
    <>
      <div
        id={downloadModalId}
        className="modal fade"
        role="dialog"
        aria-labelledby="standard-modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content ">
            <div className="modal-header">
              <h4 className="modal-title" id="standard-modalLabel">
                {pageData.reservedAreaTitle}
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              ></button>
            </div>
            <div className="modal-body">
              {user && !userIsLoading ? (
                <>
                  <p className="card-text">
                    {qrCode.type !== "" && pageData.RestrictedAccessMessage} 
                    {pageData.authenticatedUserMessage}
                  </p>
                </>
              ) : (
                <>
                  <p className="card-text">
                    {qrCode.type !== "" && pageData.RestrictedAccessMessage}
                    {pageData.unauthenticatedUserMessage}
                  </p>
                </>
              )}
            </div>
            <div className="modal-footer m-auto border-0">
              <button
                ref={closeModalRef}
                type="button"
                className="btn btn-light d-none"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              {user && !userIsLoading ? (
                <>
                  <button
                    onClick={() => {
                      closeModal();
                      navigate("/create");
                    }}
                    className="btn btn-primary"
                  >
                    {pageData.btnCreateQrTitle}
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={() => {
                      closeModal();
                      navigate("/signin");
                    }}
                    className="btn btn-secondary me-2"
                  >
                    {pageData.btnSignInTitle}
                  </button>
                  <button
                    onClick={() => {
                      closeModal();
                      navigate("/signUp");
                    }}
                    className="btn btn-primary"
                  >
                    {pageData.btnSignUpTitle}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid" id="content-Prev">
        <div className="row">
          <div className="col-lg-3 d-flex justify-content-center sticky-preview">
            <div className="phone-showcase">
              <img src={img} alt="Phone" className="phone-image" />
              <div className="qr-preview-container">
                <QRPreview pageData={pageData} edit qrCode={qrCode} width={qrCodeDimensions.width} height={qrCodeDimensions.height} />
              </div>
              <div className="w-100">
                <button
                  className="btn btn-primary my-2 w-100"
                  data-bs-toggle="modal"
                  data-bs-target={"#" + downloadModalId}
                >
                  <i className="mdi mdi-download me-2"></i>
                  {content.DownloadButtonText}
                </button>
              </div>
            </div>
          </div>

          <div className="col-lg-9">
            <div className="row">
              <div>
                <div className="card mb-4">
                  <div className="card-header" id="headingFour">
                    <h5 className="m-0">
                      <a
                        className="custom-accordion-title d-block py-1"
                        data-bs-toggle="collapse"
                        href="#collapseFour"
                        aria-expanded="true"
                        aria-controls="collapseFour"
                        onClick={() => setIsCollapsed(false)}
                      >
                        {content.TextAcoordion.map((t) => t.Text)[0]}
                        <i className="mdi mdi-chevron-down accordion-arrow"></i>
                      </a>
                    </h5>
                  </div>

                  <div
                    id="collapseFour"
                    className={!isCollapsed ? "collapse show" : "collapse"}
                    aria-labelledby="headingFour"
                    data-bs-parent="#custom-accordion-one"
                  >
                    <div className="card-body">
                      <div className="row">{dynamicTypeElements}</div>
                    </div>
                  </div>
                </div>
                <div className="card mb-4">
                  <div className="card-header" id="headingFive">
                    <h5 className="m-0">
                      <a
                        className="custom-accordion-title collapsed d-block py-1"
                        data-bs-toggle="collapse"
                        href="#collapseFive"
                        aria-expanded="true"
                        aria-controls="collapseFive"
                      >
                        {content.TextAcoordion.map((t) => t.Text)[1]}
                        <i className="mdi mdi-chevron-down accordion-arrow"></i>
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseFive"
                    className="collapse show"
                    aria-labelledby="headingFive"
                    data-bs-parent="#custom-accordion-one"
                  >
                    <div className="card-body">
                      {qrCode.type === "Página de Destino" ||
                      qrCode.type === "Páginas Web" ? (
                        <Group>
                          <Label htmlFor="url">
                            {pageData.DestinyURL.Label}
                          </Label>
                          <Input
                            disabled={false}
                            onChange={handleValueChange}
                            value={qrCode.url}
                            name="url"
                            placeholder={pageData.DestinyURL.Placeholder}
                            error={errors.url}
                          />
                        </Group>
                      ) : qrCode.type === "Texto" ||
                        qrCode.type === "Comentários" ? (
                        <Group>
                          <Label htmlFor="text">{typeContent.typeTitle}</Label>
                          <TextArea
                            disabled={false}
                            onChange={handleValueChange}
                            value={qrCode.text}
                            name="text"
                            placeholder={
                              qrCode.type === "Texto"
                                ? pageData.TextPlaceholder
                                : pageData.CommentPlaceholder
                            }
                            error={errors.text}
                          />
                        </Group>
                      ) : qrCode.type === "Wifi" ? (
                        <QrCodeInputSwitch
                          qrCode={qrCode}
                          setFiles={setFiles}
                          wifi={wifi}
                          setWifi={setWifi}
                          urlPreference={urlPreference}
                          linkList={linkList}
                          setLinkList={setLinkList}
                          pageData={pageData}
                          typeTitle={typeContent.typeTitle}
                          errors={errors}
                          setErrors={setErrors}
                        />
                      ) : (
                        <div className="text-center">
                          <p>{qrCode.description}</p>

                          {user && !userIsLoading ? (
                            <>
                              <p className="card-text">
                                {content.TextRestrictAccess}
                              </p>
                              <Link to={"/create"} className="btn btn-primary">
                                {pageData.btnCreateQrTitle}
                              </Link>
                            </>
                          ) : (
                            <>
                              <p className="card-text">
                                {content.TextRestrictAccess}
                              </p>
                              <Link
                                to={"/signin"}
                                className="btn btn-secondary me-2"
                              >
                                {content.TextLoginButton}
                              </Link>
                              <Link to={"/signUp"} className="btn btn-primary">
                                {content.TextRegisterButton}
                              </Link>
                            </>
                          )}
                        </div>
                      )}
                      {showAccordion && (
                        <>
                          <hr />
                          <h4>{content.TextAcoordion.map((t) => t.Text)[2]}</h4>
                          <QRStyleEditor
                            qrCode={qrCode}
                            setQRCode={setQRCode}
                            handleValueChange={handleValueChange}
                            pageData={pageData}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QRCodeCreator;
