import { getToken } from "../localStorage";
import { FetchingError } from "../errors";

/**
 * Makes an HTTP request to a specified URL with given options.
 * @param {String} url - The URL to which the request is made.
 * @param {Object} options - The options for the request.
 * @param {String} options.method - The HTTP method (e.g., GET, POST). Defaults to "GET".
 * @param {Headers} options.header - The headers for the request. Defaults to buildDefaultHeader().
 * @param {Object} options.body - The body of the request. Defaults to an empty object.
 * @returns {Promise<Object>} The response data parsed as JSON.
 */
export default async function makeRequest (url, { method = "GET", headers = buildDefaultHeader(), body = undefined } = {}) {
  const options = { method, headers, body: body ? JSON.stringify(body) : undefined };
  
  return fetch(url, options)
    .then(res => res.json())
    .then(data => {
      if(data.error) throw new FetchingError(data.error.message);
      return data
    });
}

/**
 * Builds a default header object for HTTP requests.
 * @returns {Headers} A Headers object with default values for authorization and content type.
 */
function buildDefaultHeader () {
  return new Headers({
    "Authorization": `Bearer ${getToken()}`,
    "Content-Type": "application/json"
  })
}
