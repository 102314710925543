import { useState, useCallback } from "react";
import { deleteCurrent } from "../../services/apiCalls/user/current"; 
import { toast } from "../../services/toast";
import { ToastType } from "../../services/toast/ToastType";
import { removeToken } from "../../services/localStorage";
import { internalErrors } from "../../utils/error";

import { useAuthContext } from "../../context/AuthContext";

import { getSharing, updateSharing, removeFromSharing, switchSharingState, getUser } from "../../services/apiCalls/db";
import { ROLES, STATES } from "../../utils/constants";

const SHARING_ROLES = ["admins", "colaborators", "inactive_admins", "inactive_colaborators"];

export default function useUser () {
  const { user, isLoading: isLoadingUser, setUser } = useAuthContext();
  const [isPending, setIsPending] = useState(false);
  const [sharingUsers, setSharingUsers] = useState([]);

  const doUpdateAllUsers = () => {
    getSharing()
      .then((data) => {        
        const admins = data.admins?.map(admin => ({
          ...admin,
          role: ROLES.ADMIN,
          state: STATES.ACTIVE,
        }));

        const colaborators = data.colaborators?.map(colaborator => ({
          ...colaborator,
          role: ROLES.COLLABORATOR,
          state: STATES.ACTIVE,
        }));

        const inactive_admins = data.inactive_admins?.map(inactive_admin => ({
          ...inactive_admin,
          role: ROLES.ADMIN,
          state: STATES.INACTIVE,
        }));
        
        const inactive_colaborators = data.inactive_colaborators?.map(inactive_colaborator => ({
            ...inactive_colaborator,
            role: ROLES.COLLABORATOR,
            state: STATES.INACTIVE,
        }));
        
        setSharingUsers([ ...admins, ...colaborators, ...inactive_admins, ...inactive_colaborators ]);
      });
  };

  const isUserAlreadyAdded = async ({ email }) => {
    try {
      const sharing = await getSharing();

      return SHARING_ROLES.some(role => {
        sharing[role]?.some(user => user?.email === email);
      });
    } catch (err) {
      if (!internalErrors(err)) toast({
        actionName: ToastType.USER_UPDATE.ACTION_NAME,
        actionMessage: ToastType.USER_UPDATE.MESSAGES.ERROR_UPDATE
      });
    }
  };

  const doDeleteAccount = () => {
    toast({
      actionName: ToastType.DELETE_ACCOUNT.ACTION_NAME,
      actionMessage: ToastType.DELETE_ACCOUNT.MESSAGES.DELETING_ACCOUNT
    });

    deleteCurrent()
      .then(() => {
        setTimeout(() => {
          removeToken();
          toast({
            actionName: ToastType.DELETE_ACCOUNT.ACTION_NAME,
            actionMessage: ToastType.DELETE_ACCOUNT.MESSAGES.DELETED_ACCOUNT_SUCCESSFULLY
          });
          window.location.href = "/";
        }, 3000);
      })
      .catch((err) => {
        if (!internalErrors(err)) toast({
          actionMessage: ToastType.DELETE_ACCOUNT.ACTION_NAME,
          actionMessage: ToastType.DELETE_ACCOUNT.MESSAGES.ERROR_DELETING_ACCOUNT
        });
      });
  };

  const checkInputs = ({ email, role }) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) return false;
    if (typeof email !== "string") return false;
    if (!emailRegex.test(email)) return false;
    if (!role) return false;
    if (typeof role !== "string") return false;

    return true;
  };

  const doUpdate = async ({ email, role }) => {
    try {
      if (isPending) return;

      setIsPending(true);

      if (!checkInputs({ email, role })) throw Error("Update Error");

      if (user.email === email) {
        toast({
          actionName: ToastType.USER_UPDATE.ACTION_NAME,
          actionMessage: ToastType.USER_UPDATE.MESSAGES.YOU_CANT_ADD_YOURSELF
        });
        return;
      }

      const isAlreadyAdded = await isUserAlreadyAdded({ email });
      
      if (isAlreadyAdded) {
        toast({
          actionName: ToastType.USER_UPDATE.ACTION_NAME,
          actionMessage: ToastType.USER_UPDATE.MESSAGES.YOU_HAVE_ALREADY_ADDED_THAT_USER
        });
        return;
      }

      updateSharing({ email, role })
        .then((res) => {
          toast({
            actionName: ToastType.USER_UPDATE.ACTION_NAME,
            actionMessage: ToastType.USER_UPDATE.MESSAGES.USER_ADDED_SUCCESSFULLY
          });
          doUpdateAllUsers();
        })
        .catch(err => {
          if (!internalErrors(err)) toast({
            actionName: ToastType.USER_UPDATE.ACTION_NAME,
            actionMessage: ToastType.USER_UPDATE.MESSAGES.ERROR_UPDATE
          });
        });
    } catch (err) {
      if (!internalErrors(err)) toast({
        actionName: ToastType.USER_UPDATE.ACTION_NAME,
        actionMessage: ToastType.USER_UPDATE.MESSAGES.ERROR_UPDATE
      });
    } finally {
      setIsPending(false);
    }
  };

  const doSwitchSharingState = ({ updatedUser }) => {
    switchSharingState(updatedUser)
      .then(() => {
        doUpdateAllUsers();
        toast({
          actionName: ToastType.SWITCH_SHARING_STATE.ACTION_NAME,
          actionMessage: ToastType.SWITCH_SHARING_STATE.MESSAGES.UPDATE_STATE_SUCCESS
        });
      })
      .catch(err => {
        if (!internalErrors(err)) toast({
          actionName: ToastType.SWITCH_SHARING_STATE.ACTION_NAME,
          actionMessage: ToastType.SWITCH_SHARING_STATE.MESSAGES.ERROR_UPDATE_STATE
        });
      });
  };

  const doUpdateSharing = ({ updatedUser }) => {
    updateSharing(updatedUser)
      .then(() => {
        doUpdateAllUsers();
        toast({
          actionName: ToastType.UPDATE_SHARING.ACTION_NAME,
          actionMessage: ToastType.UPDATE_SHARING.MESSAGES.UPDATE_PROFILE_SUCCESS
        });
      })
      .catch(err => {
        if (!internalErrors(err)) toast({
          actionName: ToastType.UPDATE_SHARING.ACTION_NAME,
          actionMessage: ToastType.UPDATE_SHARING.MESSAGES.ERROR_UPDATE_PROFILE
        }); 
      });
  };

  const doDeleteUser = ({ userToRemove }) => {
    removeFromSharing(user.id, userToRemove)
      .then(() => {
        doUpdateAllUsers();
        toast({
          actionName: ToastType.REMOVE_FROM_SHARING.ACTION_NAME,
          actionMessage: ToastType.REMOVE_FROM_SHARING.MESSAGES.USER_REMOVED_SUCCESSFULLY
        });
      })
      .catch(() => {
        toast({
          actionName: ToastType.REMOVE_FROM_SHARING.ACTION_NAME,
          actionMessage: ToastType.REMOVE_FROM_SHARING.MESSAGES.ERROR_DELETE_USER
        });
      });
  }

  const doGetCurrentUser = () => {
    setIsPending(true);

    getUser()
        .then(setUser)
        .catch((err) => {
          if (!internalErrors(err)) toast({
            actionName: ToastType.GET_CURRENT_USER.ACTION_NAME,
            actionMessage: ToastType.GET_CURRENT_USER.MESSAGES.COULD_NOT_GET_USER
          });
        })
        .finally(() => setIsPending(false));
  }

  return { doDeleteAccount, doUpdate, doUpdateAllUsers, doSwitchSharingState, doUpdateSharing, doDeleteUser, doGetCurrentUser, user, sharingUsers, isPending, isLoadingUser };
}
