import Card from "../../../components/Card";
import QRPreview from "../../../components/QRPreview";
import QRStyleEditor from "../../../components/QRStyleEditor";
import { Group, Label, TextArea } from "../../../components/Form";
import QrCodeInputSwitch from "../../../components/QrCodeInputSwitch";


export function Editing({ pageData, qrCode, qrCodeDataUpdated, setQrCodeDataUpdated, handleTextChange, urlPreference, setFiles, wifi, setWifi, linkList, setLinkList }) {
  const usesRawText = ["Texto", "Comentários"].includes(qrCode?.type);

  const contentLabel = qrCode.type === "Texto" || qrCode.type === "Comentários" ?
    "Texto" :
    "Comentário";

  const contentPlaceholder = qrCode.type === "Texto" || qrCode.type === "Comentários" ?
    pageData.TextPlaceholder :
    pageData.CommentPlaceholder;

  const contentColumns = usesRawText ? 2 : 1;

  return (
    <>
      <Card>
        <div className="row">
          <h4 className="header-title mb-2">
            {pageData.EditContentTitle}
          </h4>
          <Group columns={contentColumns}>
            <Label htmlFor="description">
              {pageData.Description.Label}
            </Label>
            <TextArea
              id="description"
              onChange={handleTextChange}
              value={qrCodeDataUpdated.description}
              name="description"
              placeholder={pageData.Description.Placeholder}
            />
          </Group>
          {
            !urlPreference &&
            <Group>
              <QrCodeInputSwitch
                qrCode={qrCode}
                setFiles={setFiles}
                wifi={wifi}
                setWifi={setWifi}
                urlPreference={urlPreference}
                linkList={linkList}
                setLinkList={setLinkList}
                editing={true}
                pageData={pageData}
              />
            </Group>
          }
          {
            usesRawText &&
            <Group columns={contentColumns}>
              <Label htmlFor="text">{contentLabel}</Label>
              <TextArea
                onChange={handleTextChange}
                value={qrCodeDataUpdated.text}
                name="text"
                placeholder={contentPlaceholder}
              />
            </Group>
          }
        </div>
      </Card>

      <Card>
        <h4 className="header-title mb-2">
          {pageData.EditAppearanceTitle}
        </h4>
        <div className="row">
          <div className="col-md-8 mb-2 mb-md-0">
            <QRStyleEditor
              qrCode={qrCodeDataUpdated}
              setQRCode={setQrCodeDataUpdated}
              handleValueChange={handleTextChange}
              pageData={pageData}
            />
          </div>
          <div className="col-md-4 justify-content-center align-items-center">
            <QRPreview
              pageData={pageData}
              className="d-flex justify-content-center align-items-center sticky"
              qrCode={qrCodeDataUpdated}
              size={250}
            />
          </div>
        </div>
      </Card>
    </>
  );
}