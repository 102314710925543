import { useState, useEffect } from "react";
import { findQrCodeScans } from "../../services/apiCalls/qrCodes/scans";
import { toast } from "../../services/toast";
import { ToastType } from "../../services/toast/ToastType";

export default function useFindQrCodeScans () {
  const [qrCodes, setQrCodes] = useState(null);
  
  useEffect(() => {
    findQrCodeScans()
      .then(data => {
        setQrCodes(data)
      })
      .catch(() => {
        toast({
          actionName: ToastType.FIND_QR_CODES_SCANS.ACTION_NAME,
          actionMessage: ToastType.FIND_QR_CODES_SCANS.MESSAGES.COULD_NOT_GET_QR_CODES
        })
        setQrCodes([])
      })
  }, []);

  return { qrCodes };
};
