import React, { useState, useEffect } from "react";

import { AuthContext } from "../../context/AuthContext";
import { API, BEARER } from "../../constant";
import { getToken } from "../../helpers";

const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const authToken = getToken();

  const fetchLoggedInUser = async (token) => {
    try {
      const response = await fetch(`${API}/users/me?populate[0]=avatar&populate[1]=role&populate[2]=qr_codes&populate[3]=qr_codes.scans`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const data = await response.json();

      setUserData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUser = (user) => {
    setUserData(user);
  };

  useEffect(() => {
    if (authToken) {
      fetchLoggedInUser(authToken);
    } else {
      setIsLoading(false);
    }
  }, [authToken]);

  return (
    <AuthContext.Provider
      value={{ user: userData, setUser: handleUser, isLoading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;