export function Card({ title, description, image }) {
  return (
    <div className="card h-100">
      <div className="d-flex g-0 justify-content-start align-items-center">
        <div className="p-2">
          <div className="avatar-lg">
            <span className="avatar-title bg-primary-mute rounded">
              <img src={image} alt="" />
            </span>
          </div>
        </div>
        <div className="flex-grow-1">
          <div className="card-body">
            <h5 className="card-title">{title}</h5>
            <p className="card-text">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}