import makeRequest from "../../dataFetching"
import { API_NOT_FOUND } from "../../../utils/constants";
import { findNotFoundPageMapping } from "../../dataMapping/notFoundPage";
/**
 * FIND
 * Fetches all NotFoundPage entries.
 * @returns {Promise<Object>} A promise that resolves to the response data from the GET request.
 */
export function findNotFoundPage () {
  return makeRequest(API_NOT_FOUND)
  .then(findNotFoundPageMapping);
}

/**
 * CREATE LOCALIZATION
 * Creates a new NotFoundPage localization entry.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function createLocalizationNotFoundPage ({}) {}


/**
 * PUT
 * Updates an existing NotFoundPage entry.
 * @param {integer} id - The ID of the NotFoundPage entry to update.
 * @returns {Promise<Object>} A promise that resolves to the response data from the PUT request.
 */
export function updateNotFoundPage (id, {}) {}


/**
 * DELETE
 * Deletes a specific NotFoundPage entry by ID.
 * @param {integer} id - The ID of the NotFoundPage entry to delete.
 * @returns {Promise<Object>} A promise that resolves to the response data from the DELETE request.
 */
export function deleteNotFoundPage ({ id }) {}
