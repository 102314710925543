import { Table } from "../../../components/Table";
import { LOCAL } from "../../../constant";

export function Summary({ pageData, currentPlan, price }) {
  const currency = pageData.Currency;

  const tipIcon = LOCAL + pageData.TipIcon.data.attributes.url;
  const lockerIcon = LOCAL + pageData.LockerIcon.data.attributes.url;
  const paymentMethodImage =
    LOCAL + pageData.PaymentMethodImage.data.attributes.url;
  const paymentMethodImage2 =
    LOCAL + pageData.PaymentMethodImage2.data.attributes.url;

  const { totalExcludingTax, tax, total, discount } = price;

  const formatPrice = (price) => {
    if (price !== 0) {
      return price.toFixed(2);
    }

    return "__.__";
  };

  const pricesDisplays = [
    `${formatPrice(currentPlan.Price)}${currency}`,
    `${formatPrice(totalExcludingTax)}${currency}`,
    `${formatPrice(tax)}${currency}`,
    `${formatPrice(discount)}${currency}`,
    `${formatPrice(total)}${currency}`,
  ];

  const tips = pageData.TipList.map((tip) => {
    return (
      <li className="list-group-item" key={`tip-${tip.Text}`}>
        <img src={tipIcon} className="me-2" alt="" />
        {tip.Text}
      </li>
    );
  });

  return (
    <aside>
      <h4 className="mt-2 mb-3">{pageData.Summary}</h4>
      <Table>
        <tbody>
          <tr>
            <td>
              {pageData.CurrentPlan} - {currentPlan.Title}
            </td>
            <td className="text-end">{pricesDisplays[0]}</td>
          </tr>
          <tr>
            <td>{pageData.NoIVATotal}</td>
            <td className="text-end">{pricesDisplays[1]}</td>
          </tr>
          <tr>
            <td>{pageData.IVA}</td>
            <td className="text-end">{pricesDisplays[2]}</td>
          </tr>
          {discount !== 0 && (
            <tr>
              <td>{pageData.Discount}</td>
              <td className="text-end">-{pricesDisplays[3]}</td>
            </tr>
          )}
          <tr>
            <td className="fw-bold">{pageData.TotalWithIVA}</td>
            <td className="text-end fw-bold">{pricesDisplays[4]}</td>
          </tr>
        </tbody>
      </Table>
      <section>
        <ul className="list-group">{tips}</ul>
      </section>
    </aside>
  );
}
