import React, { useRef, useState } from "react";
import { toast } from "react-toastify";

import { formatImage } from "../../utils";
import { Group, Input, Label } from "../Form";

const LinkCard = ({
  item,
  imagePreviews,
  itemId,
  allItems,
  setAllItems,
  QRID,
  setImagePreviews,
  pageData,
}) => {
  const [openDetails, setOpenDetails] = useState(false);
  const [errors, setErrors] = useState({});

  const LinkImageInputRef = useRef(null);
  const handleLinkImageUploadClick = () => {
    LinkImageInputRef.current.click();
  };
  const [newValues, setNewValues] = useState({
    Name: item.Name,
    Link: item.Link,
    image: item.image,
    qr_code: QRID,
    imagePreview: item.imagePreview,
  });
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setNewValues({ ...newValues, image: file });
          setImagePreviews({ ...imagePreviews, [file.name]: reader.result });
          item.image = file;
        };
        reader.readAsDataURL(file);
      }
    }
  };
  const handleLinkChange = (e) => {
    const { name, value } = e.target;
    setNewValues({ ...newValues, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: undefined });
    }
  };
  const moveDownByIndex = (index) => {
    const updatedItems = [...allItems];

    if (index === updatedItems.length - 1) {
      return;
    }

    const currentItem = updatedItems[index];
    updatedItems[index] = updatedItems[index + 1];
    updatedItems[index + 1] = currentItem;

    setAllItems(updatedItems);
  };
  const removeItemByIndex = (indexToRemove) => {
    const updatedItems = [...allItems];

    const filteredItems = updatedItems.filter(
      (item, index) => index !== indexToRemove
    );

    setAllItems(filteredItems);
  };
  const handleEdit = (index) => {
    let newErrors = {};
    if (!newValues.Name) {
      newErrors.name = pageData.linkNameMissing.Message;
    }

    if (!newValues.Link) {
      newErrors.link = pageData.urlLinkMissing.Message;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast(pageData.PleaseFillInAllLinkFields);
      return;
    }

    const updatedItems = allItems.map((item, idx) =>
      idx === index ? newValues : item
    );

    setAllItems(updatedItems);
    setOpenDetails(false);
    setErrors({});
  };

  return (
    <div className="CreateQr--LinkTree--LinkCard--Wrapper">
      <div className="separator">{pageData.linkListTitle}</div>
      {openDetails ? (
        <li className="row border border-1 p-2 pt-3 align-items-center rounded">
          <div className="col-lg-2" style={{ minWidth: "146px" }}>
            <Label>{pageData.LinkImageLabel}</Label>
            {item.image ? (
              <div
                className="avatar-xl mb-2"
                onClick={handleLinkImageUploadClick}
              >
                <div className="image-edit-container avatar-title bg-light rounded">
                  <img
                    src={formatImage(item.image)}
                    alt="Preview"
                    className="image-preview"
                  />
                  <div className="edit-icon-overlay rounded">
                    <i className="mdi mdi-image-edit-outline h1 text-primary"></i>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="avatar-xl mb-2 preview-empty"
                onClick={handleImageChange}
              >
                <span className="avatar-title bg-light rounded">
                  <div className="text-center">
                    <i className="mdi mdi-image text-primary h1"></i>
                    <p className="mb-0">
                      <small className="mb-0 text-primary p-1">
                        {pageData.btnAddNewImageTitle}
                      </small>
                    </p>
                  </div>
                </span>
              </div>
            )}
            <input
              type="file"
              id="fileInput"
              ref={LinkImageInputRef}
              style={{ display: "none" }}
              accept={".png,.jpg"}
              onChange={handleImageChange}
            />
          </div>
          <div className="col-lg-10">
            <Group>
              <Label>{pageData.LinkName.Label}</Label>
              <Input
                onChange={handleLinkChange}
                value={newValues.Name}
                name="Name"
                width={"100%"}
                placeholder={pageData.LinkName.Placeholder}
                error={errors?.name}
              />
            </Group>
            <Group>
              <Label>{pageData.LinkURL.Label}</Label>
              <Input
                onChange={handleLinkChange}
                value={newValues.Link}
                name="Link"
                width={"100%"}
                placeholder={pageData.LinkURL.Placeholder}
                error={errors?.link}
              />
            </Group>
          </div>
          <div className="col-lg-12" style={{ textAlign: "right" }}>
            <button
              className="btn btn-primary addLink float-right"
              onClick={() => handleEdit(itemId)}
            >
              {pageData.EditLink}
            </button>
          </div>
        </li>
      ) : (
        <li
          className="row border border-1 p-2 pt-3 align-items-center rounded"
          key={itemId}
        >
          <div className="col-lg-2">
            <div className="avatar-md mb-2">
              {imagePreviews[item.image.name] && (
                <div className="image-edit-container_ avatar-title bg-light rounded">
                  <img
                    src={formatImage(imagePreviews[item.image.name])}
                    alt={item.name}
                    style={{ maxHeight: "75px", maxWidth: "90px" }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-8">
            <p>
              <strong>{pageData.nameImageTitle}</strong>
              {newValues.Name}
            </p>
            <p>
              <strong>{pageData.urlTitle}</strong>
              {newValues.Link}
            </p>
          </div>
          <div className="col-lg-2" style={{ textAlign: "right" }}>
            <button className="btn px-1" onClick={() => setOpenDetails(true)}>
              <i className="mdi mdi-pencil font-20"></i>
            </button>
            <button
              className="btn px-1"
              onClick={() => moveDownByIndex(itemId)}
            >
              <i className="mdi mdi-arrow-down font-20"></i>
            </button>
            <button
              className="btn px-1"
              onClick={() => removeItemByIndex(itemId)}
            >
              <i className="mdi mdi-delete font-20"></i>
            </button>
          </div>
        </li>
      )}
    </div>
  );
};

export default LinkCard;
