import { FetchingError } from "../../errors";

export function contactMapping(response) {
  if (!response) throw new FetchingError("Response not found");
  
  return {
    id: response.data.id,
    name: response.data.attributes.name,
    subject: response.data.attributes.subject,
    message: response.data.attributes.message,
    email: response.data.attributes.email
  }
}