import React from "react";

// Components Imports
import Loading from "../../components/Loading";
import InfoCard from "../../components/InfoCard";
import { Hero, CTABanner, Section, AskedQuestions, HeroButton } from "../../components/Landing";

// Hooks Imports
import usePagesData from "../../hooks/pageData/usePagesData";
import { PageTypes } from "../../hooks/pageData/PageTypes";

const Faq = () => {
  const faqsId = "questions";
  const { pageData: pageContent, isPending, isError }= usePagesData({ pageType: PageTypes.FAQ });

  if (isPending || isError) return <Loading />;

  const pageData = pageContent.pageData;
  const infoCardData = pageContent.infoCardData;

  const header = pageData.Header;
  const banner = pageData.BannerCTA;
  const headerSection_1 = pageData.HeaderSection_1;

  const faqs = pageData.faq_cards.data.map((f) => {
    const faq = f.attributes;

    return { question: faq.question, answer: faq.answer };
  });

  return (
    <>
      <Hero
        title={header.Title}
        subtitle={`${header.Subtitle} ${header.Subtitle2}`}
        image={header.Image}
      >
        <HeroButton
          id={faqsId}
          text={header.ButtonText}
          icon={header.ButtonIconClass}
        />
      </Hero>
      <Section
        isFirstSection={true}
        titles={headerSection_1.Title.map((t) => t.Text)}
        descriptions={[headerSection_1.Subtitle]}
        icon={headerSection_1.Icon}
        navigationId={faqsId}
      >
        <AskedQuestions questions={faqs} symbol="P." />
      </Section>
      <CTABanner text={banner.Title} button={banner.Button} url={banner.URL} />
      <InfoCard infoCardData={infoCardData} />
    </>
  );
};

export default Faq;
