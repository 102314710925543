import makeRequest from "../../dataFetching"
import { API_MY_QR_CODES_PAGE } from "../../../utils/constants";
import { findMyQrCodesPageMapping } from "../../dataMapping/myQrCodesPage";
/**
 * FIND
 * Fetches all MyQrCodesPage entries.
 * @returns {Promise<Object>} A promise that resolves to the response data from the GET request.
 */
export function findMyQrCodesPage () {
  return makeRequest(API_MY_QR_CODES_PAGE)
  .then(findMyQrCodesPageMapping);
}

/**
 * CREATE LOCALIZATION
 * Creates a new MyQrCodesPage localization entry.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function createLocalizationMyQrCodesPage ({}) {}


/**
 * PUT
 * Updates an existing MyQrCodesPage entry.
 * @param {integer} id - The ID of the MyQrCodesPage entry to update.
 * @returns {Promise<Object>} A promise that resolves to the response data from the PUT request.
 */
export function updateMyQrCodesPage (id, {}) {}


/**
 * DELETE
 * Deletes a specific MyQrCodesPage entry by ID.
 * @param {integer} id - The ID of the MyQrCodesPage entry to delete.
 * @returns {Promise<Object>} A promise that resolves to the response data from the DELETE request.
 */
export function deleteMyQrCodesPage ({ id }) {}
