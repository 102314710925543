export function Accordion({ items = [] }) {
  const parentId = `accordion-parent-${items.length}`

  return (
    <div className="accordion custom-accordion" id={parentId}>
      {
        items.map((item, index) => {
          return (
            <AccordionItem
              key={`accordion-item-${item.title}`}
              itemId={index}
              title={item.title}
              description={item.description}
              parentId={parentId}
            />
          );

        })
      }
    </div>
  );
}

function AccordionItem({ title, description, itemId, parentId }) {
  const accordionItemId = `accordion-item-${itemId}`;
  const collapseItemId = `collapse-item-${itemId}`;

  const show = itemId === 0;

  const itemShowClass = show ? "" : "collapsed";
  const collapseShowClass = show ? "show" : "";

  return (
    <div className="card mb-0">
      <div className="card-header" id={accordionItemId}>
        <h5 className="m-0">
          <a
            className={`custom-accordion-title d-flex justify-content-between py-1 ${itemShowClass}`}
            data-bs-toggle="collapse"
            href={`#${collapseItemId}`}
            aria-controls={collapseItemId}
            {...show && { "aria-expanded": "true" }}
          >
            {title} <i
              className="mdi mdi-chevron-down fs-4"></i>
          </a>
        </h5>
      </div>

      <div id={collapseItemId} className={`collapse ${collapseShowClass}`}
        aria-labelledby={accordionItemId}
        data-bs-parent={`#${parentId}`}>
        <div className="card-body">
          {description}
        </div>
      </div>
    </div>
  );
}