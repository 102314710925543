import makeRequest from "./makeRequest"
import { APP } from "../../../constant";

export async function getCurrentPlan() {
  try {
    const currentPlanStatus = await makeRequest("/current-plan");

    if (currentPlanStatus.error)
      return "";

    return {
      isTrial: currentPlanStatus.isTrial,
      name: currentPlanStatus?.currentPlan?.name,
      isContract: currentPlanStatus?.currentPlan?.isContract
    };
  } catch (error) {
    console.error(error);
  }
}

export async function getInvoices() {
  try {
    const invoices = await makeRequest("/invoices");

    if (invoices.error && invoices.error.status === 404)
      return [];

    return invoices?.map(i => {
      return {
        ...i,
        date: new Date(i.date).toLocaleDateString(),
      }
    })
  } catch (error) {
    console.error(error);
  }
}

export async function createPaymentIntent(paymentPlanId) {
  const payment_plan = { payment_plan: paymentPlanId };

  try {
    const res = await makeRequest("/payment-intent", "POST", payment_plan);

    if (res.error && res.error.message === "You can't downgrade your plan") {
      return Promise.reject(res.error.message);
    }

    const { subscriptionId, paymentIntentId, clientSecret, price } = res;

    return {
      subscriptionId,
      paymentInformation: {
        clientSecret,
        paymentIntentId,
        price
      }
    };
  } catch (error) {
    console.error(error);
  }
}

export async function confirmPayment(stripe, elements) {
  try {
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${APP}/checkout/completion`,
      },
      redirect: "if_required",
    })

    return { error, paymentIntent };
  } catch (error) {
    console.error(error);
  }
}

export async function cancelPaymentIntent(subscriptionId) {
  try {
    return await makeRequest(`/payment-intent/${subscriptionId}`, "DELETE");
  } catch (error) {
    console.error(error);
  }
}

export async function updateCustomer(invoiceDetails) {
  try {
    return await makeRequest("/customer-address", "PATCH", invoiceDetails);
  } catch (error) {
    console.error(error);
  }
}

export async function cancelSubscription() {
  try {
    const canceled = await makeRequest("/current-plan", "DELETE");

    if (canceled.error)
      return Promise.reject(canceled.error.message);

    return canceled;
  } catch (error) {
    console.error(error);
  }
}

export async function addSubscription(body) {
  try {
    return await makeRequest("/subscriptions", "POST", body);
  } catch (error) {
    console.error(error);
  }
}