import 'react-toastify/dist/ReactToastify.css';
import { toast as t } from 'react-toastify';

import { findOneToastMessages } from "../apiCalls/toastMessages"

export function toast({ actionName, actionMessage }) {
  if(!actionName || !actionMessage) handle({ type: 'warn', message: `${actionName ? 'actionMessage' : 'actionName'} must be defined` });

  findOneToastMessages({ action: actionName, message: actionMessage })
  .then(res => {
    handle(res);
  })
  .catch(err => handle({Type: "error", Message: err.message}));
}

function handle({ type, message }) {
  try {
    t[type](message);
  } catch (err) {
    t.error("type does not match")
  }
}