import { useEffect, useState } from "react";

export function useThemeSelect() {
  // Check user's preference for dark mode
  const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const preferredTheme = prefersDarkMode ? "dark" : "light";

  // Initialize state with themes from local storage or user preference
  const [themes, setThemes] = useState(() => {
    const localStorageThemes = localStorage.getItem("themes");
    return localStorageThemes ?
      JSON.parse(localStorageThemes) :
      { sidebar: preferredTheme, page: preferredTheme };
  });

  // Update local storage when themes change
  useEffect(() => {
    localStorage.setItem("themes", JSON.stringify(themes));
  }, [themes]);

  // Update body properties when theme changes
  useEffect(() => {
    document.body.setAttribute("data-layout-color", themes.page);
    document.body.setAttribute("data-leftbar-theme", themes.sidebar);
  });

  // Update theme based on user preference
  useEffect(() => {
    const matchDarkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const updateThemeBasedOnPreference = (e) => {
      const newTheme = e.matches ? "dark" : "light";
      setThemes({ sidebar: newTheme, page: newTheme });
    };

    matchDarkMediaQuery.addEventListener('change', updateThemeBasedOnPreference);

    // Cleanup function to remove the event listener
    return () => {
      matchDarkMediaQuery.removeEventListener('change', updateThemeBasedOnPreference);
    };
  }, []);

  return [themes, setThemes];
}