import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  pt: {
    translation: require('./locales/pt.json')
  },
  en: {
    translation: require('./locales/en.json')
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("language") || 'pt',
  fallbackLng: Object.getOwnPropertyNames(resources),
});

export default i18n;
