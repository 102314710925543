export function Section({
  isFirstSection,
  titles,
  titleStartsPrimary = false,
  descriptions = [],
  icon,
  navigationId,
  children,
}) {
  const iconClasses = `mdi ${icon}`;

  const titleContent = titles?.map((title, index) => {
    if (index === 0) {
      return (
        <span key={index} className={titleStartsPrimary ? "text-primary" : ""}>
          {title}{" "}
        </span>
      );
    }
    if (index === 1 && !titleStartsPrimary) {
      return (
        <span key={index} className="text-primary">
          {title}{" "}
        </span>
      );
    }

    const isPrimary =
      (index % 2 === 0 && titleStartsPrimary) ||
      (index % 2 !== 0 && !titleStartsPrimary);
    const textClass = isPrimary ? "text-primary" : "";
    return (
      <span key={index} className={textClass}>
        {title}{" "}
      </span>
    );
  });

  const descriptionContent = descriptions?.map((description) => {
    return (
      <span key={description}>
        {description}
        <br />{" "}
      </span>
    );
  });

  return (
    <section className="py-5">
      <div className="container">
        <div className={`row ${isFirstSection ? "py-4" : ""}`}>
          <div className="col-lg-12" id={navigationId}>
            <div className="text-center">
              {icon && (
                <h1 className="mt-0">
                  <i className={iconClasses}></i>
                </h1>
              )}
              <h3 className="w-75 mx-auto">{titleContent}</h3>
              <p className="text-muted mt-2">{descriptionContent}</p>
            </div>
          </div>
        </div>
        {children}
      </div>
    </section>
  );
}
