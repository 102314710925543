import React, { useEffect, useRef, useState } from "react";

import "../../styles/pages/Users.css";

// Components Imports
import { Group, Input, Label, SelectComponent } from "../../components/Form";
import { Table, TableHead } from "../../components/Table";
import Loading from "../../components/Loading";
import Modal from "../../components/Modal";

// Hooks Imports
import { useAuthContext } from "../../context/AuthContext";
import usePagesData from "../../hooks/pageData/usePagesData";
import { PageTypes } from "../../hooks/pageData/PageTypes";
import useUser from "../../hooks/user/useUser";

// Utils Imports
import { LOCAL } from "../../constant";
import { states } from "./constants";

const addUserModalId = "addUserModal";
const removeUserModalId = "removeUserModal";

const Users = () => {
  const [userToRemove, setUserToRemove] = useState(null);
  const [formError, setFormError] = useState({});

  const { pageData, isPending } = usePagesData({ pageType: PageTypes.USERS_PAGE });
  const { doUpdate, doUpdateSharing, doUpdateAllUsers, doSwitchSharingState, doDeleteUser, sharingUsers, isPending: isUsersPending } = useUser();
  const { user } = useAuthContext();

  const inputEmailRef = useRef();
  const inputRoleRef = useRef();
  const closeModalButtonRef = useRef();
  const formRef = useRef();
  const closeRemoveModalButtonRef = useRef();

  useEffect(() => {
    if (!user) return;
    doUpdateAllUsers();
  }, [user]);

  if (isUsersPending || isPending) return <Loading />;

  const roleOptions = pageData.Perfil.Options.map((o) => ({
    value: o.Value,
    label: o.Text,
  }));

  const handleState = (user, newState) => {
    const updatedUser = { ...user, state: newState };
    doSwitchSharingState({ updatedUser });
  };

  const handleRole = (user, newRole) => {
    const updatedUser = { ...user, role: newRole };
    doUpdateSharing({ updatedUser });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    if (name === "email") {
      const email = value.trim();
      if (!email) {
        setFormError({ email: pageData.ErrorRequiredEmail });
      } else if (!emailRegex.test(email)) {
        setFormError({ email: pageData.ErrorRequiredInvalidEmail });
      }
    } else if (inputRoleRef.current) {
      const role = inputRoleRef.current.props.value;
      if (!role) setFormError({ role: pageData.ErrorRequiredProfile });
    }
  }

  const handleSubmitUser = (e) => {
    e.preventDefault();

    const { email, role } = Object.fromEntries(new FormData(e.target));
    
    doUpdate({ email, role })
    
    if (inputEmailRef.current && inputRoleRef.current && closeModalButtonRef.current) {
      inputEmailRef.current.value = "";
      inputRoleRef.current.value = "";
      closeModalButtonRef.current.click();
    }
  };

  const deleteUser = (userToRemove) => {
    doDeleteUser({ userToRemove });
    if (closeRemoveModalButtonRef.current) closeRemoveModalButtonRef.current.click();
  };

  const addUserModal = (
    <Modal
      ref={closeModalButtonRef}
      id={addUserModalId}
      title={pageData.AddUser}
      position="center"
    >
      <form ref={formRef} onSubmit={handleSubmitUser}>
        <Group>
          <Label htmlFor="email">{pageData.Email.Label}</Label>
          <Input
            ref={inputEmailRef}
            type="text"
            id="email"
            name="email"
            onBlur={handleBlur}
            placeholder={pageData.Email.Placeholder}
            onChange={() => setFormError({})}
            error={formError.email}
          />
        </Group>
        <Group>
          <Label htmlFor="role">{pageData.Perfil.Label}</Label>
          <SelectComponent
            ref={inputRoleRef}
            id="role"
            name="role"
            onBlur={handleBlur}
            options={roleOptions}
            placeholder={pageData.Perfil.Title}
            isSearchable={false}
            error={formError.role}
            onChange={() => setFormError({})}
            className={formError.role ? "is-invalid-select" : ""}
          />
          {formError.role && <div className="invalid-feedback">{formError.role}</div>}
        </Group>
        <button type="submit" className="btn btn-primary">
          <i className="mdi mdi-plus-circle me-2"></i>
          {pageData.ButtonTextAddUser}
        </button>
      </form>
    </Modal>
  );

  const removeUserModal = (
    <Modal
      ref={closeRemoveModalButtonRef}
      id={removeUserModalId}
      title={pageData.RemoveModalTitle}
      position="center"
      footer={{
        close: pageData.ButtonTextCancel,
        confirm: {
          text: pageData.ButtonTextRemoveUser,
          color: "btn-danger",
          onClick: () => deleteUser(userToRemove),
        },
      }}
    >
      <p>{pageData.RemoveModalText1}</p>
      <p>{pageData.RemoveModalText2}</p>
    </Modal>
  );

  const tableRows = sharingUsers.map((userToShow) => {
    const key = `user-sharing-${userToShow.id}`;
    const active = userToShow.state === states.ACTIVE;

    const roleIndex = pageData.Perfil.Options.findIndex(
      (o) => o.Value === userToShow.role
    );
    const currentRole = pageData.Perfil.Options[roleIndex];
    const currentRoleOption = {
      value: currentRole.Value,
      label: currentRole.Text,
    };

    const avatar = userToShow.avatar?.data?.attributes?.url || pageData.UserAvatar?.data?.attributes?.url;
    const userAvatar = LOCAL + avatar;

    const handleStateChange = () => {
      handleState(userToShow, active ? states.INACTIVE : states.ACTIVE);
    };
    const handleRoleChange = (role) => {
      handleRole(userToShow, role);
    };

    const handleDeleteUser = () => {
      setUserToRemove(userToShow);
    };

    return (
      <tr key={key}>
        <td className="table-user">
          <img src={userAvatar} alt="" className="me-2 rounded-circle" />
          <span className="text-body fw-semibold">{userToShow.username}</span>
        </td>
        <td>
          <span className="text-body fw-semibold">{userToShow.email}</span>
        </td>
        <td className="select-min-width">
          <SelectComponent
            onChange={(selected) => handleRoleChange(selected.value)}
            options={roleOptions}
            defaultValue={currentRoleOption}
            isSearchable={false}
            menuPortalTarget={document.body}
          />
        </td>
        <td>
          <input
            type="checkbox"
            id={key}
            onChange={handleStateChange}
            checked={active}
            data-switch="success"
          />
          <label
            htmlFor={key}
            data-on-label="Sim"
            data-off-label="Não"
            className="mb-0 d-block"
          ></label>
        </td>
        <td className="text-center">
          <button
            onClick={handleDeleteUser}
            className="button-reset action-icon"
            data-bs-toggle="modal"
            data-bs-target={`#${removeUserModalId}`}
          >
            <i className="mdi mdi-delete"></i>
          </button>
        </td>
      </tr>
    );
  });

  return (
    <>
      {removeUserModal}
      {addUserModal}
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <h4 className="page-title">{pageData.Title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="row mb-2">
                  {sharingUsers.length > 0 && (
                    <div className="col-sm-5">
                      <button
                        data-bs-target={`#${addUserModalId}`}
                        data-bs-toggle="modal"
                        className="btn btn-primary mb-2"
                      >
                        <i className="mdi mdi-plus-circle me-2"></i>
                        {pageData.AddUser}
                      </button>
                    </div>
                  )}
                </div>
                {sharingUsers.length > 0 ? (
                  <Table className="table-striped">
                    <TableHead actionLabel={pageData.removeTitle}>
                      <th>{pageData.LabelTableNameUser}</th>
                      <th>{pageData.Email.Label}</th>
                      <th>{pageData.Perfil.Label}</th>
                      <th>{pageData.isActiveTitle}</th>
                    </TableHead>
                    <tbody>{tableRows}</tbody>
                  </Table>
                ) : (
                  <>
                    <div className="text-center">
                      <h4 className="mb-3">{pageData.LabelEmptyTable}</h4>
                      <button
                        data-bs-target={`#${addUserModalId}`}
                        data-bs-toggle="modal"
                        className="btn btn-primary mb-3"
                      >
                        <i className="mdi mdi-plus-circle me-2"></i>
                        {pageData.LabelButtonAdd}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
