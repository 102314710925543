import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Components Imports
import { Group, Input, Label, SelectComponent } from "../../components/Form";
import { Tab, TabContent, TabPane, Tabs } from "../../components/Tab";
import appearance from "../../styles/components/StripeElements";
import ThemeSelector from "../../components/ThemeSelector";
import Loading from "../../components/Loading";
import { StripeElements } from "./components";
import Modal from "../../components/Modal";

// Hooks Imports
import { useAuthContext } from "../../context/AuthContext";
import useAuth from "../../hooks/auth/useAuth";
import usePagesData from "../../hooks/pageData/usePagesData";
import useUser from "../../hooks/user/useUser";

// Utils Imports
import { PageTypes } from "../../hooks/pageData/PageTypes";
import { STRIPE_PUBLIC_KEY } from "../../constant";

const INITIAL_USER_DATA = {
  isParticular: true,
  companyName: "",
  contact: "",
  nif: "",
  email: "",
  firstName: "",
  lastName: "",
  address: "",
  postalCode: "",
  country: "",
  address2: "",
  city: "",
};

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const systemId = "system";
const accountId = "account";
const invoicingId = "invoicing";
const deleteUserModalId = "deleteUserModal";

const Settings = () => {
  const [inputError, setInputError] = useState({});
  const [currentTab, setCurrentTab] = useState(true);
  const [isEditingInfo, setIsEditingInfo] = useState(false);

  const { pageData, isPending } = usePagesData({ pageType: PageTypes.SETTING });
  const { doUpdatePassword } = useAuth();
  const { doDeleteAccount } = useUser();
  const { user } = useAuthContext();

  const inputNewPasswordRef = useRef();
  const formUpdatePasswordRef = useRef();
  const formRef = useRef(null);
  
  const [data, setData] = useState(INITIAL_USER_DATA);

  const resetUserData = useCallback(() => {
    setData({
      isParticular: user.isParticular,
      companyName: user.companyName || "",
      contact: user.contact || "",
      nif: user.nif || "",
      email: user.email || "",
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      address: user.address || "",
      postalCode: user.postalCode || "",
      country: user.country || "",
      address2: user.address2 || "",
      city: user.city || "",
    });
  }, [user]);

  useEffect(() => {
    if (!user) return;
    resetUserData();
  }, [user, resetUserData]);

  const handleInfoUpdate = () => {
    if (formRef.current === null) return;
    formRef.current.submit.click();
  };

  const handleBlur = (e) => {
    const { name, value } = e.currentTarget;
    
    if (name === "currentPassword") {
      if (!value.trim()) {
        setInputError({ currentPassword: pageData.validateCurrentPassword.Message });
      } else {
        setInputError({ currentPassword: "" });
      }
    }

    if (name === "password") {
      if (!value.trim()) {
        setInputError({ password: pageData.validatePassword.Message });
      } else {
        setInputError({ password: "" });
      }
    }

    if (name === "passwordConfirmation") {
      if(inputNewPasswordRef.current) {
        const pass = inputNewPasswordRef.current.value
        if (!value.trim() || (value.trim() !== pass.trim())) {
          setInputError({ passwordConfirmation: pageData.validatePasswordConfirmation.Message });
        } else {
          setInputError({ passwordConfirmation: "" });
        }
      }
    }
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();

    const { currentPassword, password, passwordConfirmation } = Object.fromEntries(new FormData(e.currentTarget));

    doUpdatePassword({ currentPassword, password, passwordConfirmation });

    if (formUpdatePasswordRef.current) formUpdatePasswordRef.current.reset();
  }

  const handleDiscardChanges = () => {
    setIsEditingInfo(false);
    resetUserData();
  };

  function handleDeleteAccount() {
    doDeleteAccount();
  }

  const toggleIsEditingInfo = () => {
    setIsEditingInfo((prev) => !prev);
  };
  
  if (!user || isPending) return <Loading />;

  const isAtInvoiceTab = currentTab === invoicingId;

  const modalData = pageData.DeleteAccountPopUp;

  const deleteUserModal = (
    <Modal
      id={deleteUserModalId}
      title={modalData.Title}
      position="center"
      footer={{
        close: modalData.Cancel,
        confirm: {
          text: modalData.Confirm,
          color: "btn-danger",
          onClick: handleDeleteAccount,
        },
      }}
    >
      <p>{modalData.Description}</p>
    </Modal>
  );

  return (
    <>
      {deleteUserModal}
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="page-title-box">
              {isAtInvoiceTab && (
                <div className="page-title-right">
                  {isEditingInfo ? (
                    <>
                      <button
                        type="button"
                        onClick={handleInfoUpdate}
                        className="btn btn-primary float-end"
                      >
                        <i className="mdi mdi-content-save-check me-2"></i>
                        {pageData.btnSaveText}
                      </button>
                      <button
                        type="button"
                        onClick={handleDiscardChanges}
                        className="btn btn-secondary float-end me-2"
                      >
                        <i className="mdi mdi-pencil-off me-2"></i>
                        {pageData.btnCancelText}
                      </button>
                    </>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary float-end"
                      onClick={toggleIsEditingInfo}
                    >
                      <i className="mdi mdi-pencil me-2"></i>
                      {pageData.btnEditText}
                    </button>
                  )}
                </div>
              )}
              <h4 className="page-title">{pageData.Title}</h4>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <Tabs>
              <Tab
                title={pageData.tabSystemTitle}
                active={true}
                id={systemId}
                setTab={setCurrentTab}
              />
              <Tab
                title={pageData.tabAccountTitle}
                active={false}
                id={accountId}
                setTab={setCurrentTab}
              />
              <Tab
                title={pageData.tabInvoicesTitle}
                active={false}
                id={invoicingId}
                setTab={setCurrentTab}
              />
            </Tabs>
            <TabContent>
              <TabPane id={systemId} active={true}>
                <h5 className="mb-3 text-uppercase">{pageData.tabContentSystemTitle}</h5>
                <Group>
                  <Label htmlFor="language">{pageData.Language.Label}</Label>
                  <SelectComponent
                    id="language"
                    placeholder={pageData.Language.Title}
                    options={pageData.Language.Options.map((o) => ({
                      value: o.Value,
                      label: o.Text,
                    }))}
                  />
                </Group>
                <h5 className="mb-3 text-uppercase">{pageData.themeTitle}</h5>
                <ThemeSelector pageData={pageData} />
              </TabPane>
              <TabPane id={accountId}>
                <h5 className="mb-3 text-uppercase">{pageData.tabContentAccountTitle}</h5>
                <form
                  onSubmit={handleSubmit}
                  ref={formUpdatePasswordRef}
                >
                  <Group>
                    <Label htmlFor="currentPassword">
                      {pageData.ChangePassword.Label}
                    </Label>
                    <Input
                      type="password"
                      id="currentPassword"
                      name="currentPassword"
                      placeholder={pageData.ChangePassword.Placeholder}
                      onBlur={handleBlur}
                      error={inputError.currentPassword}
                    />
                  </Group>
                  <div className="row">
                    <Group columns={2}>
                      <Label htmlFor="password">
                        {pageData.NewPassword.Placeholder}
                      </Label>
                      <Input
                        ref={inputNewPasswordRef}
                        type="password"
                        id="password"
                        name="password"
                        placeholder={pageData.NewPassword.Placeholder}
                        onBlur={handleBlur}
                        error={inputError.password}
                      />
                    </Group>

                    <Group columns={2}>
                      <Label htmlFor="passwordConfirmation">
                        {pageData.labelPasswordConfirmationTitle}
                      </Label>
                      <Input
                        type="password"
                        id="passwordConfirmation"
                        name="passwordConfirmation"
                        placeholder={pageData.NewPassword.Placeholder}
                        onBlur={handleBlur}
                        error={inputError.passwordConfirmation}
                      />
                    </Group>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    {pageData.btnChangePasswordTitle}
                  </button>
                </form>
                <h5 className="my-3 text-uppercase">{pageData.accountStateTitle}</h5>

                <div className="button-list">
                  <Link to="/profile" className="btn btn-secondary">
                    {pageData.btnGoToProfileTitle}
                  </Link>
                  <button
                    type="button"
                    data-bs-target={`#${deleteUserModalId}`}
                    data-bs-toggle="modal"
                    className="btn btn-outline-danger"
                  >
                    <i className="mdi mdi-delete me-2"></i>{pageData.btnDeleteAccountTitle}
                  </button>

                </div>
              </TabPane>
              <TabPane id={invoicingId}>
                <Elements options={{ appearance }} stripe={stripePromise}>
                  <StripeElements
                    pageData={pageData}
                    data={data}
                    setData={setData}
                    isEditingInfo={isEditingInfo}
                    setIsEditingInfo={setIsEditingInfo}
                    ref={formRef}
                  />
                </Elements>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
