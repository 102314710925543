import { Pagination, Grid } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// Utils Imports
import { LOCAL } from "../../../constant";

const QrTypeSwiper = ({ cards }) => {
  const getDividedCards = (cards) => {
    const chunkSize = 6;
    const dividedCards = [];

    for (let i = 0; i < cards.length; i += chunkSize) {
      dividedCards.push(cards.slice(i, i + chunkSize));
    }

    return dividedCards;
  }

  const cardRows = getDividedCards(cards).map((row, index) => {
    const swiperCards = row.map(card => {

      const title = card.Title;
      const description = card.Description || "";
      const image = LOCAL + card.Image.data.attributes.url;

      return (
        <QrTypeCard key={`type-card-${title}`} title={title} description={description} image={image} />
      )
    });

    return (
      <SwiperSlide key={`type-row-${index}`}>
        <div className="row">
          {swiperCards}
        </div>
      </SwiperSlide>
    );
  });

  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={30}
      loop={true}
      grabCursor={true}
      pagination={{
        clickable: true,
      }}
      modules={[Grid, Pagination]}
    >
      {cardRows}
    </Swiper>
  )
}

export default QrTypeSwiper;

const QrTypeCard = ({ title, description, image }) => {
  return (
    <div className="col-lg-4 col-md-6">
      <div className="text-center p-2 p-sm-3">
        <div className="avatar-sm m-auto">
          <span className="avatar-title bg-primary-lighten rounded-circle">
            <img src={image} alt="" className="qr-type-image" />
          </span>
        </div>
        <h4 className="mt-3">{title}</h4>
        <p className="text-muted mt-2 mb-0">
          {description}
        </p>
      </div>
    </div>
  );
}