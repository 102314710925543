import makeRequest from "../../dataFetching"
import { API_HOME, INFO_MENU } from "../../../utils/constants";
import { findHomePageMapping } from "../../dataMapping/homePage";
import { infoMenuMapping } from "../../dataMapping/infoMenu";
/**
 * FIND
 * Fetches all HomePage entries.
 * @returns {Promise<Object>} A promise that resolves to the response data from the GET request.
 */
export async function findHomePage () {
  const responseHome = await makeRequest(API_HOME);
  const responseInfoMenu = await makeRequest(INFO_MENU);
  
  const mappedHome = findHomePageMapping(responseHome);
  const mappedInfoMenu = infoMenuMapping(responseInfoMenu);

  return { 
    pageData: mappedHome,
    infoCardData: mappedInfoMenu
  }
}

/**
 * CREATE LOCALIZATION
 * Creates a new HomePage localization entry.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function createLocalizationHomePage ({}) {}


/**
 * PUT
 * Updates an existing HomePage entry.
 * @param {integer} id - The ID of the HomePage entry to update.
 * @returns {Promise<Object>} A promise that resolves to the response data from the PUT request.
 */
export function updateHomePage (id, {}) {}


/**
 * DELETE
 * Deletes a specific HomePage entry by ID.
 * @param {integer} id - The ID of the HomePage entry to delete.
 * @returns {Promise<Object>} A promise that resolves to the response data from the DELETE request.
 */
export function deleteHomePage ({ id }) {}
