import { Group, Label, Input, Switch, SelectComponent } from "../Form";

export default function WifiForm({
  wifi,
  setWifi,
  pageData,
  errors,
  setErrors,
}) {
  const handleWifiValueChange = (event) => {
    setWifi((old) => {
      return {
        ...old,
        [event.target.name]: event.target.value,
      };
    });
    if (errors.wifiName) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        wifiName: undefined, // ou use null
      }));
    }
    if (errors.wifiPassword) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        wifiPassword: undefined, // ou use null
      }));
    }
  };
  const handleIncriptionChange = (selected) => {
    setWifi((old) => {
      return {
        ...old,
        wifiIncription: selected.value,
      };
    });
    if (errors.wifiIncription) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        wifiIncription: undefined, // ou use null
      }));
    }
  };

  return (
    <div className="createQR--form">
      <div className="row">
        <div className="col-lg-6">
          <Group>
            <Label>{pageData.WifiName.Label}</Label>
            <Input
              disabled={false}
              onChange={handleWifiValueChange}
              value={wifi.wifiName}
              name="wifiName"
              placeholder={pageData.WifiName.Placeholder}
              error={errors.wifiName}
            />
          </Group>
        </div>

        <div className="col-lg-6">
          <Group>
            <Label>{pageData.WifiPassword.Label}</Label>
            <Input
              disabled={false}
              type={"password"}
              onChange={handleWifiValueChange}
              value={wifi.wifiPassword}
              name="wifiPassword"
              placeholder={pageData.WifiPassword.Placeholder}
              error={errors.wifiPassword}
            />
          </Group>
        </div>
      </div>
      <div className="row">
        <Group>
          <Label>{pageData.EncryptionType.Label}</Label>
          <SelectComponent
            id={"encription"}
            placeholder={pageData.EncryptionType.Placeholder}
            options={pageData.EncryptionType.Options.map((o) => ({
              label: o.Text,
              value: o.Value,
            }))}
            onChange={handleIncriptionChange}
            className={errors.wifiIncription ? "is-invalid-select" : ""}
          />
          {errors.wifiIncription && (
            <div className="invalid-feedback">{errors.wifiIncription}</div>
          )}
        </Group>
        <div className="createQR--checkbox-container">
          <Group>
            <div className="row">
              <Label className="ms-2" htmlFor={"hideWifi"}>
                {pageData.HiddenWifi}
              </Label>
            </div>
            <Switch
              id={"hideWifi"}
              name="hideWifi"
              onLabel="Sim"
              offLabel="Não"
              checked={wifi.isHidden}
              onChange={() =>
                // Corrigido aqui
                setWifi((old) => {
                  return {
                    ...old,
                    isHidden: !old.isHidden,
                  };
                })
              }
            />
          </Group>
        </div>
      </div>
    </div>
  );
}
