import makeRequest from "../../dataFetching"
import { API_PROFILE_PAGE, INFO_MENU } from "../../../utils/constants";
import { findProfilePageMapping } from "../../dataMapping/profilePage";
import { infoMenuMapping } from "../../dataMapping/infoMenu";
/**
 * FIND
 * Fetches all ProfilePage entries.
 * @returns {Promise<Object>} A promise that resolves to the response data from the GET request.
 */
export async function findProfilePage () {
  const responseProfilePage = await makeRequest(API_PROFILE_PAGE);
  const responseInfoMenu = await makeRequest(INFO_MENU);
  
  const mappedProfilePage = findProfilePageMapping(responseProfilePage);
  const mappedInfoMenu = infoMenuMapping(responseInfoMenu);

  return { 
    pageData: mappedProfilePage,
    infoCardData: mappedInfoMenu
  }
}

/**
 * CREATE LOCALIZATION
 * Creates a new ProfilePage localization entry.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function createLocalizationProfilePage ({}) {}


/**
 * PUT
 * Updates an existing ProfilePage entry.
 * @param {integer} id - The ID of the ProfilePage entry to update.
 * @returns {Promise<Object>} A promise that resolves to the response data from the PUT request.
 */
export function updateProfilePage (id, {}) {}


/**
 * DELETE
 * Deletes a specific ProfilePage entry by ID.
 * @param {integer} id - The ID of the ProfilePage entry to delete.
 * @returns {Promise<Object>} A promise that resolves to the response data from the DELETE request.
 */
export function deleteProfilePage ({ id }) {}
