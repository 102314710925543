import "../../styles/pages/Home.css";

// Components Imports
import QRCodeCreator from "../../components/QRCodeCreator";
import InfoCard from "../../components/InfoCard";
import QrTypeSwiper from "./components/QrTypeSwiper";
import { Card, Section, Hero, HeroButton } from "../../components/Landing";
import Loading from "../../components/Loading";

// Utils Imports
import { LOCAL } from "../../constant";

// Hooks Imports
import usePagesData from "../../hooks/pageData/usePagesData";
import { PageTypes } from "../../hooks/pageData/PageTypes";
import { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    const adjustIframeHeight = () => {
      const height = document.body.scrollHeight; // Altura total do conteúdo
      window.parent.postMessage(height, "*"); // Envia a altura para o pai
    };

    // Chama a função para ajustar a altura
    adjustIframeHeight();

    // Se o conteúdo pode mudar, você pode querer chamar isso novamente
    // por exemplo, usando MutationObserver ou outro evento
    window.addEventListener("resize", adjustIframeHeight);
    return () => {
      window.removeEventListener("resize", adjustIframeHeight);
    };
  }, []);
  const simulationSectionId = "simulate";

  const { pageData: pageContent, isPending } = usePagesData({
    pageType: PageTypes.HOME,
  });

  if (isPending) return <Loading />;

  const pageData = pageContent.pageData;
  const infoCardData = pageContent.infoCardData;

  const contentSection_4_cards = pageData.ContentSection_4.Cards.map((card) => {
    const title = card.Title;
    const description = card.Description;
    const image = LOCAL + card.Image.data.attributes.url;
    return (
      <div key={`feature-card-${title}`} className="col-md-6 col-lg-4 h-100">
        <Card title={title} description={description} image={image} />
      </div>
    );
  });

  const contentSection_5_cards = pageData.ContentSection_5.Cards.reduce(
    (acc, card, index) => {
      const title = card.Title;
      const description = card.Description;
      const image = LOCAL + card.Image.data.attributes.url;

      const item = (
        <div key={`feature2-card-${title}`}>
          <div className="float-start">
            <img src={image} alt="" />
          </div>
          <h4 className="faq-question text-body">{title}</h4>
          <p className="faq-answer mb-4 pb-1 text-muted">{description}</p>
        </div>
      );

      if (index % 2 === 0) {
        acc.push([item]);
      } else {
        acc[acc.length - 1].push(item);
      }

      return acc;
    },
    []
  ).map((items, index) => (
    <div key={`group-${index}`} className="col-lg-5 offset-lg-1">
      {items}
    </div>
  ));

  const contentSection_6_Image =
    LOCAL + pageData.ContentSection_6.PhoneImage.data.attributes.url;
  const contentSection_7_Image =
    LOCAL + pageData.ContentSection_7.Image.data.attributes.url;

  return (
    <>
      {/* <Hero
        title={`${pageData.Header.Title}`}
        subtitle={`${pageData.Header.Subtitle}`}
        image={LOCAL + pageData.Header.Image.data.attributes.url}
      >
        <HeroButton
          id={simulationSectionId}
          text={pageData.Header.ButtonText}
          icon={pageData.Header.ButtonIconClass}
        />
      </Hero>
      <Section
        isFirstSection={true}
        titles={pageData.HeaderSection_1.Title.map((t) => t.Text)}
        descriptions={[pageData.HeaderSection_1.Subtitle]}
        icon={pageData.HeaderSection_1.Icon}
      >
        <QrTypeSwiper cards={pageData.ContentSection_1.Cards} />
      </Section>
      <Section
        titles={pageData.HeaderSection_2.Title.map((t) => t.Text)}
        descriptions={[pageData.HeaderSection_2.Subtitle]}
        icon={pageData.HeaderSection_2.Icon}
      >
        <div className="row mt-2 py-5 align-items-center">
          <div className="col-lg-5 col-md-6">
            <img src={LOCAL + pageData.ContentSection_2.Image.data.attributes.url} className="img-fluid" alt="" />
          </div>
          <div className="col-lg-6 offset-md-1 col-md-5">
            <h3 className="fw-normal">{pageData.ContentSection_2.Title}</h3>
            <p className="text-muted mt-3 mb-3">{pageData.ContentSection_2.Text}</p>
            {pageData.ContentSection_2.ListaPontos.map((l) => {
              return (
                <p className="text-muted" key={l.id}>
                  <i className="mdi mdi-circle-medium text-primary"></i>
                  {l.Text}
                </p>
              );
            })}
          </div>
        </div>

        <div className="row pb-3 pt-5 align-items-center">
          <div className="col-lg-6 col-md-5">
            <h3 className="fw-normal">{pageData.ContentSection_3.Title}</h3>
            <p className="text-muted mt-3 mb-3">{pageData.ContentSection_3.Text}</p>

            {pageData.ContentSection_3.ListaPontos.map((l) => {
              return (
                <p className="text-muted" key={l.id}>
                  <i className="mdi mdi-circle-medium text-primary"></i>
                  {l.Text}
                </p>
              );
            })}
          </div>
          <div className="col-lg-5 col-md-6 offset-md-1">
            <img src={LOCAL + pageData.ContentSection_3.Image.data.attributes.url} className="img-fluid" alt="" />
          </div>
        </div>
      </Section>
      <Section
        titles={pageData.HeaderSection_4.Title.map((t) => t.Text)}
        descriptions={[pageData.HeaderSection_4.Subtitle]}
        icon={pageData.HeaderSection_4.Icon}
      >
        <div className="row mt-2 py-5 align-items-stretch">
          {contentSection_4_cards}
        </div>
      </Section>
      <Section
        titles={pageData.HeaderSection_5.Title.map((t) => t.Text)}
        titleStartsPrimary={true}
        icon={pageData.HeaderSection_5.Icon}
      >
        <div className="row mt-5">{contentSection_5_cards}</div>
      </Section> */}
      <Section
         titles={pageData.HeaderSection_6.Title.map((t) => t.Text)}
        descriptions={[pageData.HeaderSection_6.Subtitle]}
        titleStartsPrimary={true}
         icon={pageData.HeaderSection_6.Icon}
        navigationId={simulationSectionId}
      >
        <div className="row">
          <QRCodeCreator
            createMode="simulate"
            img={contentSection_6_Image}
            content={pageData.ContentSection_6}
          />
        </div>
      </Section>
      {/* <Section>
        <div className="row pb-3 pt-5 align-items-center">
          <div className="col-lg-6 col-md-5">
            <h3 className="fw-normal">{pageData.ContentSection_7.Title}</h3>
            <p className="text-muted mt-3 mb-3">{pageData.ContentSection_7.Text}</p>
            {pageData.ContentSection_7.ListaPontos.map((l) => {
              return (
                <p className="text-muted" key={l.id}>
                  <i className="mdi mdi-circle-medium text-primary"></i>
                  {l.Text}
                </p>
              );
            })}
          </div>
          <div className="col-lg-5 col-md-6 offset-md-1 text-center">
            <img
              src={contentSection_7_Image}
              className="img-fluid"
              style={{ maxHeight: "350px" }}
              alt=""
            />
          </div>
        </div>
      </Section>
      <InfoCard infoCardData={infoCardData} /> */}
    </>
  );
};

export default Home;
