import { FetchingError } from "../../errors"

export function currentUserMapping (response) {
  if (!response) throw new FetchingError("Response not found");
  return {...response}
}

export function currentUserDeleteMapping (response) {
  if (!response) throw new FetchingError("Response not found");
  return {...response}
}