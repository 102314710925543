import makeRequest from "../../dataFetching"
import { API_PAYMENTS_PAGE } from "../../../utils/constants";
import { findPaymentsPageMapping } from "../../dataMapping/paymentsPage";
/**
 * FIND
 * Fetches all PaymentsPage entries.
 * @returns {Promise<Object>} A promise that resolves to the response data from the GET request.
 */
export function findPaymentsPage () {
  return makeRequest(API_PAYMENTS_PAGE)
  .then(findPaymentsPageMapping);
}

/**
 * CREATE LOCALIZATION
 * Creates a new PaymentsPage localization entry.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function createLocalizationPaymentsPage ({}) {}


/**
 * PUT
 * Updates an existing PaymentsPage entry.
 * @param {integer} id - The ID of the PaymentsPage entry to update.
 * @returns {Promise<Object>} A promise that resolves to the response data from the PUT request.
 */
export function updatePaymentsPage (id, {}) {}


/**
 * DELETE
 * Deletes a specific PaymentsPage entry by ID.
 * @param {integer} id - The ID of the PaymentsPage entry to delete.
 * @returns {Promise<Object>} A promise that resolves to the response data from the DELETE request.
 */
export function deletePaymentsPage ({ id }) {}
