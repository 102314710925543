import { useState, useEffect } from "react";
import { getScansData } from "../../utils/lib"

export default function useAllScans (qrCodes) {
  const [AllScans, setAllScans] = useState(null);

  useEffect(() => {
    if (!qrCodes) return;

    setAllScans(getScansData(qrCodes));
  }, [qrCodes]);

  return { AllScans };
}