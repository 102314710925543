import { Link } from "react-router-dom";

export function Tab({ title, active, id, setTab }) {
  const expanded = active ? "true" : "false";
  const activeClass = active ? "active" : "";

  const handleClick = () => {
    setTab(id);
  }

  return (
    <li className="nav-item" onClick={handleClick}>
      <Link to={`#${id}`} data-bs-toggle="tab" aria-expanded={expanded} className={`nav-link rounded-0 ${activeClass}`}>
        {title}
      </Link>
    </li>
  );
}