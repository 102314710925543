const createErrorFactory = function (name) {
  return class BusinessError extends Error {
    constructor (message) {
      super(message);
      this.name = name;
    }
  }
}

export const ConnectionError = createErrorFactory('ConnectionError');
export const FetchingError = createErrorFactory('FetchingError');
export const UploadError = createErrorFactory('UploadError');
export const ValidationError = createErrorFactory('ValidationError');