import React from 'react';

export function InvoicePreview({ invoiceDetails }) {
  const fullName = `${invoiceDetails.firstName} ${invoiceDetails.lastName}`;

  return (
    <address className='mb-0 address-lg d-flex flex-column'>
      <span className="fw-bold">{invoiceDetails.isParticular ? fullName : invoiceDetails.username}</span>
      <span>{invoiceDetails.nif}</span>
      <span>{invoiceDetails.email}</span>
      <span>{invoiceDetails.address} - {invoiceDetails.address2}</span>
      <span>{invoiceDetails.postalCode}</span>
      <span>{invoiceDetails.city}, {invoiceDetails.country}</span>
    </address>
  );
}