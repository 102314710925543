const stateTypes = {
  APPROVED: "APPROVED",
  NOT_APPROVED: "NOT_APPROVED",
  PENDING: "PENDING",
};

const sortTypes = {
  MOST_RECENT: "MOST_RECENT",
  NAME: "NAME",
  MOST_SCANNED: "MOST_SCANNED",
}

export {
  sortTypes, stateTypes
};
