export function StatusCard({ label, value, icon, footer = { show: false, label: "", value: "" } }) {
  return (
    <div className="card tilebox-one">
      <div className="card-body">
        <i className={`mdi ${icon} float-end`}></i>
        <h6 className="text-uppercase mt-0">{label}</h6>
        <h2 className="my-2">
          {value}
        </h2>
        {
          footer.show &&
          <p className="mb-0 text-muted">
            <span className="me-1">{footer.label}</span>
            <span className="text-nowrap">{footer.value}</span>
          </p>
        }
      </div>
    </div>
  );
}