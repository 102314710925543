import makeRequest from "../../dataFetching";
import { API_SETTINGS_PAGE } from "../../../utils/constants";
import { findSettingsPageMapping } from "../../dataMapping/settingsPage";

/**
 * FIND
 * Fetches all SettingsPage entries.
 * @returns {Promise<Object>} A promise that resolves to the response data from the GET request.
 */
export function findSettingsPage () {
  return makeRequest(API_SETTINGS_PAGE)
  .then(findSettingsPageMapping);
}

/**
 * CREATE LOCALIZATION
 * Creates a new SettingsPage localization entry.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function createLocalizationSettingsPage ({}) {}


/**
 * PUT
 * Updates an existing SettingsPage entry.
 * @param {integer} id - The ID of the SettingsPage entry to update.
 * @returns {Promise<Object>} A promise that resolves to the response data from the PUT request.
 */
export function updateSettingsPage (id, {}) {}


/**
 * DELETE
 * Deletes a specific SettingsPage entry by ID.
 * @param {integer} id - The ID of the SettingsPage entry to delete.
 * @returns {Promise<Object>} A promise that resolves to the response data from the DELETE request.
 */
export function deleteSettingsPage ({ id }) {}
