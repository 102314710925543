import { FetchingError } from "../../errors";

export function loginMapping(response) {
  if (!response) throw new FetchingError("Response not found");
  return {...response}
}

export function registerMapping(response) {
  if (!response) throw new FetchingError("Response not found");
  return {...response}
}

export function updatePasswordMapping(response) {
  if (!response) throw new FetchingError("Response not found");
  return {...response}
}

export function recoveryPasswordMapping(response) {
  if (!response) throw new FetchingError("Response not found");
  return {...response}
}

export function updatePasswordTokenMapping(response) {
  if (!response) throw new FetchingError("Response not found");
  return {...response}
}