import { FetchingError } from "../../errors";

export function qrCodeFindScansMapping (response) {
  if (!response) throw new FetchingError("Response not found");

  const mapped = response.data.map(qrCode => { 
    return { 
      id: qrCode.id, 
      ...qrCode.attributes 
    } 
  });

  return mapped;
}

export function findQrCodeScansFilesMapping (response) {
  if (!response) throw new FetchingError("Response not found");

  const attributes = response.data.attributes

  return {
    image: attributes?.image?.data?.at(0)?.id,
    mediaField: attributes?.mediaField?.data?.at(0)?.id,
    links: attributes?.qr_code_linktrees?.data?.map(link => link.id),
    scans: attributes?.scans?.data?.map(scan => scan.id)
  }
}

export function deleteScanMapping (response) {
  if (!response) throw new FetchingError("Response not found");
  return { ...response };
}
