import makeRequest from "../../dataFetching"
import { API_QR_CODE_DETAILS_PAGE } from "../../../utils/constants";
import { findQrCodeDetailsPageMapping } from "../../dataMapping/qrCodeDetailsPage";
/**
 * FIND
 * Fetches all QrCodeDetailsPage entries.
 * @returns {Promise<Object>} A promise that resolves to the response data from the GET request.
 */
export function findQrCodeDetailsPage () {
  return makeRequest(API_QR_CODE_DETAILS_PAGE)
  .then(findQrCodeDetailsPageMapping);
}

/**
 * CREATE LOCALIZATION
 * Creates a new QrCodeDetailsPage localization entry.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function createLocalizationQrCodeDetailsPage ({}) {}


/**
 * PUT
 * Updates an existing QrCodeDetailsPage entry.
 * @param {integer} id - The ID of the QrCodeDetailsPage entry to update.
 * @returns {Promise<Object>} A promise that resolves to the response data from the PUT request.
 */
export function updateQrCodeDetailsPage (id, {}) {}


/**
 * DELETE
 * Deletes a specific QrCodeDetailsPage entry by ID.
 * @param {integer} id - The ID of the QrCodeDetailsPage entry to delete.
 * @returns {Promise<Object>} A promise that resolves to the response data from the DELETE request.
 */
export function deleteQrCodeDetailsPage ({ id }) {}
