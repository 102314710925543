import makeRequest from "../../dataFetching"
import { API_SUPPORT, INFO_MENU } from "../../../utils/constants";
import { findSupportPageMapping } from "../../dataMapping/supportPage";
import { infoMenuMapping } from "../../dataMapping/infoMenu";
/**
 * FIND
 * Fetches all SupportPage entries.
 * @returns {Promise<Object>} A promise that resolves to the response data from the GET request.
 */
export async function findSupportPage () {
  const responseSupport = await makeRequest(API_SUPPORT);
  const responseInfoMenu = await makeRequest(INFO_MENU);
  
  const mappedSupport = findSupportPageMapping(responseSupport);
  const mappedInfoMenu = infoMenuMapping(responseInfoMenu);

  return { 
    pageData: mappedSupport,
    infoCardData: mappedInfoMenu
  }
}

/**
 * CREATE LOCALIZATION
 * Creates a new SupportPage localization entry.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function createLocalizationSupportPage ({}) {}


/**
 * PUT
 * Updates an existing SupportPage entry.
 * @param {integer} id - The ID of the SupportPage entry to update.
 * @returns {Promise<Object>} A promise that resolves to the response data from the PUT request.
 */
export function updateSupportPage (id, {}) {}


/**
 * DELETE
 * Deletes a specific SupportPage entry by ID.
 * @param {integer} id - The ID of the SupportPage entry to delete.
 * @returns {Promise<Object>} A promise that resolves to the response data from the DELETE request.
 */
export function deleteSupportPage ({ id }) {}
