import { Link } from 'react-router-dom';

import "../../styles/components/SupportCards.css";

export function SupportCards({ cards }) {
  const supportCards = cards.map(card => {
    return (
      <SupportCard
        key={`support-card-${card.url}`}
        image={card.image}
        title={card.title}
        description={card.description}
        url={card.url}
      />
    );
  });

  return (
    <div className="row g-4 my-4 mx-2">
      {supportCards}
    </div>
  );
}

function SupportCard({ image, title, description, url }) {
  return (
    <div className="col-md-3">
      <Link to={url} className="d-block p-3 rounded border border-2 support-card h-100">
        <img src={image} className="img-fluid py-2 support-card-image" alt="" />
        <div>
          <h5 className="text-primary">
            {title}
          </h5>
          <p className="text-black-50">
            {description}
          </p>
        </div>
      </Link>
    </div>
  );
}