import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import "../../styles/pages/Statistics.css";

// Components Imports
import { Group, Input, Label, RangeDate, Switch } from "../../components/Form";
import { Editing, Statistics } from "./components";
import QRPreview from "../../components/QRPreview";
import Loading from "../../components/Loading";
import Modal from "../../components/Modal";
import Card from "../../components/Card";

// Hooks Imports
import { useAuthContext } from "../../context/AuthContext";
import useQrCodes from "../../hooks/qrCode/useQrCodes";
import usePagesData from "../../hooks/pageData/usePagesData";

// Utils Imports
import { API } from "../../constant";
import { getToken } from "../../helpers";
import { roles } from "../../constants/qrCode";
import { countUniqueScans, toast } from "../../utils";
import { PageTypes } from "../../hooks/pageData/PageTypes";

const deleteQrCodeModalId = "deleteQrCodeModal";

const QRDetails = () => {
  const QrCodeId = useParams().id;

  const { doGetQrCodes, doUpdateQrCode, doDeleteQrCode, qrCode, urlPreference, qrCodeDataUpdated, wifi, setQrCode, setUrlPreference, setWifi, setQrCodeDataUpdated } = useQrCodes();

  const { user } = useAuthContext();
  const [userRole, setUserRole] = useState('');
  const [isEditingQrCode, setIsEditingQrCode] = useState(false);
  const [files, setFiles] = useState("")
  const [linkList, setLinkList] = useState([]);

  const [shouldDownload, setShouldDownload] = useState(false)
  const [shouldCopy, setShouldCopy] = useState(false)
  const [shouldPrint, setShouldPrint] = useState(false)

  const closeDeleteQrCodeModalRef = useRef(null)

  const navigate = useNavigate();

  const acceptsUrl = ["Vcard", "Negócio", "Menu", "Páginas Web", "Cupon", "Apps", "Página de Destino", "Vídeo", "Lista de Reprodução"].includes(qrCode?.type);
  const canHaveFileOrUrl = ["Vídeo", "Menu", "Cupon"].includes(qrCode?.type);

  function cleanUrlOrFile(dirtyQrCode) {
    if (!urlPreference)
      return { ...dirtyQrCode, url: "" };

    return { ...dirtyQrCode, mediaField: { data: [] } };
  }

  useEffect(() => {
    doGetQrCodes({ qrCodeId: QrCodeId });
  }, [QrCodeId, navigate]);

  useEffect(() => {
  }, [qrCodeDataUpdated]);

  useEffect(() => {

    if (!qrCode) return;

    setUserRole(qrCode.role);

  }, [qrCode, user]);

  useEffect(() => {
    if (!qrCode) return;

    if (qrCode.type === "Lista de Links") {
      setQrCodeDataUpdated(prev => ({ ...prev, qr_code_linktrees: linkList }));
    }
  }, [linkList, qrCode])

  const handleTextChange = (event) => {
    setQrCodeDataUpdated((old) => {
      return {
        ...old,
        [event.target.name]: event.target.value,
      };
    });
  };

  const countNumberOfScans = (scans) => {
    if (Array.isArray(scans)) {
      return scans.length;
    } else {
      return 0;
    }
  }

  const [stateCoolDown, setStateCoolDown] = useState(false);
  const scans = qrCode?.scans.data;

  useEffect(() => {
    if (!qrCode) return;

    if (qrCode.type === "MP3" || qrCode.type === "PDF" || qrCode.type === "Imagens" || (qrCode.type === "Vídeo" && urlPreference === false) || (qrCode.type === "Menu" && urlPreference === false) || (qrCode.type === "Cupon" && urlPreference === false)) {
      setQrCodeDataUpdated((prevQrCodeDataUpdated) => {
        return { ...prevQrCodeDataUpdated, mediaField: files }
      });
    }

  }, [files, urlPreference, qrCode])

  const { pageData, isPending: pageIsLoading, isError } = usePagesData({ pageType: PageTypes.QR_CODE_DETAILS });

  if (!qrCode || !qrCodeDataUpdated || !QrCodeId || pageIsLoading || isError) return <Loading />;

  const handleQrCodeUpdate = async () => {
    doUpdateQrCode({ qrCodeId: QrCodeId });
  };

  function handleDeleteQR() {
    doDeleteQrCode({ currentQrCode: qrCode });
    if (closeDeleteQrCodeModalRef.current) closeDeleteQrCodeModalRef.current.click();
  }

  const handleEditQR = () => {
    if (userRole !== "owner" && userRole !== "admin") {
      toast(pageData.OnlyAdminsCanEditSharedCodes)
      return
    }

    if (qrCode.static) {
      toast(pageData.StaticQRCodesCanNotBeEdited);
      return;
    }

    setQrCodeDataUpdated(qrCode)
    setIsEditingQrCode(prev => !prev);
  }

  const handleCampaignStartDateChange = (e) => {
    setQrCodeDataUpdated({ ...qrCodeDataUpdated, startDate: e.target.value });
  };
  const handleCampaignEndDateChange = (e) => {
    setQrCodeDataUpdated({ ...qrCodeDataUpdated, endDate: e.target.value });
  };
  const handleCheckChange = (event) => {
    if (qrCode.approval === "Pending" || qrCode.approval === "Not Approved") {
      toast(pageData.QRCodeNeedsApproval);
      return
    }

    if (stateCoolDown) {
      toast(pageData.WaitAMoment);
      return
    }

    setQrCode((old) => {
      return {
        ...old,
        [event.target.name]: event.target.checked
      }
    }, updateState()); 
    
    async function updateState() {
      try {
        setStateCoolDown(true);
        const response = await fetch(`${API}/qr-codes/${QrCodeId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify({
              data: {
                state: !qrCode.state,
              },
            }),
          }
        );
        await response.json();
        setTimeout(() => setStateCoolDown(false), 3000);
      } catch (error) {
      }
    }
  };

  const deletePopUp = pageData.DeletePopUp;

  const deleteModal = (
    <Modal
      ref={closeDeleteQrCodeModalRef}
      id={deleteQrCodeModalId}
      position="center"
      title={deletePopUp.Title}
      footer={
        {
          confirm: {
            text: deletePopUp.Confirm,
            onClick: handleDeleteQR,
            color: "btn-danger"
          },
          close: deletePopUp.Cancel
        }
      }
    >
      <p>{deletePopUp.Description}</p>
    </Modal>
  )
  const userRoleLabel = userRole === roles.OWNER ? pageData.UserRoleCreatorTitle :
    user.role === roles.ADMIN ? pageData.UserRoleAdminTitle : pageData.UserRoleCollaboratorTitle;

  return (
    <>
      {deleteModal}
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                {
                  isEditingQrCode ?
                    <>
                      <button
                        type="button"
                        onClick={handleQrCodeUpdate}
                        className="btn btn-primary float-end"
                      >
                        <i className="mdi mdi-content-save-check me-2"></i>
                        {pageData.Save}
                      </button>
                      <button
                        type="button"
                        onClick={handleEditQR}
                        className="btn btn-secondary float-end me-2"
                      >
                        <i className="mdi mdi-pencil-off me-2"></i>
                        {pageData.Discard}
                      </button>
                    </>
                    :
                    <>
                      <button className="btn btn-primary float-end" onClick={handleEditQR}>
                        <i className="mdi mdi-pencil me-2"></i>
                        {pageData.Edit}
                      </button>
                      <button
                        className="btn btn-danger float-end me-2"
                        data-bs-target={`#${deleteQrCodeModalId}`}
                        data-bs-toggle="modal"
                      >
                        <i className="mdi mdi-trash-can-outline me-2"></i>
                        {pageData.Delete}
                      </button>
                    </>
                }
              </div>
              <div className="d-flex gap-4 align-items-center">
                <h4 className="page-title">
                  {qrCode.name}
                </h4>

                <div className="d-flex gap-2 align-items-center">
                  <Switch
                    id="qr-code-state"
                    name="state"
                    checked={qrCode.state}
                    onChange={handleCheckChange}
                  />
                  <Label type="checkbox" htmlFor="qr-code-state" className="">
                    {
                      qrCode.state ?
                        pageData.Active :
                        pageData.Inactive
                    }
                  </Label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row d-flex align-items-stretch">
          <div className="col-lg-9">
            <Card>
              <div className="row">
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="d-flex flex-column align-items-center">
                    <QRPreview
                      pageData={pageData}
                      width={150}
                      height={150}
                      qrCode={qrCode}
                      shouldCopy={shouldCopy}
                      shouldDownload={shouldDownload}
                      setShouldCopy={setShouldCopy}
                      setShouldDownload={setShouldDownload}
                      shouldPrint={shouldPrint}
                      setShouldPrint={setShouldPrint}
                    />
                  </div>
                  <h5 className="text-primary text-center">{qrCode.type}</h5>
                  <div className="d-flex justify-content-between justify-content-sm-center justify-content-md-center gap-md-3 align-items-center px-4 px-md-0 mb-3 mb-md-0">
                    <button
                      className="btn px-1 btn-action"
                      onClick={() => setShouldCopy(true)}
                    >
                      <i className="mdi mdi-content-copy font-20"></i>
                    </button>
                    <button
                      className="btn px-1 btn-action"
                      onClick={() => setShouldDownload(true)}
                    >
                      <i className="mdi mdi-download font-20"></i>
                    </button>
                    <button
                      className="btn px-1 btn-action"
                      onClick={() => setShouldPrint(true)}
                    >
                      <i className="mdi mdi-printer font-20"></i>
                    </button>
                  </div>
                </div>
                <div className="col-12 col-md-8 col-lg-9">
                  <div className="row">
                    <Group columns={2}>
                      <Label htmlFor="name">{pageData.Name.Label}</Label>
                      <Input
                        label={pageData.Name.Label}
                        disabled={!isEditingQrCode}
                        value={qrCodeDataUpdated.name}
                        name="name"
                        onChange={handleTextChange}
                      />
                    </Group>

                    <Group columns={2}>
                      <Label htmlFor="description">{pageData.State.Label}</Label>
                      <Input
                        id="description"
                        disabled={true}
                        value={qrCode.state ? pageData.Active : pageData.Inactive}
                        name="description"
                        onChange={handleTextChange}
                      />
                    </Group>
                  </div>
                  <div className="row">
                    {
                      acceptsUrl &&
                      <Group columns={2}>
                        <Label htmlFor="url">{pageData.AssociatedURL.Label}</Label>
                        <Input
                          id="url"
                          className={isEditingQrCode && canHaveFileOrUrl ? "mb-3" : ""}
                          disabled={!isEditingQrCode || !urlPreference}
                          value={qrCodeDataUpdated.url}
                          name="url"
                          onChange={handleTextChange}
                        />
                        {
                          isEditingQrCode && canHaveFileOrUrl &&
                          <div>
                            <Input
                              id="url-preference"
                              type="checkbox"
                              className="me-2"
                              checked={urlPreference}
                              onChange={() => setUrlPreference(prev => !prev)}
                            />
                            <Label
                              htmlFor="url-preference"
                              type="checkbox"
                            >
                              {pageData.UseURL}
                            </Label>
                          </div>
                        }
                      </Group>
                    }
                    <Group columns={acceptsUrl ? 2 : 1}>
                      <Label htmlFor="shortLink">{pageData.ShortURL.Label}</Label>
                      <Input
                        id="short-link"
                        label={pageData.ShortURL.Label}
                        disabled={true}
                        value={`${API}/redirect/` + qrCode.identifier}
                        name="shortLink"
                        onChange={handleTextChange}
                      />
                    </Group>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-lg-3">
            <Card>
              <h4 className="header-title mb-2">
                {pageData.InformationCardTitle}
              </h4>
              <hr />
              <dl className="row mb-0">
                <dt className="col-sm-7">{pageData.CreationDate.Label}</dt>
                <dd className="col-sm-5">
                  {new Date(qrCodeDataUpdated.createdAt).toLocaleDateString()}
                </dd>
                {
                  !qrCode.static &&
                  <>
                    <dt className="col-sm-7">{pageData.LastUpdateCardTitle}</dt>
                    <dd className="col-sm-5">
                      {new Date(qrCodeDataUpdated.updatedAt).toLocaleDateString()}
                    </dd>
                  </>
                }
                <dt className="col-sm-7">{pageData.QrCodeTypeTitle}</dt>
                <dd className="col-sm-5">
                  {qrCode.static ? pageData.QrCodeStaticTitle : pageData.QrCodeDynamicTitle}
                </dd>
                <dt className="col-sm-7">{pageData.CreatedByTitle}</dt>
                <dd className="col-sm-5">
                  {qrCodeDataUpdated.owner.username}
                </dd>
                <dt className="col-sm-7">{pageData.PermissionTitle}</dt>
                <dd className="col-sm-5">
                  {userRoleLabel}
                </dd>
              </dl>
            </Card>
          </div>
        </div >

        <div className="row">
          <div className="col-12">
            <Card>
              <div className="row g-3 mb-2">
                <div className="col-6 col-md-2 text-start text-md-center">
                  {
                    qrCode.static ?
                      <i className="mdi mdi-qrcode-scan fs-2 d-block" />
                      :
                      <span className="fs-2 fw-bold d-block">{countNumberOfScans(qrCodeDataUpdated.scans.data)}</span>
                  }
                  <span className="text-primary">{pageData.Scans}</span>
                </div>
                <div className="col-6 col-md-2 text-start text-md-center">
                  {
                    qrCode.static ?
                      <i className="mdi mdi-qrcode-scan fs-2 d-block" />
                      :
                      <span className="fs-2 fw-bold d-block">{countUniqueScans(qrCodeDataUpdated.scans.data)}</span>
                  }
                  <span className="text-primary">{pageData.UniqueScansTitle}</span>
                </div>
                <div className="col-12 col-md-8">
                  <RangeDate
                    isDisabled={!isEditingQrCode}
                    min={true}
                    onChange={handleTextChange}
                    startName="startDate"
                    endName="endDate"
                    startDate={qrCodeDataUpdated.startDate}
                    endDate={qrCodeDataUpdated.endDate}
                    handleStartDateChange={handleCampaignStartDateChange}
                    handleEndDateChange={handleCampaignEndDateChange}
                    startDateLabel={pageData.CampaignStart}
                    endDateLabel={pageData.CampaignEnd}
                    pageData={pageData}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>

        {
          isEditingQrCode ?
            <Editing
              pageData={pageData}
              qrCode={qrCode}
              qrCodeDataUpdated={qrCodeDataUpdated}
              setQrCodeDataUpdated={setQrCodeDataUpdated}
              handleTextChange={handleTextChange}
              urlPreference={urlPreference}
              setFiles={setFiles}
              wifi={wifi}
              setWifi={setWifi}
              linkList={linkList}
              setLinkList={setLinkList}
            />
            :
            !qrCode.static &&
            <Statistics
              pageData={pageData}
              qrCodeDataUpdated={qrCodeDataUpdated}
            />
        }
      </div >
    </>
  );
};

export default QRDetails;
