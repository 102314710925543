export const PageTypes = Object.freeze({
  CHECKOUT_PAGE: "CHECKOUT_PAGE",
  SETTING: "SETTING",
  USERS_PAGE: "USERS_PAGE",
  HOME: "HOME",
  CREATE_QR: "CREATE_QR",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  SIGN_IN: "SIGN_IN",
  SIGN_UP: "SIGN_UP",
  MY_QR_CODES: "MY_QR_CODES",
  NOT_FOUND: "NOT_FOUND",
  CONTACT: "CONTACT",
  PAYMENTS: "PAYMENTS",
  PLANS_AND_PRICING: "PLANS_AND_PRICING",
  PROFILE_PAGE: "PROFILE_PAGE",
  STATISTICS: " STATISTICS",
  SUPPORT_CATEGORY: "SUPPORT_CATEGORY",
  SUPPORT: "SUPPORT",
  FAQ: "FAQ",
  QR_LINK_TREE_REDIRECT: "QR_LINK_TREE_REDIRECT",
  QR_CODE_DETAILS: "QR_CODE_DETAILS",
  FOOTER: "FOOTER",
  PAYMENT_PLANS: "PAYMENT_PLANS"
});