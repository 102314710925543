import makeRequest from "../../dataFetching"
import { 
  API_USERS_CURRENT, 
  API_USERS_PERMISSIONS_CURRENT
} from "../../../utils/constants";
import { currentUserMapping, currentUserDeleteMapping } from "../../dataMapping/user/current";
import { createUpload } from "../upload";


/**
 * findCurrent
 * Fetches a specific current user.
 * @returns {Promise<Object>} A promise that resolves to the response data from the GET request.
 */
export function findCurrent () {
  return makeRequest(API_USERS_CURRENT)
    .then(currentUserMapping);
}

/**
 * updateCurrent
 * Updates the current user's information including avatar, contact details, description, email, NIF, and username.
 * @param {Object} params - The parameters for updating the current user.
 * @param {Object|null} params.avatar - The avatar object of the user.
 * @param {Object|null} params.avatarFile - The file object for a new avatar to be uploaded.
 * @param {string} params.contact - The contact details of the user.
 * @param {string} params.description - The description of the user.
 * @param {string} params.email - The email address of the user.
 * @param {string} params.nif - The NIF (tax identification number) of the user.
 * @param {string} params.username - The username of the user.
 * @returns {Promise<Object>} A promise that resolves to the response data from the PUT request.
 */
export async function updateCurrent ({ avatar = null, avatarFile = null, contact = "", description = "", email, nif = "", username }) {
    if (avatarFile) {
      const uploadResponse = await createUpload(avatarFile)
        .then(res => {
          if (res && res.id) avatar = res.id;
        })
    } else {
        avatar= avatar?.id;
    }
  
    return await makeRequest(API_USERS_PERMISSIONS_CURRENT, {
      method: "PUT",
      body: {
        avatar, 
        avatarFile,
        contact,
        description,
        email,
        nif, 
        username
      }
    });
}

/**
 * deleteCurrent
 * Deletes the current user's account.
 * @returns {Promise<Object>} A promise that resolves to the response data from the DELETE request.
 */
export function deleteCurrent() {
  return makeRequest(API_USERS_PERMISSIONS_CURRENT, {
    method: "DELETE"
  })
  .then(currentUserDeleteMapping);
}
