import React from "react";

import "../../styles/pages/PageContact.css";

// Components Imports
import Loading from "../../components/Loading";
import ExternalLottie from "../../components/ExternalLottie";
import InfoCard from "../../components/InfoCard";

// Utils Imports
import { toast } from "../../utils";
import { Label, Input, TextArea, Group } from "../../components/Form";

// Hooks Imports
import { useAuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

// Services 
import { createContact } from "../../services/apiCalls/contact";
import { LottieTypes } from "../../services/apiCalls/lottie/LottieTypes";
import usePagesData from "../../hooks/pageData/usePagesData";
import { PageTypes } from "../../hooks/pageData/PageTypes";

const PageContact = () => {
  const { pageData: pageContent, isPending, isError } = usePagesData({ pageType: PageTypes.CONTACT });

  const { user, isLoading: userIsLoading } = useAuthContext();

  const navigate = useNavigate();

  if (isPending || isError || userIsLoading) return <Loading />;

  const pageData = pageContent.pageData
  const infoCardData = pageContent.infoCardData;

  const getUserDetails = () => {
    if (!user) return { name: "", email: "" };

    const name = user.firstName
      ? `${user.firstName} ${user.lastName}`
      : user.username;

    return { name, email: user.email };
  };

  const { name, email } = getUserDetails();
  
  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const payload = Object.fromEntries(formData.entries());

    createContact(payload)
    .then(() => {
      toast(pageData.MessageSentSuccessfully);
      setTimeout(() => { navigate("/"); }, 3000);
    })
    .catch((err) => {
      const { Message } = err.error
      if (Message.includes("email")) return toast(pageData.InvalidEmail);
      toast(pageData.ErrorWhileSendingMessage);
    });
  };

  return (
    <>
      <div className="hero-section hero-section-sm" />
      <div className="container">
        <div className="row row-cols-md-2">
          <div className="d-none d-md-block">
            <ExternalLottie lottieType={LottieTypes.CONTACT} />
          </div>
          <div>
            <div>
              <h3>{pageData.Title}</h3>
              <p className="text-muted mt-2">{pageData.Description}</p>
            </div>
            <form onSubmit={handleSubmit} className="">
              <div className="row g-2">
                <Group columns={2}>
                  <Label htmlFor="name">{pageData.Name.Label}</Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder={pageData.Name.Placeholder}
                    required
                    defaultValue={name}
                  />
                </Group>
                <Group columns={2}>
                  <Label htmlFor="email">{pageData.Email.Label}</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder={pageData.Email.Placeholder}
                    required
                    defaultValue={email}
                  />
                </Group>
              </div>
              <Group>
                <Label htmlFor="subject">{pageData.Subject.Label}</Label>
                <Input
                  type="text"
                  name="subject"
                  id="subject"
                  placeholder={pageData.Subject.Placeholder}
                  required
                />
              </Group>
              <Group>
                <Label htmlFor="message">{pageData.Message.Label}</Label>
                <TextArea
                  name="message"
                  id="message"
                  placeholder={pageData.Message.Placeholder}
                />
              </Group>
              <button type="submit" className="btn btn-primary">
                {pageData.Submit}
              </button>
            </form>
          </div>
        </div>
      </div>
      <InfoCard infoCardData={infoCardData} />
    </>
  );
};

export default PageContact;
