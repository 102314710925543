import makeRequest from "../../dataFetching"
import { API_SIGN_UP } from "../../../utils/constants";
import { findSignUpPageMapping } from "../../dataMapping/signUpPage";
/**
 * FIND
 * Fetches all SignUpPage entries.
 * @returns {Promise<Object>} A promise that resolves to the response data from the GET request.
 */
export function findSignUpPage () {
  return makeRequest(API_SIGN_UP)
  .then(findSignUpPageMapping);
}

/**
 * CREATE LOCALIZATION
 * Creates a new SignUpPage localization entry.
 * @returns {Promise<Object>} A promise that resolves to the response data from the POST request.
 */
export function createLocalizationSignUpPage ({}) {}


/**
 * PUT
 * Updates an existing SignUpPage entry.
 * @param {integer} id - The ID of the SignUpPage entry to update.
 * @returns {Promise<Object>} A promise that resolves to the response data from the PUT request.
 */
export function updateSignUpPage (id, {}) {}


/**
 * DELETE
 * Deletes a specific SignUpPage entry by ID.
 * @param {integer} id - The ID of the SignUpPage entry to delete.
 * @returns {Promise<Object>} A promise that resolves to the response data from the DELETE request.
 */
export function deleteSignUpPage ({ id }) {}
